<template>
  <div v-if="isLoading" class="spinner-container">
    <div class="spinner">
      <div class="yin-yang">
        <div class="dot dot1"></div>
        <div class="dot dot2"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'YinYangSpinner',
  props: {
    isLoading: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.95);
  z-index: 9999;
}

.spinner {
  width: 50px;
  height: 50px;
  position: relative;
}

.yin-yang {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: radial-gradient(circle, black 50%, white 50%);
  position: relative;
  animation: spin 1s linear infinite;
}

.dot {
  width: 25%;
  height: 25%;
  border-radius: 50%;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dot1 {
  background: white;
}

.dot2 {
  background: black;
  top: 75%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>