<template>
    <div class="carousel-container" v-if="!isMobile">
      <div class="carousel">
        <div
          v-for="(card, index) in orderedCards"
          :key="card.id"
          class="carousel-item"
          :style="getCardStyle(index)"
          @mouseover="setHoveredCard(index)"
          @click="useCard(card, index)"
        >
          <div class="card-content" :style="{ backgroundImage: `url(${card.image})` }">
            <span class="card-name">{{ card.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <swiper v-else :options="swiperOptions" class="mobile-carousel">
      <swiper-slide
        v-for="(card, index) in orderedCards"
        :key="card.id"
        class="carousel-item"
        @click="useCard(card, index)"
      >
        <div class="card-content" :style="{ backgroundImage: `url(${card.image})` }">
          <span class="card-name">{{ card.name }}</span>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </template>
  
  <script>
  import { Swiper as SwiperClass, Pagination, Navigation } from 'swiper';
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import 'swiper/swiper-bundle.css';
  
  import cardBackground from '../../../public/assets/images/combat-cards/test-card.png';
  
  SwiperClass.use([Pagination, Navigation]);
  
  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    computed: {
      currentCharacter() {
        return this.$store.getters.getCurrentCharacter;
      },
      user() {
        return this.$store.getters.getCurrentUser;
      },
    },
    data() {
      return {
        orderedCards: [],
        hoveredCard: null,
        isMobile: false,
        swiperOptions: {
          slidesPerView: 2.5,
          spaceBetween: 10,
          loop: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          watchOverflow: true,
        },
      };
    },
    methods: {
      useCard(card, index) {
        this.$emit('card-clicked', card.name);
      },
      setHoveredCard(index) {
        this.hoveredCard = index;
      },
      getCardStyle(index) {
        const baseScale = 0.6;
        const hoveredScale = 1.0;
        const distance = Math.abs(index - this.hoveredCard);
        const scale = hoveredScale - distance * 0.1;
        const zIndex = index === this.hoveredCard ? 10 : 5 - distance;
        const offset = 20 * distance;
        return {
          transform: `scale(${Math.max(baseScale, scale)}) translateX(${index < this.hoveredCard ? -offset : offset}px)`,
          zIndex: zIndex,
          margin: '0 -30px',
          transition: 'transform 0.3s, z-index 0.3s',
        };
      },
      checkIfMobile() {
        this.isMobile = window.innerWidth <= 768;
      },
      generateCards() {
        // Preset action cards
        const actionCards = [
          { id: 'weapon', name: 'Weapon Attack', image: cardBackground },
          { id: 'defend', name: 'Defend', image: cardBackground },
          { id: 'retreat', name: 'Retreat', image: cardBackground },
        ];
  
        // Skill cards from character skills
        const skillCards = this.currentCharacter.skills.map(skill => ({
          id: skill.name,
          name: skill.name,
          image: cardBackground, // Assign specific images based on skill types or names if needed
        }));
  
        // Combine action and skill cards in the desired order
        this.orderedCards = [...actionCards, ...skillCards];
      },
    },
    mounted() {
      this.generateCards();
      this.hoveredCard = Math.floor(this.orderedCards.length / 2);
      this.checkIfMobile();
      window.addEventListener('resize', this.checkIfMobile);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.checkIfMobile);
    },
  };
  </script>
  
  <style scoped>
  .carousel-container {
    width: 100%;
    height: 300px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .carousel {
    display: flex;
    align-items: center;
    position: relative;
    width: 90%;
    justify-content: center;
  }
  
  .carousel-item {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card-content {
    width: 100px;
    height: 150px;
    position: relative;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, z-index 0.3s;
  }
  
  .card-name {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    font-weight: bold;
    padding: 5px 0;
    box-sizing: border-box;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .mobile-carousel {
    width: 100%;
    height: 300px;
    overflow: hidden;
  }
  
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto !important;
  }
  
  .swiper-pagination-bullet {
    background: #000;
  }
  </style>
  