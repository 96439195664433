<template>
    <div>
        <ion-grid>
            <ion-row v-if="!isCooking && !selectedRecipe" :key="setRefresh">
                <ion-col size="12">
                    <ion-label>Select Recipe:</ion-label>
                    <ion-text>
                        <h2>Select Recipe</h2>
                    </ion-text>
                    <ion-list class="recipe-list recipe-cards">
                        <div v-for="recipe in recipeList" :key="recipe.name" @click="handleRecipeClick(recipe)"
                            :class="{ selected: selectedRecipe === recipe.name }"
                            :style="{ color: getRecipeColor(recipe) }">
                            <img class="recipe-icon" :src="recipe.image" alt="Recipe Image" />
                            <ion-label class="recipe-name">{{ recipe.name }}</ion-label>
                        </div>
                    </ion-list>
                </ion-col>
            </ion-row>
            <ion-row v-if="!isCooking && selectedRecipe">
                <ion-col size="12">
                    <img :src="selectedRecipeObject.image" alt="Recipe Image" class="recipe-image" />
                    <ion-text class="text-center">
                        <h1>{{ selectedRecipe }}</h1>
                    </ion-text>
                    <ion-text class="text-center">
                        <p>{{ selectedRecipeObject.description }}</p>
                    </ion-text>
                    <div>
                        <ion-text class="text-center">
                            <h4>Buffs:</h4>
                        </ion-text>
                        <ion-list class="recipe-stat-list">
                            <ion-item v-for="(value, key) in selectedRecipeObject.effects" :key="key">
                                <ion-label>{{ capitalizeAllWords(key) }}: {{ value }}</ion-label>
                            </ion-item>
                        </ion-list>
                    </div>
                    <ion-text class="text-center">
                        <p><strong >Value: </strong>
                            <CurrencyDisplay currency="mortal" :value="selectedRecipeObject.value" />
                        </p>
                    </ion-text>
                    <div
                        v-if="selectedRecipeObject.components && (selectedRecipeObject.components.meats.length > 0 || selectedRecipeObject.components.supplementary.length > 0)">
                        <ion-text class="text-center">
                            <h3>Components required:</h3>
                        </ion-text>
                        <ion-list class="recipe-stat-list">
                            <ion-item
                                v-for="component in [...selectedRecipeObject.components.meats, ...selectedRecipeObject.components.supplementary]"
                                :key="component.name">
                                <ion-label>{{ capitalizeAllWords(component.name) }}: {{ component.quantity
                                    }}</ion-label>
                            </ion-item>
                        </ion-list>
                    </div>
                    <div v-if="missingResources.length > 0">
                        <ion-text class="text-center">
                            <h3>Missing Resources:</h3>
                        </ion-text>
                        <ion-list class="recipe-stat-list">
                            <ion-item v-for="resource in missingResources" :key="resource.name">
                                <ion-label>{{ capitalizeAllWords(resource.name) }}: {{ resource.quantity }}</ion-label>
                            </ion-item>
                        </ion-list>
                    </div>
                    <QJButton v-if="missingResources.length === 0" @click="startCookingProcess">Craft</QJButton>
                    <QJButton @click="clearSelectedRecipe">Back to Recipe Book</QJButton>
                </ion-col>
            </ion-row>

            <ion-row v-if="isCooking">
                <ion-col size="12">
                    <ion-text>
                        <h3>Cooking Process</h3>
                    </ion-text>
                    <div class="cooking-stages">
                        <ion-chip v-for="(stage, index) in cookingStages" :key="index" :class="['stage', stage.status]">
                            {{ index + 1 }}
                        </ion-chip>
                    </div>
                    <ReflexGame v-if="currentStage < 4" :difficulty="7" :skill="6" :startRunning="true"
                        @game-finished="handleReflexScore" />
                    <div v-if="currentStage === 4">
                        <ion-text>
                            <h3>Average Score: {{ averageScore }}</h3>
                        </ion-text>
                        <QJButton @click="finalizeCookingProcess">Finalize Cooking</QJButton>
                    </div>
                </ion-col>
            </ion-row>

            <ion-row class="end-message" v-if="showMessage">
                <ion-col size="12">
                    <ion-text>
                        <p>{{ endMessage }}</p>
                    </ion-text>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</template>

<script>
import ReflexGame from '../minigames/ReflexGame.vue';
import inventoryWorker from '@/workers/inventoryWorker';
import characterWorker from '@/workers/characterWorker';

import helperFunctions from '../../../serverless-backend/server/helpers/helperFunctions.js';

import CurrencyDisplay from '../helpers/CurrencyDisplay.vue';
import QJButton from '../framework/QJButton.vue';

const recipeData = require('../../../serverless-backend/server/datasets/recipes.json');

export default {
    components: {
        ReflexGame,
        CurrencyDisplay,
        QJButton
    },
    computed: {
        currentCharacter() {
            return this.$store.getters.getCurrentCharacter;
        },
        user() {
            return this.$store.getters.getCurrentUser;
        }
    },
    data() {
        return {
            selectedRecipe: '',
            selectedRecipeObject: {},
            recipeList: [],
            missingResources: [],
            isCooking: false,
            cookingStages: [
                { status: 'empty' },
                { status: 'empty' },
                { status: 'empty' },
                { status: 'empty' }
            ],
            currentStage: 0,
            scores: [],
            averageScore: 0,
            showMessage: false,
            endMessage: '',
            setRefresh: 0
        };
    },
    mounted() {
        this.initializeCooking();
    },
    methods: {
        async initializeCooking() {
            if (!this.currentCharacter.professions.cooking) {
                let updatedCharacter = this.currentCharacter;
                updatedCharacter.professions.cooking = {
                    level: 1,
                    xp: 0,
                    recipes: []
                };
                await this.updateCharacter(updatedCharacter);
            }

            this.loadRecipesForLevel();
            this.checkAllRecipes();
        },
        async updateCharacter(character) {
            await characterWorker.updateCharacter(this.$store, this.user, character);
        },
        loadRecipesForLevel() {
            const cooking = this.currentCharacter.professions.cooking;
            const availableRecipes = recipeData.recipes.food.filter(recipe => recipe.requiredCookingLevel <= cooking.level);

            if (!cooking.recipes || cooking.recipes.length === 0) {
                cooking.recipes = availableRecipes.map(recipe => recipe.name);
                this.updateCharacter(this.currentCharacter);
            }

            this.recipeList = availableRecipes.filter(recipe => cooking.recipes.includes(recipe.name));
            this.addImagesToRecipes();
        },
        addImagesToRecipes() {
            this.recipeList.forEach(recipe => {
                const recipeName = helperFunctions.normalizeForS3(recipe.name);
                recipe.image = `https://willoftheimmortals-images.s3.amazonaws.com/recipeimages/${recipeName}.png`;
            });
        },
        handleRecipeClick(recipe) {
            this.selectedRecipe = recipe.name;
            this.selectedRecipeObject = recipe;
            this.checkMissingResources();
        },
        clearSelectedRecipe() {
            this.selectedRecipe = '';
            this.selectedRecipeObject = {};
            this.missingResources = [];
        },
        checkMissingResources() {
            this.missingResources = [];
            const allComponents = [
                ...(this.selectedRecipeObject.components.meats || []),
                ...(this.selectedRecipeObject.components.supplementary || [])
            ];
            allComponents.forEach(component => {
                const characterResource = this.currentCharacter.resources.find(resource => resource.material === component.name);
                if (!characterResource || characterResource.quantity < component.quantity) {
                    const missingQuantity = characterResource ? component.quantity - characterResource.quantity : component.quantity;
                    this.missingResources.push({ name: component.name, quantity: missingQuantity });
                }
            });
        },
        checkAllRecipes() {
            this.recipeList.forEach(recipe => {
                let hasAll = true;
                let hasSome = false;
                const allComponents = [
                    ...(recipe.components.meats || []),
                    ...(recipe.components.supplementary || [])
                ];
                console.log("Components", allComponents);
                console.log("Character resources:", this.currentCharacter.resources);
                allComponents.forEach(component => {
                    const characterResource = this.currentCharacter.resources.find(resource => (resource.material).toLowerCase() === (component.name).toLowerCase());

                    if (!characterResource || characterResource.quantity < component.quantity) {
                        hasAll = false;
                    }
                    if (characterResource && characterResource.quantity > 0) {
                        hasSome = true;
                    }
                });
                if (hasAll) {
                    recipe.status = 'all';
                } else if (hasSome) {
                    recipe.status = 'some';
                } else {
                    recipe.status = 'none';
                }
            });
        },
        getRecipeColor(recipe) {
            if (recipe.status === 'all') {
                return 'green';
            } else if (recipe.status === 'some') {
                return 'orange';
            } else {
                return 'red';
            }
        },
        startCookingProcess() {
            this.isCooking = true;
            this.removeResourcesFromCharacter(this.selectedRecipeObject.components);
            this.currentStage = 0;
            this.scores = [];
            this.cookingStages = [
                { status: 'empty' },
                { status: 'empty' },
                { status: 'empty' },
                { status: 'empty' }
            ];
        },
        async removeResourcesFromCharacter(components) {
            await inventoryWorker.removeFromResources(this.$store, this.$store.getters.getCurrentUser, this.currentCharacter, components.meats);
            await inventoryWorker.removeFromResources(this.$store, this.$store.getters.getCurrentUser, this.currentCharacter, components.supplementary);
        },
        handleReflexScore(score) {
            this.scores.push(score);
            this.cookingStages[this.currentStage].status = this.getStageStatus(score);
            this.currentStage++;
            if (this.currentStage === 4) {
                this.calculateAverageScore();
            }
        },
        getStageStatus(score) {
            if (score === 100) {
                return 'green';
            } else if (score === 75) {
                return 'yellow';
            } else {
                return 'red';
            }
        },
        calculateAverageScore() {
            const totalScore = this.scores.reduce((acc, score) => acc + score, 0);
            this.averageScore = totalScore / this.scores.length;
        },
        async finalizeCookingProcess() {
            const dish = {
                name: this.selectedRecipeObject.name,
                effects: this.selectedRecipeObject.effects,
                description: this.selectedRecipeObject.description,
                value: this.selectedRecipeObject.value,
                quantity: 1
            };

            await inventoryWorker.addToConsumables(this.$store, this.user, this.currentCharacter, dish);

            this.isCooking = false;
            this.clearSelectedRecipe();
            this.showMessage = true;
            this.displayEndMessage("Cooking completed successfully!");
        },
        displayEndMessage(message) {
            this.endMessage = message;
            this.checkAllRecipes();
            setTimeout(() => {
                this.showMessage = false;
            }, 2000); // Display result for 2 seconds
        },
        capitalizeAllWords(string) {
            return helperFunctions.capitalizeAllWords(string);
        }
    }
};
</script>

<style scoped>
.recipe-list {
    background-color: transparent;
}

.recipe-list div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 5px;
    border-radius: 5px;
    transition: background-color 0.3s;
    background-color: white;
}

.recipe-list .div:hover {
    background-color: #f0f0f0;
}

.recipe-list .div.selected {
    background-color: #e0e0e0;
    border-color: #ccc;
}

.recipe-icon {
    height: 100px;
    width: 100px;
    margin-right: 20px;
    flex-shrink: 0;
}

.recipe-name {
    font-size: 20px;
    font-weight: bold;
    flex-grow: 1;
    text-align: center;
}

.recipe-list div ion-label {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
}

.recipe-stat-list {
    background-color: transparent;
}

.recipe-stat-list ion-item {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: bold;
    flex-grow: 1;
    text-align: center;
}

.recipe-image {
    width: 350px;
    height: 350px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.end-message {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    background-color: rgba(128, 128, 128, 0.9);
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    font-size: 20px;
    color: white;
}

.cooking-stages {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.qj-button {
    margin-bottom: 5px;
}

.stage {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stage.empty {
    background-color: #fff;
}

.stage.red {
    background-color: #f00;
}

.stage.yellow {
    background-color: #ff0;
}

.stage.green {
    background-color: #0f0;
}
</style>
