<template>
  <CharacterTicker />
  <div class="map-container" ref="mapContainer">
    <div class="map-content" ref="mapContent" :style="{
      width: mapData.width + 'px',
      height: mapData.height + 'px',
      backgroundImage: getBackgroundImage(),
      backgroundSize: 'cover'
    }">
      <div v-for="poi in mapData.pointsOfInterest" :key="poi.name" class="poi" :style="getPOIStyle(poi)"
        @click="selectPOI(poi)">
        <div class="poi-label">{{ poi.name }}</div>
        <img v-if="poi.image" :src="poi.image" :alt="poi.name" class="poi-image" />
      </div>
    </div>
  </div>
  <FullScreenPopup :isOpen="isPopupOpen" :componentLoaded="currentSceneComponent" @close="handleSceneClose">
    <component :is="currentSceneComponent" :shopType="shopType" :mapData="localMapData" @close="handleSceneClose">
    </component>
  </FullScreenPopup>
</template>

<script>
import FullScreenPopup from '../navigation/FullScreenPopup.vue';
import MapComponent from './MapComponent.vue';
import BlacksmithScene from '../scenes/BlacksmithScene.vue';
import AlchemyScene from '../scenes/AlchemyScene.vue';
import KitchenScene from '../scenes/KitchenScene.vue';
import QuestboardScene from '../scenes/QuestboardScene.vue';
import ShopScene from '../scenes/ShopScene.vue';
import CharacterTicker from '../character/CharacterTicker.vue';

import mapWorker from '../../workers/mapWorker';
import characterWorker from '../../workers/characterWorker';

export default {
  components: {
    CharacterTicker,
    FullScreenPopup,
    MapComponent,
    BlacksmithScene,
    AlchemyScene,
    KitchenScene,
    QuestboardScene,
    ShopScene
  },
  props: {
    mapData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isPopupOpen: false,
      currentSceneComponent: null,
      shopType: null,
      localMapData: {}
    };
  },
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    }
  },
  mounted() {
    this.initializeMapScrolling();
    this.centerMapViewport();
  },
  watch: {
    mapData() {
      this.centerMapViewport();
    },
  },
  methods: {
    async showScene(poi) {
      if (poi.action === 'go-to-blacksmith') {
        this.currentSceneComponent = 'BlacksmithScene';
        this.isPopupOpen = true;
      } else if (poi.action === 'go-to-alchemist') {
        this.currentSceneComponent = 'AlchemyScene';
        this.isPopupOpen = true;
      } else if (poi.action === 'go-to-kitchen') {
        this.currentSceneComponent = 'KitchenScene';
        this.isPopupOpen = true;
      } else if (poi.action === 'go-to-townhall') {
        this.currentSceneComponent = 'QuestboardScene';
        this.isPopupOpen = true;
      } else if (poi.action === 'go-to-grocery-shop') {
        this.shopType = 'grocery-store';
        this.currentSceneComponent = 'ShopScene';
        this.isPopupOpen = true;
      } else if (poi.action === 'go-to-library') {
        this.shopType = 'library-store';
        this.currentSceneComponent = 'ShopScene';
        this.isPopupOpen = true;
      } else if (poi.action === 'go-to-livestock-shop') {
        this.shopType = 'livestock-store';
        this.currentSceneComponent = 'ShopScene';
        this.isPopupOpen = true;
      } else if (poi.action === 'go-to-weapon-shop') {
        this.shopType = 'weapons';
        this.currentSceneComponent = 'ShopScene';
        this.isPopupOpen = true;
      } else if (poi.action === 'go-to-armor-shop') {
        this.shopType = 'armor';
        this.currentSceneComponent = 'ShopScene';
        this.isPopupOpen = true;
      } else if (poi.action === 'go-to-location') {
        let updatedCharacter = this.currentCharacter;
        updatedCharacter.zone = poi.connections;
        await characterWorker.updateCharacter(this.$store, this.user, updatedCharacter);
        this.$emit('change-location', poi.connections);
      }
      else if (poi.action === 'go-to-map') {
        const newMapData = await mapWorker.loadMap(this.user, poi.connections);
        this.localMapData = { ...newMapData }; // Replace with a new object
        this.currentSceneComponent = 'MapComponent';
        this.isPopupOpen = true;
      }
    },
    handleSceneClose() {
      this.isPopupOpen = false;
      this.currentSceneComponent = null;
    },
    initializeMapScrolling() {
      const mapContainer = this.$refs.mapContainer;
      const mapContent = this.$refs.mapContent;

      let isDragging = false;
      let startX, startY, scrollLeft, scrollTop;

      mapContainer.addEventListener("mousedown", (e) => {
        isDragging = true;
        startX = e.pageX - mapContainer.offsetLeft;
        startY = e.pageY - mapContainer.offsetTop;
        scrollLeft = mapContainer.scrollLeft;
        scrollTop = mapContainer.scrollTop;
      });

      mapContainer.addEventListener("mouseleave", () => {
        isDragging = false;
      });

      mapContainer.addEventListener("mouseup", () => {
        isDragging = false;
      });

      mapContainer.addEventListener("mousemove", (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - mapContainer.offsetLeft;
        const y = e.pageY - mapContainer.offsetTop;
        const walkX = x - startX;
        const walkY = y - startY;
        mapContainer.scrollLeft = scrollLeft - walkX;
        mapContainer.scrollTop = scrollTop - walkY;
      });

      mapContainer.addEventListener("touchstart", (e) => {
        isDragging = true;
        startX = e.touches[0].pageX - mapContainer.offsetLeft;
        startY = e.touches[0].pageY - mapContainer.offsetTop;
        scrollLeft = mapContainer.scrollLeft;
        scrollTop = mapContainer.scrollTop;
      });

      mapContainer.addEventListener("touchend", () => {
        isDragging = false;
      });

      mapContainer.addEventListener("touchmove", (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.touches[0].pageX - mapContainer.offsetLeft;
        const y = e.touches[0].pageY - mapContainer.offsetTop;
        const walkX = x - startX;
        const walkY = y - startY;
        mapContainer.scrollLeft = scrollLeft - walkX;
        mapContainer.scrollTop = scrollTop - walkY;
      });
    },
    centerMapViewport() {
      const mapContainer = this.$refs.mapContainer;
      const mapContent = this.$refs.mapContent;

      const centerX = (mapContent.offsetWidth - mapContainer.offsetWidth) / 2;
      const centerY = (mapContent.offsetHeight - mapContainer.offsetHeight) / 2;

      mapContainer.scrollLeft = centerX;
      mapContainer.scrollTop = centerY;
    },
    getBackgroundImage() {
      const filename = this.mapData.backgroundImageFilename || 'map-background';
      return `url(https://willoftheimmortals-images.s3.amazonaws.com/mapimages/${filename}.png)`;
    },
    getPOIStyle(poi) {
      return {
        left: `${poi.x}px`,
        top: `${poi.y}px`,
        width: '100px',
        height: '100px'
      };
    },
    selectPOI(poi) {
      console.log("Selected POI:", poi);
      this.showScene(poi);
    },
  },
};
</script>

<style scoped>
@media (min-width: 600px) {
  .character-ticker {
    margin-bottom: 20px;
  }
}
.map-container {
  width: 100%;
  height: 80%;
  max-height: 1024px;
  border: 3px solid #000;
  border-radius: 20px;
  background-color: black;
  overflow: hidden;
  position: relative;
  touch-action: none;
  cursor: grab;
}

.map-content {
  position: relative;
  pointer-events: none;
  /* Prevents interaction on the map itself */
}

.map-selection {
  margin-bottom: 10px;
}

.poi {
  position: absolute;
  cursor: pointer;
  background-color: none;
  padding: 0;
  border-radius: 5px;
  user-select: none;
  pointer-events: auto;
  /* Enables interaction on the POIs */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
}

.poi-label {
  position: absolute;
  bottom: 80%;
  /* Position the label above the icon */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  /* Semi-transparent black background */
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  white-space: nowrap;
  margin-bottom: 5px;
  pointer-events: none;
  text-align: center;
}

.poi-image {
  max-width: 60px;
  max-height: 60px;
}
</style>