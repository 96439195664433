<template>
  <div>
    <div class="game-container">
      <div class="text-center">
        <h1>Random Combat Test</h1>
        <h3>Combat Rounds: {{ combatCount }}</h3>
      </div><br>
      <CombatScene @close="resetCombat" :key="combatCount" />
    </div>
  </div>
</template>

<script>
import CombatScene from '../combat/CombatScene.vue';

export default {
  components: {
    CombatScene
  },
  props: {

  },
  data() {
    return {
      combatCount: 0
    }
  },
  methods: {
    resetCombat() {
      console.log('resetting combat');
      this.combatCount++;
    }
  }
};
</script>

<style scoped></style>