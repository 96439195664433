import axios from 'axios';
import generationWorker from './generationWorker';

const generateRandomBaseResource = async (user, character, location) => {
    console.log('WORKER generateBaseResource');
    try {
        const response = await generationWorker.generateRandomBaseResource(user, character, location);
        let generatedResource = response.data;
        return generatedResource;
    } catch (error) {
        console.error("Failed to generate resource", error);
    }
}

const generateBaseResourceByType = async (user, character, location, type) => {
    try {
        const response = await generationWorker.generateBaseResourceByType(user, character, location, type);
        let generatedResource = response.data;
        console.log("Generated Resource WORKER: ", generatedResource);
        return generatedResource;
    } catch (error) {
        console.error("Failed to generate resource", error);
    }
}

const generateResourceByName = async (user, character, name) => {
    try {
        const response = await generationWorker.generateResourceByName(user, character, name);
        let generatedResource = response.data;
        return generatedResource;
    } catch (error) {
        console.error("Failed to generate resource by name", error);
    }
}

const generateResourceBySourceName = async (user, character, sourceName) => {
    try {
        const response = await generationWorker.generateResourceBySourceName(user, character, sourceName);
        let generatedResource = response.data;
        return generatedResource;
    } catch (error) {
        console.error("Failed to generate resource by source name", error);
    }
}

export default {
    generateRandomBaseResource,
    generateBaseResourceByType,
    generateResourceByName,
    generateResourceBySourceName
}
