<template>
  <div class="fishing-game">
    <p>Tap the "fish" when it appears.</p>
    <div class="fishing-field" ref="fishingField">
      <div v-if="fishVisible" class="fish" :style="{ top: fishPosition.y + 'px', left: fishPosition.x + 'px' }"
        @click="handleClick"></div>
    </div>
    <ion-text v-if="showEndMessage" class="end-message">
      <p>{{ endMessage }}</p>
    </ion-text>
  </div>
</template>

<script>
import resourcesData from '../../../serverless-backend/server/datasets/resources.json';
import inventoryWorker from '../../workers/inventoryWorker.js';
import helperFunctions from '../../../serverless-backend/server/helpers/helperFunctions';

export default {
  data() {
    return {
      meat: {},
      fishVisible: false,
      fishPosition: { x: 0, y: 0 },
      gameOver: false,
      success: false,
      appearancesLeft: 3,
      fishTimer: null,
      disappearTimer: null,
      endMessage: '',
      showEndMessage: false,
    };
  },
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    }
  },
  methods: {
    startGame() {
      const fishMeats = resourcesData.resources.meats.filter(meat => ['Trout', 'Salmon', 'Tuna', 'Mackerel', 'Catfish'].includes(meat.source[0]));
      this.meat = fishMeats[Math.floor(Math.random() * fishMeats.length)];
      this.scheduleFishAppearance();
    },
    scheduleFishAppearance() {
      if (this.appearancesLeft > 0) {
        this.fishTimer = setTimeout(() => {
          this.showFish();
        }, Math.random() * 2000 + 1000); // Fish appears between 1 to 3 seconds
      } else {
        this.endGame(false);
      }
    },
    showFish() {
      const fishingField = this.$refs.fishingField;
      const maxX = fishingField.clientWidth - 50; // 50 is the width of the fish
      const maxY = fishingField.clientHeight - 50; // 50 is the height of the fish

      this.fishPosition = {
        x: Math.random() * maxX,
        y: Math.random() * maxY,
      };
      this.fishVisible = true;
      this.disappearTimer = setTimeout(() => {
        this.fishVisible = false;
        this.appearancesLeft -= 1;
        this.scheduleFishAppearance();
      }, 750); // Fish visible for 0.75 seconds
    },
    handleClick() {
      this.endGame(true);
    },
    async endGame(success) {
      clearTimeout(this.fishTimer);
      clearTimeout(this.disappearTimer);
      this.gameOver = true;
      this.success = success;
      const gatheredMeat = { name: this.meat.name, quantity: 1, value: this.meat.value };
      const resourcesGathered = [gatheredMeat];
      console.log('Fishing game ended with success:', gatheredMeat);
      await inventoryWorker.addResourcesToInventory(this.$store, this.user, this.currentCharacter, resourcesGathered);
      this.displayEndMessage(success ? `You procure ${this.capitalizeAllWords(this.meat.name)}!` : 'You failed to catch the fish.');
    },
    displayEndMessage(message) {
      this.endMessage = message;
      this.showEndMessage = true;
      setTimeout(() => {
        this.showEndMessage = false;
        this.$emit('close');
      }, 1500); // Display result for 2 seconds before closing
    },
    resetGame() {
      this.gameOver = false;
      this.success = false;
      this.appearancesLeft = 3;
      this.startGame();
    },
    capitalizeAllWords(string) {
      return helperFunctions.capitalizeAllWords(string);
    },
  },
  mounted() {
    this.startGame();
  },
  beforeDestroy() {
    clearTimeout(this.fishTimer);
    clearTimeout(this.disappearTimer);
  },
};
</script>

<style scoped>
.fishing-game {
  text-align: center;
}

.fishing-field {
  position: relative;
  width: 500px;
  height: 500px;
  border: 2px solid black;
  border-radius: 10px;
  margin-top: 20px;
  background-color: darkblue;
}

@media (max-width: 600px) {
  .fishing-field {
    width: 300px;
    height: 300px;
  }
}

.fish {
  width: 50px;
  height: 50px;
  background-color: blue;
  position: absolute;
  border: solid black 2px;
  border-radius: 50%;
  cursor: pointer;
}

.result {
  margin-top: 20px;
}

.end-message {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(128, 128, 128, 0.9);
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  font-size: 20px;
  color: white;
}
</style>
