<template>
  <Teleport to="body">
    <div v-if="user.uid === 'Zh1ApdkrkmfXateT7n5Tu1Dgxhn1'" class="map-generator">
      <div class="map-config">
        <ion-item>
          <ion-label>Name: </ion-label>
          <ion-input v-model.text="mapData.name" type="text" placeholder="Enter map name"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>Type: </ion-label>
          <ion-select v-model="mapData.type" placeholder="Select map type">
            <ion-select-option v-for="type in mapTypes" :key="type" :value="type">{{ type }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label>Rows: </ion-label>
          <ion-input v-model.number="mapData.numRows" type="number" placeholder="Enter number of rows"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>Columns: </ion-label>
          <ion-input v-model.number="mapData.numCols" type="number" placeholder="Enter number of columns"></ion-input>
        </ion-item>
        <ion-button @click="generateGrid">Generate Map</ion-button>
      </div>

      <div class="block-selector">
        <ion-button :color="selectedBlockType === 0 ? 'primary' : 'light'" @click="selectBlockType(0)">Empty Space</ion-button>
        <ion-button :color="selectedBlockType === 1 ? 'primary' : 'light'"
          @click="selectBlockType(1)">Blocked</ion-button>
        <ion-button :color="selectedBlockType === 2 ? 'primary' : 'light'" @click="selectBlockType(2)">Shop</ion-button>
        <ion-button :color="selectedBlockType === 3 ? 'primary' : 'light'" @click="selectBlockType(3)">Road</ion-button>
        <ion-button :color="selectedBlockType === 4 ? 'primary' : 'light'"
          @click="selectBlockType(4)">Combat</ion-button>
        <ion-button :color="selectedBlockType === 5 ? 'primary' : 'light'"
          @click="selectBlockType(5)">Mineral</ion-button>
        <ion-button :color="selectedBlockType === 6 ? 'primary' : 'light'"
          @click="selectBlockType(6)">Hunting</ion-button>
        <ion-button :color="selectedBlockType === 7 ? 'primary' : 'light'"
          @click="selectBlockType(7)">Fishing</ion-button>
        <ion-button :color="selectedBlockType === 8 ? 'primary' : 'light'" @click="selectBlockType(8)">Spawn Point</ion-button>
        <ion-button :color="selectedBlockType === 9 ? 'primary' : 'light'" @click="selectBlockType(9)">Exit</ion-button>
      </div>

      <div v-if="mapData.grid.length" class="grid-container">
        <div v-for="(row, rowIndex) in mapData.grid" :key="rowIndex" class="row">
          <div v-for="(cell, cellIndex) in row" :key="cellIndex" :class="getCellClass(cell)"
            @click="handleCellClick(rowIndex, cellIndex)">
          </div>
        </div>
      </div>

      <ion-footer>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="saveMap">Save Map</ion-button>
            <ion-button @click="loadAvailableMaps">Load Available Maps</ion-button>
            <ion-button @click="deleteMap" color="danger" v-if="!isNewMap">Delete Map</ion-button>
          </ion-buttons>
          <ion-item slot="end">
            <ion-label>Available Maps</ion-label>
            <ion-select v-model="selectedMap" @ionChange="loadMap">
              <ion-select-option v-for="map in availableMaps" :key="map" :value="map">{{ map.name }}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-toolbar>
      </ion-footer>

      <ion-modal v-if="showEnemyOptions" :is-open="showEnemyOptions" @will-dismiss="resetEnemyOptions">
        <ion-header>
          <ion-toolbar>
            <ion-title>Select Enemy</ion-title>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-item>
            <ion-label>Enemy Name (optional):</ion-label>
            <ion-input v-model="enemyName"></ion-input>
          </ion-item>
          <ion-list v-if="!enemyName">
            <ion-item>
              <ion-label>Type:</ion-label>
              <ion-select v-model="selectedEnemyType" placeholder="Select Enemy Type">
                <ion-select-option v-for="type in enemyTypes" :key="type" :value="type">
                  {{ type }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </ion-list>
          <ion-item>
            <ion-label>Difficulty:</ion-label>
            <ion-select v-model="selectedDifficulty" placeholder="Select Difficulty">
              <ion-select-option v-for="difficulty in enemyDifficulties" :key="difficulty" :value="difficulty">
                {{ difficulty }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-button expand="block" @click="handleEnemySelection">Confirm</ion-button>
        </ion-content>
      </ion-modal>

      <ion-modal v-if="showResourceOptions" :is-open="showResourceOptions" @will-dismiss="resetResourceOptions">
        <ion-header>
          <ion-toolbar>
            <ion-title>Select Resource</ion-title>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-item>
            <ion-label>Resource Name (optional):</ion-label>
            <ion-input v-model="resourceName"></ion-input>
          </ion-item>
          <ion-list v-if="!resourceName">
            <ion-item>
              <ion-label>Type:</ion-label>
              <ion-select v-model="selectedResourceType" placeholder="Select Resource Type">
                <ion-select-option v-for="type in resourceTypes" :key="type" :value="type">
                  {{ type }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </ion-list>
          <ion-button expand="block" @click="handleResourceSelection">Confirm</ion-button>
        </ion-content>
      </ion-modal>

    </div>
  </Teleport>
</template>

<script>
import mapWorker from '../../workers/mapWorker';

export default {
  data() {
    return {
      selectedBlockType: 0, // Default to empty (numeric type)
      availableMaps: [],
      selectedMap: '',
      showEnemyOptions: false,
      showResourceOptions: false,
      enemyDifficulties: ['low', 'normal', 'elevated', 'deadly', 'extreme', 'otherworldly', 'boss'],
      resourceTypes: ['ores', 'stones', 'wood', 'herbs'],
      enemyTypes: ['human', 'spirit beast', 'awakened'],
      selectedEnemyType: '',
      selectedDifficulty: '',
      enemyName: '',
      resourceName: '',
      mapTypes: ['open', 'cave'],
      selectedCell: null, // Keep track of the currently selected cell for enemy or resource selection
      mapData: {
        name: '',
        type: '',
        numRows: 0,
        numCols: 0,
        grid: [],
        spawnPoint: {
          x: 0,
          y: 0
        },
        enemyLocations: {},
        resourceLocations: {}
      },
      isNewMap: true, // Flag to track if the map is new
    };
  },
  mounted() {
    this.loadAvailableMaps();
  },
  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    }
  },
  methods: {
    generateGrid() {
      this.mapData.grid = Array.from({ length: this.mapData.numRows }, () =>
        Array.from({ length: this.mapData.numCols }, () => 0) // Default type is empty (0)
      );
    },

    getCellClass(type) {
      return {
        cell: true,
        blocked: type === 1,
        shop: type === 2,
        road: type === 3,
        combat: type === 4,
        mineral: type === 5,
        hunting: type === 6,
        fishing: type === 7,
        spawn: type === 8,
        exit: type === 9,
        empty: type === 0,
      };
    },

    selectBlockType(type) {
      this.selectedBlockType = type;
    },

    handleCellClick(rowIndex, cellIndex) {
      if (!this.selectedBlockType && this.selectedBlockType !== 0) return;

      // Reset any existing spawn point
      if (this.selectedBlockType === 8) { // Assuming 8 is the spawn point
        this.mapData.grid.forEach((row, rIndex) => {
          row.forEach((cell, cIndex) => {
            if (cell === 8) {
              this.mapData.grid[rIndex][cIndex] = 0; // Reset to empty
            }
          });
        });
      }

      // Handle combat encounters with enemy selection
      if (this.selectedBlockType === 4) {
        this.selectedCell = { rowIndex, cellIndex };
        this.showEnemyOptions = true; // Show the modal for enemy selection
      }
      // Handle resource selection
      else if (this.selectedBlockType === 5) {
        this.selectedCell = { rowIndex, cellIndex };
        this.showResourceOptions = true; // Show the modal for resource selection
      }
      else if (this.selectedBlockType === 8) {
        this.mapData.spawnPoint = { x: cellIndex, y: rowIndex };
        console.log('Spawn point set to:', this.mapData.spawnPoint);
      } else {
        // Update grid with the selected block type
        this.mapData.grid[rowIndex][cellIndex] = this.selectedBlockType;
        this.showEnemyOptions = false;
        this.showResourceOptions = false;
      }
    },

    handleEnemySelection() {
      if (!this.selectedCell) return;

      const { rowIndex, cellIndex } = this.selectedCell;

      // Set the selected block type and store enemy info in enemyLocations
      this.mapData.grid[rowIndex][cellIndex] = 4; // Set cell type to combat
      this.mapData.enemyLocations[`${rowIndex}-${cellIndex}`] = {
        name: this.enemyName || null,
        type: this.enemyName ? null : this.selectedEnemyType,
        difficulty: this.selectedDifficulty,
      };

      // Reset the modal and cell selection
      this.selectedCell = null;
      this.showEnemyOptions = false;
      this.enemyName = '';
      this.selectedEnemyType = '';
      this.selectedDifficulty = '';
    },

    handleResourceSelection() {
      if (!this.selectedCell) return;

      const { rowIndex, cellIndex } = this.selectedCell;

      // Set the selected block type and store resource info in resourceLocations
      this.mapData.grid[rowIndex][cellIndex] = 5; // Set cell type to mineral
      this.mapData.resourceLocations[`${rowIndex}-${cellIndex}`] = {
        name: this.resourceName || null,
        type: this.resourceName ? null : this.selectedResourceType,
      };

      // Reset the modal and cell selection
      this.selectedCell = null;
      this.showResourceOptions = false;
      this.resourceName = '';
      this.selectedResourceType = '';
    },

    resetEnemyOptions() {
      this.showEnemyOptions = false;
      this.selectedCell = null;
    },

    resetResourceOptions() {
      this.showResourceOptions = false;
      this.selectedCell = null;
    },

    async saveMap() {
      try {
        if (this.isNewMap) {
          await mapWorker.createMap(this.$store.getters.getCurrentUser, this.mapData);
        } else {
          await mapWorker.saveMap(this.$store.getters.getCurrentUser, this.mapData);
          this.isNewMap = false;
        }
        await this.loadAvailableMaps();
      } catch (error) {
        console.error('Error saving map:', error);
      }
    },

    async loadAvailableMaps() {
      try {
        this.availableMaps = await mapWorker.loadAvailableMaps(this.$store.getters.getCurrentUser);
      } catch (error) {
        console.error('Error loading available maps:', error);
      }
    },

    async loadMap() {
      if (!this.selectedMap) return;

      try {
        this.mapData = await mapWorker.loadMap(this.$store.getters.getCurrentUser, this.selectedMap.name);
        this.isNewMap = false;
      } catch (error) {
        console.error(`Error loading map "${this.selectedMap}":`, error);
        this.isNewMap = true;
      }
    },

    async deleteMap() {
      if (!this.selectedMap) return;

      if (confirm(`Are you sure you want to delete the map "${this.selectedMap.name}"?`)) {
        try {
          await mapWorker.deleteMap(this.$store.getters.getCurrentUser, this.selectedMap.name);
          this.mapData = {
            name: '',
            numRows: 0,
            numCols: 0,
            grid: [],
          };
          this.isNewMap = true;
          await this.loadAvailableMaps();
        } catch (error) {
          console.error(`Error deleting map "${this.selectedMap.name}":`, error);
        }
      }
    },
  },
};
</script>

<style scoped>
.map-generator {
  position: absolute;
  top: 0;
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 100px;
}

.map-config {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.enemy-options,
.resource-options {
  position: absolute;
  top: 50px;
  width: 200px;
  height: 150px;
  background-color: white;
}

.block-selector {
  margin-bottom: 20px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.grid-container {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}

.cell {
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cell.blocked {
  background-color: black;
}

.cell.shop {
  background-color: green;
}

.cell.road {
  background-color: gray;
}

.cell.combat {
  background-color: red;
}

.cell.mineral {
  background-color: purple;
}

.cell.hunting {
  background-color: orange;
}

.cell.fishing {
  background-color: lightblue;
}

.cell.spawn {
  background-color: blue;
}

.cell.empty {
  background-color: white;
}

.cell.exit {
  background-color: yellow;
}

ion-footer {
  margin-top: 20px;
}
</style>