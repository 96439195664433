<template>
  <div>
    <ion-grid class="forge-area">
      <!-- Weapon and Armor Selection -->
      <ion-row>
        <ion-col size="12">
          <ion-text>
            <h2>Blacksmith Forge</h2>
          </ion-text>
          <QJButton @click="showWeapons" v-if="!isCrafting">Weapons</QJButton>
          <QJButton @click="showArmor" v-if="!isCrafting">Armor</QJButton>
        </ion-col>
      </ion-row>

      <!-- Weapon Type Selection -->
      <ion-row v-if="selectedCategory === 'weapons' && !selectedWeaponType && !isCrafting">
        <ion-col size="12">
          <ion-text>
            <h3>Weapon Types</h3>
          </ion-text>
          <div>
            <ion-text>
              <h4>Melee</h4>
            </ion-text>
            <ion-list>
              <ion-item v-for="(weapon) in equipmentData.weapons.Melee.subtypes" :key="weapon"
                @click="selectWeaponType('Melee', weapon)" class="clickable weapon-name text-center">
                <ion-label>{{ weapon }}</ion-label>
              </ion-item>
            </ion-list>
          </div>
          <div>
            <ion-text>
              <h4>Ranged</h4>
            </ion-text>
            <ion-list>
              <ion-item v-for="(weapon) in equipmentData.weapons.Ranged.subtypes" :key="weapon"
                @click="selectWeaponType('Ranged', weapon)" class="clickable weapon-name text-center">
                <ion-label>{{ weapon }}</ion-label>
              </ion-item>
            </ion-list>
          </div>
          <div>
            <ion-text>
              <h4>Artifact</h4>
            </ion-text>
            <ion-list>
              <ion-item v-for="(weapon) in equipmentData.weapons.Artifact.subtypes" :key="weapon"
                @click="selectWeaponType('Artifact', weapon)">
                <ion-label>{{ weapon }}</ion-label>
              </ion-item>
            </ion-list>
          </div>
        </ion-col>
      </ion-row>

      <!-- Rarity Selection -->
      <ion-row v-if="selectedWeaponType && !selectedRarity && !isCrafting">
        <ion-col size="12">
          <ion-text>
            <h3>Select Rarity</h3>
          </ion-text>
          <ion-list>
            <ion-item v-for="rarity in equipmentData.rarities" :key="rarity.name" @click="selectRarity(rarity)"
              class="clickable weapon-name text-center">
              <ion-label :style="{ color: rarity.color }">{{ rarity.name }}</ion-label>
            </ion-item>
          </ion-list>
          <QJButton @click="clearWeaponSelection">Back to Weapon List</QJButton>
        </ion-col>
      </ion-row>

      <!-- Display Required Resources for Weapon -->
      <ion-row v-if="selectedRarity && !isCrafting">
        <ion-col size="12">
          <ion-text>
            <h3>Required Resources for {{ selectedWeaponType }} ({{ selectedRarity.name }})</h3>
          </ion-text>
          <ion-list>
            <ion-item v-for="component in selectedWeaponComponents" :key="component.name">
              <ion-label>{{ component.name }}: {{ component.quantity }}</ion-label>
            </ion-item>
          </ion-list>
          <div v-if="hasAllMaterials">
            <QJButton @click="startCraftingStages">Craft Weapon</QJButton>
          </div>
          <div v-else>
            <ion-text>
              <h4>Available Resources:</h4>
            </ion-text>
            <ion-list>
              <ion-item v-for="resource in matchingResources" :key="resource.name">
                <ion-label>{{ resource.name }}: {{ resource.quantity }}</ion-label>
              </ion-item>
            </ion-list>
          </div>
          <QJButton @click="clearWeaponSelection">Back to Weapon List</QJButton>
        </ion-col>
      </ion-row>

      <!-- Display Generated Weapon or Armor -->
      <ion-row v-if="generatedWeapon || generatedArmor">
        <ion-col size="12">
          <ion-card class="display-card">
            <ion-card-header>
              <ion-card-title>{{ generatedWeapon ? 'Generated Weapon' : 'Generated Armor' }}</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <p>Name: {{ generatedWeapon ? generatedWeapon.name : generatedArmor.name }}</p>
              <p>Level: {{ generatedWeapon ? generatedWeapon.level : generatedArmor.level }}</p>
              <p>Type: {{ generatedWeapon ? generatedWeapon.type : capitalizeFirstCharacter(generatedArmor.type) }}</p>
              <p>Rarity:
                <span :style="{ color: generatedWeapon ? generatedWeapon.rarity.color : generatedArmor.rarity.color }">
                  {{ generatedWeapon ? generatedWeapon.rarity.name : generatedArmor.rarity.name }}
                </span>
              </p>
              <p v-if="generatedWeapon && generatedWeapon.subtype">Subtype: {{ generatedWeapon.subtype }}</p>
              <p v-if="generatedWeapon">Damage: {{ generatedWeapon.damage }}</p>
              <p v-if="generatedWeapon">Accuracy: {{ generatedWeapon.accuracy }}</p>
              <p v-if="generatedArmor">Armor Value: {{ generatedArmor.armorValue }}</p>
              <p v-if="generatedArmor">Slot: {{ capitalizeFirstCharacter(generatedArmor.slot) }}</p>
              <p>Maintainence: {{ generatedWeapon ? generatedWeapon.maintainence : generatedArmor.maintainence }} / {{
                generatedWeapon ? generatedWeapon.maintainence : generatedArmor.maintainence }}</p>
              <p v-if="generatedWeapon && generatedWeapon.type === 'Ranged'">Attachment Slots: {{
                generatedWeapon.attachmentSlots }}</p>
              <p>Value: {{ generatedWeapon ? generatedWeapon.value : generatedArmor.value }}</p>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>

      <!-- Crafting Process -->
      <ion-row v-if="isCrafting">
        <ion-col size="12">
          <ion-text>
            <h3>Crafting Process</h3>
          </ion-text>
          <div class="crafting-stages">
            <ion-chip v-for="(stage, index) in craftingStages" :key="index" :class="['stage', stage.status]">
              {{ index + 1 }}
            </ion-chip>
          </div>
          <ReflexGame v-if="currentStage < 5" :difficulty="7" :skill="6" :startRunning="true"
            @game-finished="handleReflexScore" />
          <div v-if="currentStage === 5">
            <ion-text>
              <h3>Average Score: {{ averageScore }}</h3>
            </ion-text>
            <QJButton @click="finalizeCraftingProcess">Finalize Crafting</QJButton>
          </div>
        </ion-col>
      </ion-row>

      <!-- Armor Slot Selection -->
      <ion-row v-if="selectedCategory === 'armor' && !selectedArmorSlot && !isCrafting">
        <ion-col size="12">
          <ion-text>
            <h3>Armor Slots</h3>
          </ion-text>
          <ion-list>
            <ion-item v-for="slot in equipmentData.armors.slots" :key="slot" @click="selectArmorSlot(slot)"
              class="clickable weapon-name text-center">
              <ion-label>{{ capitalizeFirstCharacter(slot) }}</ion-label>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>

      <!-- Armor Type Selection -->
      <ion-row v-if="selectedArmorSlot && !selectedArmorType && !isCrafting">
        <ion-col size="12">
          <ion-text>
            <h3>Armor Materials</h3>
          </ion-text>
          <ion-list>
            <ion-item v-for="type in equipmentData.armors.types" :key="type" @click="selectArmorType(type)"
              class="clickable weapon-name text-center">
              <ion-label>{{ capitalizeFirstCharacter(type) }}</ion-label>
            </ion-item>
          </ion-list>
          <QJButton @click="clearArmorSelection">Back to Armor Slots</QJButton>
        </ion-col>
      </ion-row>

      <!-- Display Required Armor Resources -->
      <ion-row v-if="selectedArmorType && !isCrafting">
        <ion-col size="12">
          <ion-text>
            <h3>Required Resources for {{ selectedArmorType }} {{ selectedArmorSlot }}</h3>
          </ion-text>
          <ion-list>
            <ion-item v-for="component in selectedArmorComponents" :key="component.name">
              <ion-label>{{ component.name }}: {{ component.quantity }}</ion-label>
            </ion-item>
          </ion-list>
          <div v-if="hasAllMaterialsForArmor">
            <QJButton @click="startArmorCraftingStages">Craft Armor</QJButton>
          </div>
          <div v-else>
            <ion-text>
              <h4>Available Resources:</h4>
            </ion-text>
            <ion-list>
              <ion-item v-for="resource in matchingResources" :key="resource.name">
                <ion-label>{{ resource.name }}: {{ resource.quantity }}</ion-label>
              </ion-item>
            </ion-list>
          </div>
          <QJButton @click="clearArmorSelection">Back to Armor Slots</QJButton>
        </ion-col>
      </ion-row>

    </ion-grid>
  </div>
</template>

<script>
import equipmentData from '../../../serverless-backend/server/datasets/equipment.json';
import armorResourceRequirements from '../../../serverless-backend/server/datasets/armorResourceRequirements.json';
import helperFunctions from '../../../serverless-backend/server/helpers/helperFunctions';
import generationWorker from '@/workers/generationWorker';
import inventoryWorker from '@/workers/inventoryWorker';
import weaponResourceRequirements from '../../../serverless-backend/server/datasets/weaponResourceRequirements.json';
import ReflexGame from '../minigames/ReflexGame.vue';
import QJButton from '../framework/QJButton.vue'

export default {
  components: {
    ReflexGame,
    QJButton
  },
  data() {
    return {
      selectedCategory: null,
      selectedArmorSlot: null,
      selectedArmorType: null,
      selectedWeaponType: null,
      selectedWeaponTypeCategory: null,
      selectedRarity: null,
      selectedWeaponComponents: [],
      selectedArmorComponents: [],
      matchingResources: [],
      hasAllMaterials: false,
      hasAllMaterialsForArmor: false,
      isCrafting: false,
      craftingStages: [
        { status: 'empty' },
        { status: 'empty' },
        { status: 'empty' },
        { status: 'empty' },
        { status: 'empty' }
      ],
      currentStage: 0,
      scores: [],
      averageScore: 0,
      generatedWeapon: null,
      generatedArmor: null
    };
  },
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
    equipmentData() {
      return equipmentData;
    }
  },
  methods: {
    showWeapons() {
      this.selectedCategory = 'weapons';
      this.selectedArmorSlot = null;
      this.selectedWeaponType = null;
      this.selectedRarity = null;
    },
    showArmor() {
      this.selectedCategory = 'armor';
      this.selectedArmorSlot = null;
      this.selectedWeaponType = null;
      this.selectedRarity = null;
    },
    selectArmorSlot(slot) {
      this.selectedArmorSlot = slot;
      this.selectedArmorType = null;
      this.selectedArmorComponents = [];
      this.matchingResources = [];
    },
    selectArmorType(type) {
      console.log('Selected armor type:', type);
      console.log('Armor slot:', this.selectedArmorSlot);

      if (!armorResourceRequirements.armorResourceRequirements.requirements) {
        console.error('requirements property is not defined in armorResourceRequirements');
        return;
      }

      if (armorResourceRequirements.armorResourceRequirements.requirements[this.selectedArmorSlot]) {
        console.log('Armor slot exists:', this.selectedArmorSlot);

        if (armorResourceRequirements.armorResourceRequirements.requirements[this.selectedArmorSlot][type]) {
          this.selectedArmorType = type;
          this.selectedArmorComponents = armorResourceRequirements.armorResourceRequirements.requirements[this.selectedArmorSlot][type][0].components;
          console.log('Selected armor components:', this.selectedArmorComponents);
          this.checkArmorMaterials();
          this.generatedArmor = null;
        } else {
          console.error(`Armor type ${type} does not exist for slot ${this.selectedArmorSlot}`);
        }
      } else {
        console.error(`Armor slot ${this.selectedArmorSlot} does not exist in requirements`);
      }
    },
    capitalizeFirstCharacter(string) {
      return helperFunctions.capitalizeFirstCharacter(string);
    },
    async selectWeaponType(type, subtype) {
      console.log('Selected weapon type:', type, subtype);
      try {
        this.selectedWeaponType = subtype;
        this.selectedWeaponTypeCategory = type;
        this.selectedRarity = null;
        this.selectedWeaponComponents = [];
        this.matchingResources = [];
      } catch (error) {
        console.error("Failed to select weapon type", error);
      }
    },
    selectRarity(rarity) {
      this.selectedRarity = rarity;
      this.selectedWeaponComponents = weaponResourceRequirements.weapons[this.selectedWeaponTypeCategory][this.selectedWeaponType].find(
        (weapon) => weapon.rarity === rarity.name
      ).components;
      this.checkMaterials();
    },
    clearWeaponSelection() {
      this.selectedWeaponType = null;
      this.selectedWeaponTypeCategory = null;
      this.selectedRarity = null;
      this.selectedWeaponComponents = [];
      this.matchingResources = [];
      this.hasAllMaterials = false;
      this.isCrafting = false;
      this.craftingStages = [
        { status: 'empty' },
        { status: 'empty' },
        { status: 'empty' },
        { status: 'empty' },
        { status: 'empty' }
      ];
      this.currentStage = 0;
      this.scores = [];
      this.averageScore = 0;
    },
    clearArmorSelection() {
      this.selectedArmorSlot = null;
      this.selectedArmorType = null;
      this.selectedArmorComponents = [];
      this.matchingResources = [];
      this.hasAllMaterialsForArmor = false;
      this.isCrafting = false;
      this.craftingStages = [
        { status: 'empty' },
        { status: 'empty' },
        { status: 'empty' },
        { status: 'empty' },
        { status: 'empty' }
      ];
      this.currentStage = 0;
      this.scores = [];
      this.averageScore = 0;
    },
    async craftWeapon() {
      try {
        const response = await generationWorker.generateWeaponByTypeAndRarity(
          this.$store.getters.getCurrentUser,
          this.currentCharacter,
          this.selectedWeaponTypeCategory,
          this.selectedWeaponType,
          this.selectedRarity
        );
        await inventoryWorker.addToInventory(this.$store, this.$store.getters.getCurrentUser, this.currentCharacter, response);
        this.generatedWeapon = response;
        this.clearWeaponSelection();
      } catch (error) {
        console.error("Failed to generate specific weapon", error);
      }
    },
    async craftArmor() {
      try {
        const response = await generationWorker.generateArmorBySlotAndMaterial(this.$store.getters.getCurrentUser, this.currentCharacter, this.selectedArmorSlot, this.selectedArmorType);
        await inventoryWorker.addToInventory(this.$store, this.$store.getters.getCurrentUser, this.currentCharacter, response);
        this.generatedArmor = response;
        this.clearArmorSelection();
      } catch (error) {
        console.error("Failed to generate specific armor", error);
      }
    },
    async addToInventory(item) {
      try {
        await inventoryWorker.addToInventory(this.$store, this.$store.getters.getCurrentUser, this.currentCharacter, item);
      } catch (error) {
        console.error("Failed to add item to inventory", error);
      }
    },
    async removeResourcesFromCharacter(components) {
      try {
        console.log('REMOVING:', components);
        await inventoryWorker.removeFromResources(this.$store, this.$store.getters.getCurrentUser, this.currentCharacter, components);
      } catch (error) {
        console.error("Failed to remove items from resources:", error);
      }
    },
    getWeaponColor(type, subtype) {
      let hasAll = true;
      let hasSome = false;

      const components = weaponResourceRequirements.weapons[type][subtype][0].components;

      for (const component of components) {
        const characterResource = this.currentCharacter.resources.find(resource => resource.material === component.name);

        if (!characterResource || characterResource.quantity < component.quantity) {
          hasAll = false;
        }
        if (characterResource && characterResource.quantity > 0) {
          hasSome = true;
        }
      }

      if (hasAll) {
        return 'green';
      } else if (hasSome) {
        return 'orange';
      } else {
        return 'red';
      }
    },
    getArmorColor(type) {
      let hasAll = true;
      let hasSome = false;

      const components = armorResourceRequirements.requirements[this.selectedArmorSlot][type][0].components;

      for (const component of components) {
        const characterResource = this.currentCharacter.resources.find(resource => resource.material === component.name);

        if (!characterResource || characterResource.quantity < component.quantity) {
          hasAll = false;
        }
        if (characterResource && characterResource.quantity > 0) {
          hasSome = true;
        }
      }

      if (hasAll) {
        return 'green';
      } else if (hasSome) {
        return 'orange';
      } else {
        return 'red';
      }
    },
    checkMaterials() {
      this.matchingResources = [];
      this.hasAllMaterials = true;

      for (const component of this.selectedWeaponComponents) {
        const characterResource = this.currentCharacter.resources.find(resource => resource.material === component.name);

        if (!characterResource || characterResource.quantity < component.quantity) {
          this.hasAllMaterials = false;
        }
        if (characterResource && characterResource.quantity > 0) {
          this.matchingResources.push({ name: component.name, quantity: characterResource.quantity });
        }
      }
    },
    checkArmorMaterials() {
      this.matchingResources = [];
      this.hasAllMaterialsForArmor = true;

      for (const component of this.selectedArmorComponents) {
        const characterResource = this.currentCharacter.resources.find(resource => resource.material === component.name);

        if (!characterResource || characterResource.quantity < component.quantity) {
          this.hasAllMaterialsForArmor = false;
        }
        if (characterResource && characterResource.quantity > 0) {
          this.matchingResources.push({ name: component.name, quantity: characterResource.quantity });
        }
      }
    },
    async startCraftingStages() {
      console.log('Starting crafting stages', this.selectedWeaponComponents);
      this.isCrafting = true;
      this.currentStage = 0;
      this.craftingStages = [
        { status: 'empty', score: 0 },
        { status: 'empty', score: 0 },
        { status: 'empty', score: 0 },
        { status: 'empty', score: 0 },
        { status: 'empty', score: 0 }
      ];
      this.scores = [];
      await this.removeResourcesFromCharacter(this.selectedWeaponComponents);
    },
    async startArmorCraftingStages() {
      console.log('Starting armor crafting stages', this.selectedArmorComponents);
      this.isCrafting = true;
      this.currentStage = 0;
      this.craftingStages = [
        { status: 'empty', score: 0 },
        { status: 'empty', score: 0 },
        { status: 'empty', score: 0 },
        { status: 'empty', score: 0 },
        { status: 'empty', score: 0 }
      ];
      this.scores = [];
      await this.removeResourcesFromCharacter(this.selectedArmorComponents);
    },
    handleReflexScore(score) {
      console.log('Score:', score);
      if (this.currentStage < this.craftingStages.length) {
        this.craftingStages[this.currentStage].score = score;
        if (score >= 90) {
          this.craftingStages[this.currentStage].status = 'green';
        } else if (score >= 50) {
          this.craftingStages[this.currentStage].status = 'yellow';
        } else {
          this.craftingStages[this.currentStage].status = 'red';
        }
        this.currentStage++;

        if (this.currentStage === this.craftingStages.length) {
          this.calculateAverageScore();
        }
      }
    },
    calculateAverageScore() {
      const totalScore = this.craftingStages.reduce((acc, stage) => acc + stage.score, 0);
      this.averageScore = totalScore / this.craftingStages.length;
    },
    async finalizeCraftingProcess() {
      try {
        if (this.selectedCategory === 'weapons') {
          await this.craftWeapon();
        } else if (this.selectedCategory === 'armor') {
          await this.craftArmor();
        }
      } catch (error) {
        console.error('Failed to finalize crafting process:', error);
      } finally {
        this.isCrafting = false;
      }
    },
  },
  mounted() {
    console.log('Blacksmith component mounted');
  }
};
</script>

<style scoped>
.forge-area {
  width: 100%;
  height: 100%;
  min-width: 350px;
}

button {
  margin: 5px;
}

.weapon-name {
  width: 100%;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  cursor: pointer;
  margin: 5px 0;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: background-color 0.3s;
}

li:hover {
  background-color: #f0f0f0;
}

.display-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
}

.button-stack {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.crafting-stages {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.stage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stage.empty {
  background-color: #fff;
}

.stage.red {
  background-color: #f00;
}

.stage.yellow {
  background-color: #ff0;
}

.stage.green {
  background-color: #0f0;
}
</style>
