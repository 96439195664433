const helperFunctions = require('../helpers/helperFunctions.js');

exports.calculateArmorStats = (character) => { 
    let updatedCharacter = character;
    let characterEquipment = updatedCharacter.equipped;
    console.log('CharacterEquipment:', characterEquipment)
    let armorValue = 0;
    console.log("Calculating armor stats")

    characterEquipment.forEach(item => {
        console.log("Item:", item)
        if (item.slot != 'weapon') {
            if (item.item.armorValue) {
                armorValue += item.item.armorValue;
            }
        }
    });

    updatedCharacter.armorValue = armorValue;

    return updatedCharacter;
}