<template>
  <div class="equipment-test">
    <ButtonStack :startOpen="true">
      <QJButton @click="generateWeapon">Generate Weapon</QJButton>
      <!-- <QJButton @click="generateRandomAttachment">Generate Attachment</QJButton> -->
      <QJButton @click="generateRandomArmor">Generate Armor</QJButton>
      <!-- <QJButton @click="generateRandomConsumable">Generate Consumable</QJButton> -->
    </ButtonStack>

    <ion-grid v-if="weaponGenOn">
      <ion-row>
        <ion-col>
          <div class="button-stack narrow-stack75">
            <QJButton @click="generateRandomWeapon">True Random</QJButton>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-card v-if="generatedWeapon || generatedArmor" class="display-card">
      <ion-card-header>
        <ion-card-title>{{ generatedWeapon ? 'Generated Weapon' : 'Generated Armor' }}</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <img v-fallback-img class="equipment-image" :src="generatedArmor ? generatedArmor.image : generatedWeapon.image" alt="Equipment Image" />
        <p>Name: {{ generatedWeapon ? generatedWeapon.name : generatedArmor.name }}</p>
        <p>Level: {{ generatedWeapon ? generatedWeapon.level : generatedArmor.level }}</p>
        <p>Type: {{ generatedWeapon ? generatedWeapon.type : capitalizeFirstCharacter(generatedArmor.type) }}</p>
        <p>
          Rarity: 
          <span :style="{ color: generatedWeapon ? generatedWeapon.rarity.color : generatedArmor.rarity.color }">
            {{ generatedWeapon ? generatedWeapon.rarity.name : generatedArmor.rarity.name }}
          </span>
        </p>
        <p v-if="generatedWeapon && generatedWeapon.subtype">Subtype: {{ generatedWeapon.subtype }}</p>
        <p v-if="generatedWeapon">Damage: {{ generatedWeapon.damage }}</p>
        <p v-if="generatedWeapon">Accuracy: {{ generatedWeapon.accuracy }}</p>
        <p v-if="generatedArmor">Armor Value: {{ generatedArmor.armorValue }}</p>
        <p v-if="generatedArmor">Slot: {{ capitalizeFirstCharacter(generatedArmor.slot) }}</p>
        <p>
          Maintainence: 
          {{ generatedWeapon ? generatedWeapon.maintainence : generatedArmor.maintainence }} / 
          {{ generatedWeapon ? generatedWeapon.maintainence : generatedArmor.maintainence }}
        </p>
        <p v-if="generatedWeapon && generatedWeapon.type === 'Ranged'">Attachment Slots: {{ generatedWeapon.attachmentSlots }}</p>
        <p>Value: {{ generatedWeapon ? generatedWeapon.value : generatedArmor.value }}</p>
        <div class="button-stack">
          <QJButton @click="addToInventory(generatedWeapon || generatedArmor)">Add to Inventory</QJButton>
        </div>
      </ion-card-content>
    </ion-card>

    <ion-card v-if="generatedConsumable" class="display-card">
      <ion-card-header>
        <ion-card-title>Generated Consumable</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <p>Name: {{ generatedConsumable.name }}</p>
        <p>Level: {{ generatedConsumable.level }}</p>
        <p>Type: {{ generatedConsumable.type }}</p>
        <p>Effects:</p>
        <p>
          SUST: {{ generatedConsumable.consumableObject.sustinencePercent }}% 
          HYD: {{ generatedConsumable.consumableObject.hydrationPercent }}% 
          HP: {{ generatedConsumable.consumableObject.healthPercent }}% 
          STAM: {{ generatedConsumable.consumableObject.staminaPercent }}%
        </p>
        <p>Value: {{ generatedConsumable.value }}</p>
        <div class="button-stack">
          <QJButton @click="addToInventory(generatedConsumable)">Add to Inventory</QJButton>
        </div>
      </ion-card-content>
    </ion-card>

    <ion-card v-if="generatedAttachment" class="display-card">
      <ion-card-header>
        <ion-card-title>Generated Attachment</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <p>Name: {{ generatedAttachment.name }}</p>
        <p>Level: {{ generatedAttachment.level }}</p>
        <p>Slot: {{ generatedAttachment.type }}</p>
        <p>Effects:</p>
        <p>{{ generatedAttachment.modifier }}: {{ generatedAttachment.amount }}</p>
        <p>Rarity: 
          <span :style="{ color: generatedAttachment.rarity.color }">
            {{ generatedAttachment.rarity.name }}
          </span>
        </p>
        <p>Value: {{ generatedAttachment.value }}</p>
        <div class="button-stack">
          <QJButton @click="addToInventory(generatedAttachment)">Add to Inventory</QJButton>
        </div>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
import axios from 'axios';
import { getAuth } from "firebase/auth";
import { useRouter } from 'vue-router';
import generationWorker from '@/workers/generationWorker.js';
import inventoryWorker from '@/workers/inventoryWorker.js';
import characterWorker from '@/workers/characterWorker.js';
import helperFunctions from '../../../serverless-backend/server/helpers/helperFunctions.js'

import ButtonStack from '../framework/ButtonStack.vue';
import QJButton from '../framework/QJButton.vue';

export default {
  components: {
    ButtonStack,
    QJButton
  },
  data() {
    return {
      generatedWeapon: undefined,
      generatedAttachment: undefined,
      generatedArmor: undefined,
      generatedConsumable: undefined,
      weaponGenOn: false,
    };
  },
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  methods: {
    generateWeapon() {
      this.weaponGenOn = !this.weaponGenOn;
    },
    async generateRandomWeapon() {
      this.generatedWeapon = undefined;
      this.generatedAttachment = undefined;
      this.generatedConsumable = undefined;
      this.generatedArmor = undefined;
      const response = await generationWorker.generateRandomWeapon(this.$store.getters.getCurrentUser, this.currentCharacter);
      this.generatedWeapon = response;
    },

    async generateRandomAttachment() {
      this.generatedWeapon = undefined;
      this.generatedAttachment = undefined;
      this.generatedConsumable = undefined;
      this.generatedArmor = undefined;
      const response = await generationWorker.generateRandomAttachment(this.$store.getters.getCurrentUser, this.currentCharacter);
      this.generatedAttachment = response;
    },

    async generateRandomArmor() {
      this.weaponGenOn = false;
      this.generatedWeapon = undefined;
      this.generatedAttachment = undefined;
      this.generatedConsumable = undefined;
      this.generatedArmor = undefined;
      const response = await generationWorker.generateRandomArmor(this.$store.getters.getCurrentUser, this.currentCharacter);
      this.generatedArmor = response;
    },

    async generateRandomConsumable() {
      this.generatedWeapon = undefined;
      this.generatedAttachment = undefined;
      this.generatedConsumable = undefined;
      this.generatedArmor = undefined;
      const response = await generationWorker.generateRandomConsumable(this.$store.getters.getCurrentUser, this.currentCharacter);
      this.generatedConsumable = response;
    },

    getInventory() {
      console.log('Going to inventory');
      this.$router.push(`/character-inventory/`);
    },

    async addToInventory(item) {
      const response = await inventoryWorker.addToInventory(this.$store, this.$store.getters.getCurrentUser, this.currentCharacter, item);
      console.log(response)
      if (this.generatedWeapon) {
        this.generateRandomWeapon();
      }
      else if (this.generatedConsumable) {
        this.generateRandomConsumable();
      }
      else if (this.generatedAttachment) {
        this.generateRandomAttachment();
      }
      else {
        this.generateRandomArmor();
      }
    },
    capitalizeFirstCharacter(string) {
      return helperFunctions.capitalizeFirstCharacter(string);
    }
  }
};
</script>

<style scoped>
.equipment-test {
  text-align: center;
}

.equipment-image {
  height: 300px;
  width: 300px;
  margin-bottom: 20px;
}
</style>