<template>
  <div class="reflex-game">
    <div class="bar">
      <div class="zone green-zone" :style="{ left: greenZoneStart + '%', width: greenZoneWidth + '%' }"></div>
      <div class="zone yellow-zone" :style="{ left: yellowZoneStart + '%', width: yellowZoneWidth + '%' }"></div>
      <div class="zone yellow-zone" :style="{ left: yellowZoneEndStart + '%', width: yellowZoneWidth + '%' }"></div>
      <div class="arrow" :style="{ left: arrowPosition + '%' }"></div>
    </div>
    <ion-button expand="block" @click="handleClick">{{ running ? 'Stop' : 'Start' }}</ion-button>
    <ion-text v-if="score !== null">Score: {{ score }}</ion-text>
  </div>
</template>

<script>
export default {
  props: {
    difficulty: {
      type: Number,
      required: true,
      default: 7
    },
    skill: {
      type: Number,
      required: true,
      default: 7
    },
    startRunning: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      running: false,
      arrowPosition: 0,
      direction: 1,
      greenZoneStart: 0,
      greenZoneWidth: 0,
      yellowZoneWidth: 0,
      yellowZoneStart: 0,
      yellowZoneEndStart: 0,
      score: null,
      intervalId: null,
      speed: 0.5 // Base speed of the arrow
    };
  },
  mounted() {
    if (this.startRunning) {
      this.startGame();
    }
  },
  methods: {
    startGame() {
      this.resetZones();
      this.running = true;
      this.score = null;
      this.arrowPosition = 0;
      this.direction = 1;
      this.setSpeed(); // Set the speed based on difficulty
      this.intervalId = setInterval(this.moveArrow, 10);
    },
    stopGame() {
      clearInterval(this.intervalId);
      this.running = false;
      this.calculateScore();
      console.log('Reflex Stopping, Score:', this.score);
      this.$emit('game-finished', this.score); // Changed from 'score' to 'game-finished'
    },
    handleClick() {
      if (this.running) {
        this.stopGame();
      } else {
        this.startGame();
      }
    },
    moveArrow() {
      if (this.arrowPosition >= 100) {
        this.direction = -1;
      } else if (this.arrowPosition <= 0) {
        this.direction = 1;
      }
      this.arrowPosition += this.direction * this.speed;
    },
    resetZones() {
      const baseGreenWidth = 10;
      const baseYellowWidth = 5;

      const greenZoneSize = Math.max(baseGreenWidth - this.difficulty + this.skill, 2);
      const yellowZoneSize = Math.max(baseYellowWidth - this.difficulty + this.skill, 1);

      const greenStart = Math.random() * (100 - greenZoneSize - 2 * yellowZoneSize);

      this.greenZoneStart = greenStart;
      this.greenZoneWidth = greenZoneSize;
      this.yellowZoneWidth = yellowZoneSize;
      this.yellowZoneStart = Math.max(0, greenStart - yellowZoneSize);
      this.yellowZoneEndStart = greenStart + greenZoneSize;
    },
    setSpeed() {
      const baseSpeed = 0.5;
      this.speed = baseSpeed + this.difficulty * 0.1;
    },
    calculateScore() {
      const greenEnd = this.greenZoneStart + this.greenZoneWidth;
      const yellowEnd = this.yellowZoneEndStart + this.yellowZoneWidth;

      if (
        this.arrowPosition >= this.greenZoneStart &&
        this.arrowPosition <= greenEnd
      ) {
        this.score = 100;
      } else if (
        this.arrowPosition >= this.yellowZoneStart &&
        this.arrowPosition <= yellowEnd
      ) {
        this.score = 75;
      } else {
        this.score = 50;
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  }
};
</script>

<style scoped>
.reflex-game {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bar {
  position: relative;
  width: 100%;
  height: 20px;
  background-color: lightgray;
  margin-bottom: 20px;
  border: solid black 1px;
  overflow: hidden;
  border-radius: 10px;
}

.zone {
  position: absolute;
  height: 100%;
}

.green-zone {
  background-color: green;
}

.yellow-zone {
  background-color: yellow;
}

.arrow {
  position: absolute;
  height: 100%;
  width: 3px;
  border: solid black 3px;
  background-color: red;
  transition: left 0.02s linear;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
}
</style>
