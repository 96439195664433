<template>
  <div class="ion-padding">
    <ion-grid class="display-card">
      <ion-row>
        <ion-col size="12">
          <div class="form-container">
            <h1>Login</h1>
            <form @submit.prevent="login" class="auth-form">
              <ion-item>
                <ion-label position="fixed">Email</ion-label>
                <ion-input v-model="loginEmail" type="email" required></ion-input>
              </ion-item>
              <ion-item>
                <ion-label position="fixed">Password</ion-label>
                <ion-input v-model="loginPassword" type="password" required></ion-input>
              </ion-item>
              <QJButton expand="full" type="submit" color="primary">
                Login
              </QJButton>
              <!-- Error message display -->
              <p v-if="loginError" class="error-text">{{ loginError }}</p>
            </form>
          </div>

          <div class="form-container">
            <h1>Sign Up</h1>
            <form @submit.prevent="signup" class="auth-form">
              <ion-item>
                <ion-label position="floating">Email</ion-label>
                <ion-input v-model="signupEmail" type="email" required></ion-input>
              </ion-item>
              <ion-item>
                <ion-label position="floating">Password</ion-label>
                <ion-input v-model="signupPassword" type="password" required></ion-input>
              </ion-item>
              <QJButton expand="full" type="submit" color="primary">
                Sign Up
              </QJButton>
            </form>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { useRouter } from 'vue-router';

import QJButton from '../framework/QJButton.vue';

export default {
  components: {
    QJButton,
  },
  data() {
    return {
      loginEmail: '',
      loginPassword: '',
      signupEmail: '',
      signupPassword: '',
      loginError: '', // Error state for login
    };
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  methods: {
    async login() {
      try {
        console.log('Logging in...');
        console.log('Email:', this.loginEmail);
        const auth = getAuth();
        await signInWithEmailAndPassword(auth, this.loginEmail.toLowerCase(), this.loginPassword);
        const user = auth.currentUser;
        if (user) {
          console.log('Logged in successfully');
          this.$store.dispatch('setCurrentUser', user);
          this.router.push(`/characters/${user.uid}`);
        }
      } catch (error) {
        console.error('Login failed:', error);
        this.loginError = 'Invalid email or password. Please try again.'; // Set error message
      }
    },
    async signup() {
      try {
        const auth = getAuth();
        const userCredential = await createUserWithEmailAndPassword(auth, this.signupEmail.toLowerCase(), this.signupPassword);
        const user = userCredential.user;
        console.log('Account created successfully');
        this.$store.dispatch('setCurrentUser', user);
        this.router.push(`/characters/${user.uid}`);
      } catch (error) {
        console.error('Sign Up failed:', error);
      }
    },
  },
};
</script>

<style>
.error-text {
  color: red;
  margin-top: 10px;
}

.qj-button {
  margin-top: 10px;
}
</style>
