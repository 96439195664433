<template>
  <div class="ion-padding">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <h1 class="ion-text-center ion-margin-bottom">Character List</h1>
        </ion-col>
      </ion-row>
      <Spinner :isLoading="loading" />
      <ion-row v-if="characters.length > 0 && !loading">
        <ion-col size="12" v-for="character in characters" :key="character._id">
          <ion-card class="ion-text-center ion-margin-bottom">
            <ion-card-header>
              <ion-card-title>{{ character.name }}</ion-card-title>
              <ion-card-subtitle>Background: {{ character.background }}</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <p>Potential: Realm {{ character.potential }}</p>
              <p>Realm: {{ character.realm }}</p>
              <p>Stage: {{ character.stage }}</p>
              <p>Rebirth: {{ character.rebirth }}</p>
            </ion-card-content>
            <div class="character-buttons">
              <QJButton color="primary" @click="loadCharacter(character._id)">Load</QJButton>
              <QJButton color="secondary" @click="deleteCharacter(character._id)">Delete</QJButton>
            </div>
          </ion-card>
        </ion-col>
      </ion-row>
      <ion-row v-if="!loading">
        <ion-col size="12">
          <QJButton expand="block" color="primary" @click="createNewCharacter">
            Create New Character
          </QJButton>
        </ion-col>
      </ion-row>
    </ion-grid>
    <QJButton @click="loadHallOfFame">Hall of Fame</QJButton>
  </div>
  <FullScreenPopup :isOpen="isPopupOpen" :componentLoaded="currentSceneComponent" @close="handleClose">
    <component :is="currentSceneComponent" :items="characterList" @close="handleClose">
    </component>
  </FullScreenPopup>
</template>

<script>
import axios from 'axios';
import { getAuth } from "firebase/auth";
import Spinner from '../framework/Spinner.vue';
import FullScreenPopup from '../navigation/FullScreenPopup.vue';
import QJButton from '../framework/QJButton.vue';
import HallOfFame from '../testing/HallOfFame.vue';

import characterWorker from '../../workers/characterWorker';

export default {
  components: {
    Spinner,
    FullScreenPopup,
    QJButton,
    HallOfFame
  },
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
  },
  data() {
    return {
      characters: [],
      characterList: [],
      loading: true,
      apiUrl: process.env.VUE_APP_API_URL,
      isPopupOpen: false,
      currentSceneComponent: null,
    };
  },
  methods: {
    async loadCharacters() {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
          const userId = user.uid;
          const idToken = await user.getIdToken();
          const response = await axios.get(`${this.apiUrl}/api/user/${userId}`, {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          });
          this.characters = response.data;
          this.loading = false;
        } else {
          console.error("User not logged in");
        }
      } catch (error) {
        console.error('Error fetching characters:', error);
      }
    },

    async deleteCharacter(id) {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          const idToken = await user.getIdToken();
          await axios.delete(`${this.apiUrl}/api/characters/${id}`, {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          });
          this.loadCharacters();
        }
      } catch (error) {
        console.error('Error deleting character:', error);
      }
    },

    createNewCharacter() {
      this.$router.push('/create-character');
    },

    loadCharacter(id) {
      this.$router.push(`/character/${id}`);
    },
    async loadHallOfFame() {
      this.characterList = await characterWorker.getAllCharacters(this.user);
      this.currentSceneComponent = 'HallOfFame';
      this.isPopupOpen = true;
    },
    handleClose() {
      console.log('Closing popup');
      this.isPopupOpen = false;
    },
  },
  mounted() {
    this.loadCharacters();
  }
};
</script>

<style scoped>
.text-center {
  text-align: center;
}

.mb-4 {
  margin-bottom: 16px;
}

.ion-card-text p {
  margin-bottom: 8px;
  /* Add some gap between lines */
}

.character-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
}

.character-buttons .qj-button {
  width: 35% !important;
  height: 50px !important;
}
</style>
