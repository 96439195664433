<template>
    <div class="container">
        <div class="info">
            <ion-text v-if="resource">
                <p>You found {{ resourceArticle }} {{ resource.name }} node!</p>
                <p>Tap on squares to find resources.</p>
            </ion-text>
            <ion-text>
                <p>Turns left: {{ turnsLeft }}</p>
                <p>Material found: {{ oreFound }}/{{ numOres }}</p>
            </ion-text>
        </div>
        <div class="grid">
            <div v-for="(row, rowIndex) in grid" :key="rowIndex" class="row">
                <div v-for="(cell, colIndex) in row" :key="colIndex" :class="cellClass(cell)"
                    @mouseover="hoverCell(cell)" @mouseleave="unhoverCell(cell)" @click="clickCell(cell)" class="cell">
                </div>
            </div>
            <ion-text v-if="endMessage" class="end-message">
                <p>{{ endMessage }}</p>
            </ion-text>
        </div>
    </div>
</template>

<script>
import helperFunctions from '../../../serverless-backend/server/helpers/helperFunctions.js';
import generationWorker from '../../workers/generationWorker.js';
import inventoryWorker from '../../workers/inventoryWorker.js';

export default {
    props: {
        resourceProp: {
            type: Object,
        },
        numTurns: {
            type: Number,
        },
        numOres: {
            type: Number,
        },
        type: {
            type: String,
        }
    },
    data() {
        return {
            grid: [],
            turnsLeft: this.numTurns,
            oreFound: 0,
            numRows: 5,
            numCols: 5,
            endMessage: '',
            resource: undefined,
            resourceCategory: undefined,
            zone: 'common',
        };
    },
    computed: {
        currentCharacter() {
            return this.$store.getters.getCurrentCharacter;
        },
        user() {
            return this.$store.getters.getCurrentUser;
        },
        resourceArticle() {
            if (this.resource) {
                return helperFunctions.getArticle(this.resource.name);
            }
        }
    },
    created() {
        this.fetchResource();
        this.initializeGrid();
    },
    methods: {
        async fetchResource() {
            try {
                if (this.resourceProp) {
                    console.log('Prop resource:', this.resourceProp);
                    this.resource = this.resourceProp;
                    console.log('Prop resource:', this.resource);
                } else {
                    console.log('No resourceProp provided, generating resource...');
                    console.log('Type:', this.type);
                    const gatheredResource = await this.generateBaseResourceByType(this.type);
                    this.resource = gatheredResource;
                    console.log('Generated Resource:', this.resource);
                }
            } catch (error) {
                console.error('Error generating resource:', error);
            }
        },
        async generateBaseResourceByType(type) {
            try {
                console.log('Generating resource by type:', type);
                let resourceType;
                if (type === "cave") {
                    resourceType = "ores";
                } else if (type === "open") {
                    resourceType = "herbs";
                }
                let gatheredResource;
                if (resourceType === "ores") {
                    console.log('Generating ore or stone...');
                    const oreOrStone = Math.floor(Math.random() * 2); // Generates 0 or 1
                    if (oreOrStone === 0) {
                        this.resourceCategory = "ore";
                        gatheredResource = await generationWorker.generateBaseResourceByType(this.user, this.currentCharacter, "ores");
                    } else {
                        this.resourceCategory = "stone";
                        gatheredResource = await generationWorker.generateBaseResourceByType(this.user, this.currentCharacter, "stones");
                    }
                } else if (resourceType === "herbs") {
                    console.log('Generating herb or wood...');
                    const herbOrWood = Math.floor(Math.random() * 2); // Generates 0 or 1
                    if (herbOrWood === 0) {
                        this.resourceCategory = "herb";
                        gatheredResource = await generationWorker.generateBaseResourceByType(this.user, this.currentCharacter, "herbs");
                    } else {
                        this.resourceCategory = "wood";
                        gatheredResource = await generationWorker.generateBaseResourceByType(this.user, this.currentCharacter, "wood");
                    }
                }
                return gatheredResource;
            } catch (error) {
                console.error('Error generating resource by type:', error);
                throw error;
            }
        },
        initializeGrid() {
            this.grid = Array.from({ length: this.numRows }, () => Array.from({ length: this.numCols }, () => ({ hasOre: false, state: 'default' })));
            this.turnsLeft = this.numTurns;
            this.oreFound = 0;
            this.placeOre();
        },
        placeOre() {
            let oreCount = 0;
            while (oreCount < this.numOres) {
                const row = Math.floor(Math.random() * this.numRows);
                const col = Math.floor(Math.random() * this.numCols);
                if (!this.grid[row][col].hasOre) {
                    this.grid[row][col].hasOre = true;
                    oreCount++;
                }
            }
        },
        cellClass(cell) {
            return {
                cell: true,
                'cell-default': cell.state === 'default',
                'cell-hover': cell.state === 'hover',
                'cell-ore': cell.state === 'ore',
                'cell-empty': cell.state === 'empty',
            };
        },
        hoverCell(cell) {
            if (cell.state === 'default') {
                cell.state = 'hover';
            }
        },
        unhoverCell(cell) {
            if (cell.state === 'hover') {
                cell.state = 'default';
            }
        },
        clickCell(cell) {
            if ((cell.state === 'default' || cell.state === 'hover') && this.turnsLeft > 0) {
                this.turnsLeft--;
                if (cell.hasOre) {
                    cell.state = 'ore';
                    this.oreFound++;
                    if (this.oreFound === this.numOres) {
                        this.onWin();
                    }
                } else {
                    cell.state = 'empty';
                }
                if (this.turnsLeft === 0 && this.oreFound < this.numOres) {
                    this.onLose();
                }
            }
        },
        onWin() {
            this.displayEndMessage(`You found ${this.oreFound} ${this.resource.name}!`);
        },
        onLose() {
            this.displayEndMessage(`You found ${this.oreFound} ${this.resource.name}.`);
        },
        async displayEndMessage(message) {
            this.endMessage = message;
            await this.updatePlayerInventory();
            setTimeout(() => {
                this.$emit('close');
            }, 2000); // Display result for 2 seconds before closing
        },
        async updatePlayerInventory() {
            let gatheredMaterial = []
            let resourceObject = {
                name: this.resource.name,
                value: this.resource.value,
                quantity: this.oreFound
            }
            gatheredMaterial.push(resourceObject);
            let event = {
                type: "harvest"
            };
            if (this.resourceCategory === "herb" || this.resourceCategory === "wood") {
                let gatheredFiber = [{
                    name: "Plant Fiber",
                    value: 10,
                    quantity: this.oreFound
                }];
                inventoryWorker.addResourcesToInventory(this.$store, this.user, this.currentCharacter, gatheredFiber, event);
            }
            inventoryWorker.addResourcesToInventory(this.$store, this.user, this.currentCharacter, gatheredMaterial, event);
        }
    }
}
</script>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
}

.grid {
    display: flex;
    flex-direction: column;
    border: 2px solid black;
    border-radius: 10px;
    padding: 10px;
    position: relative;
}

.row {
    display: flex;
}

.cell {
    width: 40px;
    height: 40px;
    margin: 2px;
    border: 1px solid #ccc;
    transition: background-color 0.3s;
}

.cell-default {
    background-color: gray;
}

.cell-hover {
    background-color: darkgray;
    cursor: pointer;
}

.cell-ore {
    background-color: blue;
    cursor: pointer;
}

.cell-empty {
    background-color: black;
    cursor: pointer;
}

.info {
    margin-top: 20px;
    text-align: center;
}

.end-message {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    background-color: rgba(128, 128, 128, 0.9);
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    font-size: 20px;
    color: white;
}
</style>