import helperFunctions from '../../serverless-backend/server/helpers/helperFunctions.js';
import combatCalculations from '../../serverless-backend/server/utilities/combatCalculations.js';

const healCharacterToFull = (character) => {
    character.health = character.maxHealth;
    return character;
}

const attack = (attacker, defender, weaponDamage = null) => {
    let weapon;

    if (attacker.weapon) {
        weapon = attacker.weapon;
    } else {
        weapon = attacker.equipped.find(i => i.slot === 'weapon').item;
    }

    let attackDetails = {
        attacker: attacker,
        defender: defender,
        weapon: weapon,
        roll: 0,
        defenderResistance: defender.resistance,
        damage: weaponDamage !== null ? weaponDamage : weapon.damage,
        damageReduction: 0,
        totalDamage: 0,
        criticalHit: false,
        miss: false,
        attackType: weapon.type,
        kill: false,
    }
    console.log('damage before reduction: ', weapon.damage);
    console.log('damage before reduction: ', weaponDamage);
    attackDetails.damageReduction = combatCalculations.calculateArmorDamageReduction(defender);
    console.log('damage reduction: ', attackDetails.damageReduction);
    attackDetails.totalDamage = Math.ceil(attackDetails.damage - attackDetails.damageReduction);
    console.log('total damage: ', attackDetails.totalDamage);

    if (attackDetails.totalDamage <= 0) {
        attackDetails.totalDamage = 0;
    }
    if (attackDetails.totalDamage >= defender.health) {
        attackDetails.totalDamage = defender.health;
        attackDetails.kill = true;
    }
    return attackDetails;
}

export default {
    healCharacterToFull,
    attack
};
