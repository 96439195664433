<template>
    <div v-if="currentCharacter" class="character-ticker">
        <ion-text class="character-ticker-item">Stage: <span ref="stage">{{ currentCharacter.stage }}</span></ion-text>
        <ion-text class="separator-operator">|</ion-text>
        <ion-text class="character-ticker-item">Qi: <span ref="qiTotal">{{ currentCharacter.qiTotal }}</span> / <span
                ref="qiToNextLevel">{{ currentCharacter.qiToNextLevel }}</span></ion-text>
        <ion-text class="separator-operator">|</ion-text>
        <ion-text class="character-ticker-item">HP: <span ref="health">{{ currentCharacter.health }}</span> / <span
                ref="maxHealth">{{ currentCharacter.maxHealth }}</span></ion-text>
        <ion-text class="separator-operator">|</ion-text>
        <ion-text class="character-ticker-item">Stam: <span ref="stamina">{{ currentCharacter.stamina }}</span> / <span
                ref="totalStamina">{{ currentCharacter.maxStamina }}</span></ion-text>
        <ion-text class="separator-operator">|</ion-text>
        <ion-text class="character-ticker-item">Vital Energy: <span ref="vitalEnergy">{{ currentCharacter.vitalEnergy
                }}</span> / <span ref="maxVitalEnergy">{{ currentCharacter.maxVitalEnergy }}</span></ion-text>
    </div>
</template>

<script>
import { isEqual, cloneDeep } from 'lodash';

export default {
    data() {
        return {
            oldCharacter: null
        };
    },
    computed: {
        currentCharacter() {
            return this.$store.getters.getCurrentCharacter;
        },
        user() {
            return this.$store.getters.getCurrentUser;
        }
    },
    watch: {
        currentCharacter: {
            handler(newVal) {
                if (!newVal || !this.oldCharacter) {
                    this.oldCharacter = cloneDeep(newVal);
                    return;
                }
                const compareObjects = (obj1, obj2, path = '') => {
                    for (const key in obj1) {
                        const fullPath = path ? `${path}.${key}` : key;
                        if (typeof obj1[key] === 'object' && obj1[key] !== null && !Array.isArray(obj1[key])) {
                            compareObjects(obj1[key], obj2[key], fullPath);
                        } else {
                            if (!isEqual(obj1[key], obj2[key])) {
                                if (obj1[key] - obj2[key] > 0) {
                                    this.flashText(key, 'green');
                                } else {
                                    this.flashText(key, 'red');
                                }
                            }
                        }
                    }
                };
                compareObjects(newVal, this.oldCharacter);
                this.oldCharacter = cloneDeep(newVal);
            },
            deep: true
        }
    },
    methods: {
        flashText(elementId, color) {
            const element = this.$refs[elementId];
            if (element) {
                element.style.color = color;
                element.style.fontWeight = 'bold';
                setTimeout(() => {
                    element.style.color = '';
                    element.style.fontWeight = '';
                }, 1000);
            }
        }
    }
};
</script>

<style scoped>
.character-ticker {
    border: 3px solid black;
    border-radius: 5px;
    background-color: antiquewhite;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 10px;
    margin-top: 10px;
}

.separator-operator {
    margin-left: 1px;
    margin-right: 1px;
}

@media (max-width: 600px) {
    .character-ticker {
        max-width: 375px;
        margin: 0 auto;
        margin-bottom: 20px;
    }
}
</style>