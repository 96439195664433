import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { createApp } from 'vue';
import { IonicVue } from '@ionic/vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/styles/global/global.scss';

// Import Ionic CSS
import '@ionic/vue/css/core.css';
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

// Optional Ionic CSS utils
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import IonicComponentsPlugin from './plugins/ionic-components';
// Create the Vue app with Ionic
const vueApp = createApp(App)
  .use(IonicVue)
  .use(store)
  .use(router)
  .use(IonicComponentsPlugin);

// Global directive to handle broken image URLs
vueApp.directive('fallback-img', {
  beforeMount(el, binding) {
    el.addEventListener('error', function() {
      el.src = binding.value || 'https://willoftheimmortals-images.s3.amazonaws.com/siteimages/imagecomingsoon.png';
    });
  }
});

const firebaseConfig = {
  apiKey: "AIzaSyDt91PFbidYmNRp8KbH9RiQ7aJprJ2Z5Z0",
  authDomain: "journey-of-qi.firebaseapp.com",
  projectId: "journey-of-qi",
  storageBucket: "journey-of-qi.appspot.com",
  messagingSenderId: "70055176954",
  appId: "1:70055176954:web:f666d46e7c66042897f743",
  measurementId: "G-D67MXMPNYK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

router.isReady().then(() => {
  vueApp.mount('#app');
});