<template>
  <div class="ion-padding">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <h2>Mustard Seed Farm</h2>
          <ButtonStack class="top-button-stack" :horizontal="false" :expandable="false">
            <QJButton @click="showFarmGrid" :class="{ active: showingFarmGrid }">Show Farm</QJButton>
            <QJButton @click="showHerbProcessing" :class="{ active: showingHerbProcessing }">Herb Processing</QJButton>
            <QJButton @click="showLivestock" :class="{ active: showingLivestock }">Show Livestock</QJButton>
          </ButtonStack>
        </ion-col>
      </ion-row>

      <ion-row v-if="currentCharacter && showingFarmGrid" class="farm-display">
        <ion-col size="12" class="display-card farm-cells">
          <div v-for="(row, rowIndex) in grid" :key="rowIndex" class="farm-row">
            <div v-for="(cell, colIndex) in row" :key="colIndex" class="farm-cell"
              @click="handleCellClick(rowIndex, colIndex)" :class="{
            'cell-empty': cell.state === 'empty',
            'cell-growing': cell.state === 'growing',
            'cell-grown': cell.state === 'grown'
          }">
              <div v-if="cell.state === 'empty'"></div>
              <div v-else-if="cell.state === 'growing'">
                <p class="wrap-text spaced-text">{{ cell.name }}</p>
                <p class="spaced-text">{{ getRemainingTime(cell) }}</p>
              </div>
              <div v-else-if="cell.state === 'grown'">
                <p class="spaced-text">{{ removeSeed(cell.name) }}</p>
                <p class="spaced-text">Ready</p>
              </div>
            </div>
          </div>
        </ion-col>

        <ion-col size="12" v-if="selectedCell" class="display-card">
          <h3>Plant Seed</h3>
          <div class="seed-selection">
            <div v-for="seed in currentCharacter.seeds" :key="seed.name" class="seed-option spaced-text"
              @click="plantSeed(seed)">
              {{ seed.name }} ({{ seed.quantity }})
            </div>
          </div>
        </ion-col>

        <div class="QJButton-container grid-controls" size="12">
          <QJButton @click="addRow">Add Row</QJButton>
          <QJButton @click="removeRow">Remove Row</QJButton>
        </div>
        <div class="seed-controls" size="12">
          <QJButton @click="addRandomSeed">Add Random Seed</QJButton>
        </div>
      </ion-row>

      <ion-row v-if="showingHerbProcessing">
        <ion-col size="12" v-if="availableHerbs.length > 0" class="display-card">
          <h3>Convert Herbs to Seeds</h3>
          <div class="herb-selection">
            <div v-for="herb in availableHerbs" :key="herb.material" class="herb-option spaced-text"
              @click="convertHerbToSeeds(herb)">
              {{ herb.material }} ({{ herb.quantity }})
            </div>
          </div>
        </ion-col>
      </ion-row>

      <ion-row v-if="showingLivestock && !showingLivestockDetail">
        <ion-col size="12">
          <h3>Livestock Management</h3>
          <div class="livestock-selection" v-if="selectedLivestockSlot !== null">
            <div v-for="livestock in availableLivestock" :key="livestock.name" class="livestock-option spaced-text"
              @click="selectLivestock(livestock)">
              {{ livestock.name }} - {{ livestock.purchasePrice }} currency
            </div>
          </div>
        </ion-col>
        <ion-col size="12" class="livestock-grid display-card">
          <div v-for="(slot, index) in livestockGrid" :key="index" class="livestock-slot"
            @click="handleLivestockSlotClick(index)">
            <div v-if="slot.state === 'empty'" class="spaced-text">Empty Slot</div>
            <div v-else>
              <p class="spaced-text">{{ slot.name }}</p>
              <p v-if="slot.state === 'maturing'" class="spaced-text">Maturing: {{ getRemainingTime(slot) }}</p>
              <p v-if="slot.state === 'producing'" class="spaced-text">{{ getTimeToNextResource(slot) }}</p>
              <div v-if="slot.resourcesReady && slot.resourcesReady.length > 0">
                <h4 class="spaced-text">Resources Ready:</h4>
                <ul>
                  <li v-for="resource in slot.resourcesReady" :key="resource.resource" class="spaced-text">
                    {{ resource.resource }} ({{ resource.quantity }})
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </ion-col>

        <div class="QJButton-container grid-controls" size="12">
          <QJButton @click="addLivestockRow">Add Row</QJButton>
          <QJButton @click="removeLivestockRow">Remove Row</QJButton>
        </div>
      </ion-row>

      <ion-row v-if="showingLivestockDetail" class="livestock-detail display-card">
        <ion-col size="12">
          <h3 class="spaced-text">{{ selectedLivestockSlotDetails.name }}</h3>
          <p class="spaced-text">Resources Provided:</p>
          <ul>
            <li v-for="resource in selectedLivestockSlotDetails.resources" :key="resource.resource" class="spaced-text">
              {{ resource.resource }}: {{ resource.quantity }} every {{ resource.timeToProvide / 60000 }} minutes
            </li>
          </ul>
          <p class="spaced-text">Time to Next: {{ getTimeToNextResource(selectedLivestockSlotDetails) }}</p>
          <p class="spaced-text">Install Date/Time: {{ new
            Date(selectedLivestockSlotDetails.installedAt).toLocaleString() }}</p>
          <p class="spaced-text">Mature Sell Price: {{ selectedLivestockSlotDetails.sellValue }}</p>
          <p>Sell Production:</p>
          <ion-button class="production-toggle" @click="handleProductionToggle()">{{ sellProduction ? 'Off' : 'On'
            }}</ion-button>
          <p class="spaced-text">Resources Ready:</p>
          <div v-if="sellProduction && selectedLivestockSlotDetails.resourcesReady.length > 0">
            <CurrencyDisplay :value="calculateTotalValue(selectedLivestockSlotDetails.resourcesReady)"
              currency="mortal" />
          </div>
          <ul v-else>
            <li
              v-for="resource in selectedLivestockSlotDetails.resourcesReady.length > 0 ? selectedLivestockSlotDetails.resourcesReady : selectedLivestockSlotDetails.resources"
              :key="resource.resource" class="spaced-text">
              {{ resource.resource }}: {{ selectedLivestockSlotDetails.resourcesReady.length > 0 ? resource.quantity : 0
              }}
            </li>
          </ul>
          <div class="QJButton-container">
            <QJButton v-if="selectedLivestockSlotDetails.resourcesReady.length > 0"
              @click="collectResources(selectedLivestockSlot)">Collect</QJButton>
            <QJButton @click="removeLivestock">Remove Livestock</QJButton>
            <QJButton @click="backToLivestock">Back to Livestock</QJButton>
          </div>
        </ion-col>
      </ion-row>

      <ion-row v-if="message" class="message-popup">
        <ion-col size="12">
          <p class="spaced-text">{{ message }}</p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import characterWorker from '@/workers/characterWorker';
import inventoryWorker from '@/workers/inventoryWorker';
import resourcesData from '../../../serverless-backend/server/datasets/resources.json';
import livestockData from '../../../serverless-backend/server/datasets/livestock.json';

import ButtonStack from '../framework/ButtonStack.vue';
import QJButton from '../framework/QJButton.vue';

export default {
  components: {
    ButtonStack,
    QJButton
  },
  data() {
    return {
      grid: [],
      selectedCell: null,
      timer: null,
      message: '',
      showingFarmGrid: true,
      showingHerbProcessing: false,
      showingLivestock: false,
      showingLivestockDetail: false,
      livestockGrid: [],
      selectedLivestockSlot: null,
      selectedLivestockSlotDetails: null,
      sellProduction: false,
    };
  },
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
    availableHerbs() {
      return this.currentCharacter.resources.filter(resource =>
        resourcesData.resources.base_materials.common.herbs.some(
          herb => herb.name.toLowerCase() === resource.material.toLowerCase() && resource.quantity > 0
        )
      );
    },
    availableLivestock() {
      return this.currentCharacter.livestock || [];
    }
  },
  methods: {
    findResourceByName(resourceData, name) {
      const searchSections = (sections) => {
        for (const section of sections) {
          for (const category in section) {
            const resource = section[category].find(item => item.name.toLowerCase() === name.toLowerCase());
            if (resource) {
              return resource;
            }
          }
        }
        return null;
      };

      const levels = ['common', 'uncommon', 'rare'];
      for (const level of levels) {
        const baseMaterialsSection = resourceData.resources.base_materials[level];
        const foundResource = searchSections([baseMaterialsSection]);
        if (foundResource) {
          return foundResource;
        }
      }

      const otherSections = [
        { crafting_materials: resourceData.resources.crafting_materials },
        { meats: resourceData.resources.meats },
        { spirit_animal_parts: resourceData.resources.spirit_animal_parts },
        { cooking_items: resourceData.resources.cooking_items },
      ];

      return searchSections(otherSections);
    },
    async initializeGrid() {
      try {
        if (!this.currentCharacter.mustardSeed.grid) {
          const initialGrid = Array(3).fill(null).map(() => Array(3).fill({
            state: 'empty',
            plantedAt: null,
            harvestTime: null,
            name: null
          }));
          this.currentCharacter.mustardSeed = { grid: initialGrid };
          await characterWorker.updateCharacter(this.$store, this.user, this.currentCharacter);
        }
        this.grid = JSON.parse(JSON.stringify(this.currentCharacter.mustardSeed.grid));
        this.startTimers();
      } catch (error) {
        console.error("Error initializing grid:", error);
      }
    },
    async initializeLivestockGrid() {
      try {
        if (!this.currentCharacter.mustardSeed.livestock) {
          this.currentCharacter.mustardSeed.livestock = Array(4).fill({
            state: 'empty',
            installedAt: null,
            lastLoaded: null,
            resourcesReady: [],
            resources: [],
            harvestTime: null
          });
          await characterWorker.updateCharacter(this.$store, this.user, this.currentCharacter);
        }
        this.livestockGrid = JSON.parse(JSON.stringify(this.currentCharacter.mustardSeed.livestock));
        this.livestockGrid.forEach(slot => {
          if (slot.state === 'producing') {
            this.calculateResourcesOnLoad(slot);
          }
        });
        this.startTimers();
      } catch (error) {
        console.error("Error initializing livestock grid:", error);
      }
    },
    calculateResourcesOnLoad(slot) {
      const currentTime = Date.now();
      const timeSinceLastLoaded = currentTime - slot.lastCollected;
      if (!slot.resources) {
        slot.resources = [];
      }
      slot.resourcesReady = slot.resources.map(resource => {
        if (!resource.timeToProvide) {
          console.error("Resource is missing 'timeToProvide':", resource);
          return { ...resource, quantity: 0 };
        }
        const resourceQuantity = Math.floor(timeSinceLastLoaded / resource.timeToProvide) * resource.quantity;
        return { ...resource, quantity: resourceQuantity, value: this.getResourceValue(resource.resource) };
      });
      slot.lastLoaded = currentTime;
    },
    getResourceValue(resourceName) {
      const resource = this.findResourceByName(resourcesData, resourceName);
      return resource ? resource.value : 0;
    },
    async handleCellClick(rowIndex, colIndex) {
      const cell = this.grid[rowIndex][colIndex];
      if (cell.state === 'empty') {
        this.selectedCell = { rowIndex, colIndex };
      } else if (cell.state === 'grown') {
        await this.harvestPlant(rowIndex, colIndex);
      }
    },
    async plantSeed(seed) {
      try {
        if (!this.selectedCell) return;

        const { rowIndex, colIndex } = this.selectedCell;
        const cell = this.grid[rowIndex][colIndex];
        const harvestTime = Date.now() + seed.harvestTime;

        cell.state = 'growing';
        cell.plantedAt = Date.now();
        cell.harvestTime = harvestTime;
        cell.name = seed.name;
        this.selectedCell = null;

        const seedInInventory = this.currentCharacter.seeds.find(s => s.name === seed.name);
        if (seedInInventory) {
          seedInInventory.quantity -= 1;
          if (seedInInventory.quantity === 0) {
            this.currentCharacter.seeds = this.currentCharacter.seeds.filter(s => s.name !== seed.name);
          }
        }

        this.grid[rowIndex][colIndex] = cell;
        this.currentCharacter.mustardSeed.grid = this.grid;
        await characterWorker.updateCharacter(this.$store, this.user, this.currentCharacter);

        this.showMessage(`Planted ${seed.name}`);
      } catch (error) {
        console.error("Error planting seed:", error);
      }
    },
    getRemainingTime(cell) {
      const remainingTime = cell.harvestTime - Date.now();
      if (remainingTime <= 0) {
        cell.state = 'grown';
        return 'Ready to harvest';
      }
      const seconds = Math.floor(remainingTime / 1000) % 60;
      const minutes = Math.floor(remainingTime / 1000 / 60);
      return `${minutes}m ${seconds}s`;
    },
    async harvestPlant(rowIndex, colIndex) {
      try {
        const cell = this.grid[rowIndex][colIndex];
        const seedName = cell.name;
        const herbName = seedName.replace(' Seed', '');

        const matchedResource = this.findResourceByName(resourcesData, herbName);

        if (!matchedResource) {
          console.error("Resource not found for herbName:", herbName);
          return;
        }

        const existingResource = this.currentCharacter.resources.find(resource => resource.material.toLowerCase() === herbName.toLowerCase());
        if (existingResource) {
          existingResource.quantity += 1;
        } else {
          let updatedCharacter = { ...this.currentCharacter };
          updatedCharacter.resources.push({
            material: herbName,
            value: matchedResource.value,
            quantity: 1
          });
          await characterWorker.updateCharacter(this.$store, this.user, updatedCharacter);
        }

        cell.state = 'empty';
        cell.plantedAt = null;
        cell.harvestTime = null;
        cell.name = null;

        this.grid[rowIndex][colIndex] = cell;
        this.currentCharacter.mustardSeed.grid = this.grid;
        await characterWorker.updateCharacter(this.$store, this.user, this.currentCharacter);

        this.showMessage(`Harvested ${herbName}`);
      } catch (error) {
        console.error("Error harvesting plant:", error);
      }
    },
    getTimeToNextResource(slot) {
      if (!slot.resources) {
        slot.resources = [];
      }
      if (slot.resources.length === 0) {
        console.error("Slot resources are empty or not defined");
        return 'N/A';
      }
      if (slot.resourcesReady.length === 0) {
        const nextResource = slot.resources.reduce((min, res) => {
          if (!res.timeToProvide) {
            console.error("Resource is missing 'timeToProvide':", res);
            return min;
          }
          return res.timeToProvide < min ? res.timeToProvide : min;
        }, slot.resources[0].timeToProvide);
        const timeSinceInstalled = Date.now() - slot.installedAt;
        const timeToNext = nextResource - (timeSinceInstalled % nextResource);
        return `${Math.floor(timeToNext / 1000 / 60)}m ${Math.floor((timeToNext / 1000) % 60)}s`;
      }
      return 'Ready';
    },
    async handleLivestockSlotClick(index) {
      const slot = this.livestockGrid[index];
      if (slot.state === 'empty') {
        this.selectedLivestockSlot = index;
      } else {
        this.calculateResourcesOnLoad(slot);
        this.selectedLivestockSlot = index;
        this.selectedLivestockSlotDetails = slot;
        this.showingLivestockDetail = true;
        this.showingLivestock = false;
        this.currentCharacter.mustardSeed.livestock = this.livestockGrid;
        await characterWorker.updateCharacter(this.$store, this.user, this.currentCharacter);
      }
    },
    async selectLivestock(livestock) {
      const slot = this.livestockGrid[this.selectedLivestockSlot];

      slot.state = 'maturing';
      slot.name = livestock.name;
      slot.installedAt = Date.now();
      slot.harvestTime = Date.now() + (livestock.timeToMature || 10000); // Default to 1 hour if timeToMature is missing

      const livestockInfo = livestockData.livestock.find(l => l.name === livestock.name);
      slot.resources = livestockInfo.resources.map(resource => ({
        ...resource,
        value: this.getResourceValue(resource.resource),
      })) || [];
      slot.resourcesReady = [];
      slot.lastLoaded = Date.now();
      slot.lastCollected = Date.now();

      let updatedCharacter = this.currentCharacter;
      updatedCharacter.livestock = this.currentCharacter.livestock.filter(l => l.name !== livestock.name);
      updatedCharacter.mustardSeed.livestock = this.livestockGrid;

      this.selectedLivestockSlot = null;
      await characterWorker.updateCharacter(this.$store, this.user, updatedCharacter);
    },
    async collectResources(index) {
      const slot = this.livestockGrid[index];
      let updatedCharacter = this.currentCharacter;
      if (this.sellProduction) {
        const totalValue = this.calculateTotalValue(slot.resourcesReady);
        updatedCharacter.mortalCurrency += totalValue;
      } else {
        slot.resourcesReady.forEach(resource => {
          const existingResource = updatedCharacter.resources.find(res => res.material === resource.resource);
          if (existingResource) {
            existingResource.quantity += resource.quantity;
          } else {
            const matchedResource = this.findResourceByName(resourcesData, resource.resource);
            if (matchedResource) {
              updatedCharacter.resources.push({
                material: resource.resource,
                quantity: resource.quantity,
                value: matchedResource.value
              });
            } else {
              console.error(`Resource ${resource.resource} not found in resource data.`);
            }
          }
        });
      }
      slot.resourcesReady = [];
      slot.lastCollected = Date.now();

      updatedCharacter.mustardSeed.livestock = this.livestockGrid;
      console.log("Mustard Seed Character:", updatedCharacter);
      await characterWorker.updateCharacter(this.$store, this.user, updatedCharacter);
    },
    calculateTotalValue(resources) {
      return resources.reduce((total, resource) => {
        const matchedResource = this.findResourceByName(resourcesData, resource.resource);
        return total + (resource.quantity * (matchedResource ? matchedResource.value : 0));
      }, 0);
    },
    handleProductionToggle() {
      this.sellProduction = !this.sellProduction;
      console.log("Sell Production:", this.sellProduction);
    },
    async removeLivestock() {
      const slot = this.selectedLivestockSlotDetails;
      if (Date.now() >= slot.harvestTime) {
        slot.name = `Mature ${slot.name}`;
        slot.mature = true;
      }
      let updatedCharacter = this.currentCharacter;
      updatedCharacter.livestock.push(slot);
      this.livestockGrid[this.selectedLivestockSlot] = {
        state: 'empty',
        installedAt: null,
        lastLoaded: null,
        resourcesReady: [],
        resources: [],
        harvestTime: null
      };
      updatedCharacter.mustardSeed.livestock = this.livestockGrid;
      this.selectedLivestockSlotDetails = null;
      this.selectedLivestockSlot = null;
      this.showingLivestock = true;
      this.showingLivestockDetail = false;
      await characterWorker.updateCharacter(this.$store, this.user, updatedCharacter);
    },
    backToLivestock() {
      this.selectedLivestockSlotDetails = null;
      this.showingLivestock = true;
      this.showingLivestockDetail = false;
    },
    async addRow() {
      try {
        const newRow = Array(3).fill({
          state: 'empty',
          plantedAt: null,
          harvestTime: null,
          name: null
        });
        this.grid.push(newRow);
        let updatedCharacter = this.currentCharacter;
        updatedCharacter.mustardSeed.grid = this.grid;
        await characterWorker.updateCharacter(this.$store, this.user, updatedCharacter);
      } catch (error) {
        console.error("Error adding row:", error);
      }
    },
    async removeRow() {
      try {
        if (this.grid.length > 3) {
          this.grid.pop();
          let updatedCharacter = this.currentCharacter;
          updatedCharacter.mustardSeed.grid = this.grid;
          await characterWorker.updateCharacter(this.$store, this.user, updatedCharacter);
        }
      } catch (error) {
        console.error("Error removing row:", error);
      }
    },
    async addLivestockRow() {
      try {
        const newSlots = Array(2).fill({
          state: 'empty',
          installedAt: null,
          lastLoaded: null,
          resourcesReady: [],
          resources: [],
          harvestTime: null
        });
        this.livestockGrid.push(...newSlots);
        let updatedCharacter = this.currentCharacter;
        updatedCharacter.mustardSeed.livestock = this.livestockGrid;
        await characterWorker.updateCharacter(this.$store, this.user, updatedCharacter);
      } catch (error) {
        console.error("Error adding livestock row:", error);
      }
    },
    async removeLivestockRow() {
      try {
        if (this.livestockGrid.length > 4) {
          this.livestockGrid.splice(-2);
          let updatedCharacter = this.currentCharacter;
          updatedCharacter.mustardSeed.livestock = this.livestockGrid;
          await characterWorker.updateCharacter(this.$store, this.user, updatedCharacter);
        }
      } catch (error) {
        console.error("Error removing livestock row:", error);
      }
    },
    async addRandomSeed() {
      try {
        const seedCategories = resourcesData.resources.base_materials.common.seeds;
        const randomSeed = seedCategories[Math.floor(Math.random() * seedCategories.length)];
        if (!randomSeed) return;

        console.log(`Adding random seed: ${randomSeed.name}`);

        const existingSeed = this.currentCharacter.seeds.find(seed => seed.name === randomSeed.name);
        if (existingSeed) {
          existingSeed.quantity += 1;
        } else {
          let updatedCharacter = this.currentCharacter;
          updatedCharacter.seeds.push({ ...randomSeed, quantity: 1 });
          await characterWorker.updateCharacter(this.$store, this.user, updatedCharacter);
        }

        await characterWorker.updateCharacter(this.$store, this.user, this.currentCharacter);
      } catch (error) {
        console.error("Error adding random seed:", error);
      }
    },
    async convertHerbToSeeds(herb) {
      try {
        const maxSeeds = 5;
        const skill = 10; // Replace with actual skill value

        let resource = [
          {
            name: herb.material,
            quantity: 1
          }
        ];

        console.log(resource);

        await inventoryWorker.removeFromResources(this.$store, this.user, this.currentCharacter, resource);

        const seedsGenerated = Math.floor(Math.random() * (maxSeeds + 1) * (skill / 10));

        if (seedsGenerated > 0) {
          const seedName = `${herb.material} Seed`;
          const existingSeed = this.currentCharacter.seeds.find(seed => seed.name === seedName);

          if (existingSeed) {
            existingSeed.quantity += seedsGenerated;
          } else {
            let updatedCharacter = this.currentCharacter;
            updatedCharacter.seeds.push({
              name: seedName,
              harvestTime: resourcesData.resources.base_materials.common.seeds.find(seed => seed.name === seedName).harvestTime,
              quantity: seedsGenerated
            });
            await characterWorker.updateCharacter(this.$store, this.user, updatedCharacter);
          }

          await characterWorker.updateCharacter(this.$store, this.user, this.currentCharacter);
          this.showMessage(`Converted ${herb.material} to ${seedsGenerated} seeds`);
        } else {
          this.showMessage(`Failed to convert ${herb.material} to seeds`);
        }
      } catch (error) {
        console.error("Error converting herb to seeds:", error);
      }
    },
    startTimers() {
      this.timer = setInterval(() => {
        this.grid = this.grid.map((row) => row.map((cell) => {
          if (cell.state === 'growing' && cell.harvestTime <= Date.now()) {
            cell.state = 'grown';
          }
          return cell;
        }));

        this.livestockGrid = this.livestockGrid.map((slot) => {
          if (slot.state === 'maturing' && slot.harvestTime <= Date.now()) {
            slot.state = 'producing';
            slot.installedAt = Date.now();
            slot.lastLoaded = Date.now();
          }

          if (slot.state === 'producing') {
            if (!slot.resources) {
              slot.resources = [];
            }
            const resourcesProduced = slot.resources.map(resource => {
              if (!resource.timeToProvide) {
                console.error("Resource is missing 'timeToProvide':", resource);
                return { ...resource, quantity: 0 };
              }
              const timeSinceInstalled = Date.now() - slot.lastCollected;
              const resourceQuantity = Math.floor(timeSinceInstalled / resource.timeToProvide) * resource.quantity;
              return { ...resource, quantity: resourceQuantity, value: resource.value };
            });
            slot.resourcesReady = resourcesProduced;
          }
          return slot;
        });
      }, 1000);
    },
    stopTimers() {
      clearInterval(this.timer);
    },
    showFarmGrid() {
      this.showingFarmGrid = true;
      this.showingHerbProcessing = false;
      this.showingLivestock = false;
      this.showingLivestockDetail = false;
    },
    showHerbProcessing() {
      this.showingFarmGrid = false;
      this.showingHerbProcessing = true;
      this.showingLivestock = false;
      this.showingLivestockDetail = false;
    },
    showLivestock() {
      this.showingFarmGrid = false;
      this.showingHerbProcessing = false;
      this.showingLivestock = true;
      this.showingLivestockDetail = false;
      this.initializeLivestockGrid();
    },
    removeSeed(seedName) {
      return seedName.replace(' Seed', '');
    },
    showMessage(msg) {
      this.message = msg;
      setTimeout(() => {
        this.message = '';
      }, 3000);
    }
  },
  mounted() {
    this.initializeGrid();
    this.initializeLivestockGrid();
    console.log("Character ON LOAD", this.currentCharacter);
  },
  beforeUnmount() {
    this.stopTimers();
  }
};
</script>

<style scoped>

.farm-cells {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.farm-cells > .item:nth-child(3n+1) {
    margin-left: 0;
}

.farm-cells > .item:nth-child(3n) {
    margin-right: 0;
}

.farm-row {
  display: contents;
}

.farm-cell {
  width: 100px;
  height: 100px;
  border: 4px solid #5c4033;
  border-radius: 20px;
  margin: 10px;
  cursor: pointer;
  box-sizing: border-box;
  flex: 0 0 calc(33.333% - 20px);
}

.qj-button {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.top-button-stack {
  margin-bottom: 20px;
}

.cell-empty {
  background-color: #9f5900;
}

.cell-growing {
  background-color: #71af65;
  color: #fff;
}

.cell-grown {
  background-color: #105819;
  color: #000;
}

.seed-selection {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  margin-top: 10px;
  padding: 10px;
}

.herb-option,
.livestock-option {
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: #f9f9f9;
  border-radius: 5px;
  transition: background-color 0.3s;
  text-align: center;
}

.herb-option:hover,
.livestock-option:hover {
  background-color: #e0e0e0;
}

.display-card {
  width: 100%;
  box-sizing: border-box;
}

.display-card .herb-selection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 10px;
}

.grid-controls {
  margin-top: 10px;
}

.seed-controls {
  margin-top: 10px;
}

.message-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
}

.livestock-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 10px;
  padding: 10px;
}

.livestock-slot {
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: #f9f9f9;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.livestock-slot:hover {
  background-color: #e0e0e0;
}

.button-stack {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.tab-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-buttons button {
  height: 60px;
  width: 120px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: darkslategray;
  transition: background-color 0.3s;
}

.tab-buttons button.active {
  background-color: darkblue;
  color: white;
}

.production-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.production-switch ion-toggle {
  transform: scale(1.5);
  width: auto;
  height: auto;
}

@media (max-width: 600px) {
  .production-switch ion-toggle {
    transform: scale(1.2);
  }

  .display-card .herb-selection {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
}

.livestock-selection {
  margin-bottom: 20px;
}

.livestock-option {
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: #f9f9f9;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.livestock-option:hover {
  background-color: #e0e0e0;
}

.livestock-detail {
  text-align: center;
  line-height: 1.6;
}

.spaced-text {
  margin: 10px 0;
}
</style>
