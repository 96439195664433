<template>
  <div>
    <div class="grid-container" :class="{ zoomedOut: isZoomedOut }">
      <div v-for="(row, rowIndex) in displayedGrid" :key="rowIndex" class="row">
        <div v-for="(cell, cellIndex) in row" :key="cellIndex" :class="[
          'cell',
          {
            player: isPlayerPosition(rowIndex, cellIndex),
            blocked: cell === 1,
            shop: cell === 2,
            road: cell === 3,
            combat: cell === 4,
            mineral: cell === 5,
            hunting: cell === 6,
            fishing: cell === 7,
            spawn: cell === 8,
            exit: cell === 9,
            adjacent: isAdjacentCell(rowIndex, cellIndex) && !isPlayerPosition(rowIndex, cellIndex),
            empty: cell === 0
          }
        ]" @click="handleCellClick(rowIndex, cellIndex)" @mouseover="handleCellMouseOver(rowIndex, cellIndex)"
          @mouseleave="handleCellMouseLeave(rowIndex, cellIndex)"></div>
      </div>
    </div>

    <FullScreenPopup :isOpen="isPopupOpen" @close="handleSceneClose">
      <component :is="currentSceneComponent" :resource="currentEncounter.resource" :numTurns="10" :numOres="15"
        :type="mapType" :enemy="currentEncounter.enemy" @close="handleSceneClose">
      </component>
    </FullScreenPopup>
  </div>
  <Spinner :isLoading="!mapLoaded" />
</template>

<script>
import FullScreenPopup from '../navigation/FullScreenPopup.vue';
import GatheringScene from '../scenes/GatheringScene.vue';
import CombatScene from '../combat/CombatScene.vue';
import HuntingScene from '../scenes/HuntingScene.vue';
import FishingScene from '../scenes/FishingScene.vue';
import Spinner from '../framework/Spinner.vue';

import generationWorker from '../../workers/generationWorker.js';

import helperFunctions from '../../../serverless-backend/server/helpers/helperFunctions';
import modifyingWords from '../../../serverless-backend/server/datasets/modifyingWords.json';
import locationDescriptors from '../../../serverless-backend/server/datasets/locationDescriptors.json';


function mulberry32(a) {
  return function () {
    var t = (a += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
}

function generateRandomSeed() {
  return Math.floor(Math.random() * 1000000);
}

export default {
  components: {
    FullScreenPopup,
    Spinner,
    GatheringScene,
    CombatScene,
    HuntingScene,
    FishingScene
  },
  props: {
    mapType: {
      type: String,
      default: 'cave',
    },
    mapSeed: {
      type: String,
      default: null,
    },
    mapRows: {
      type: Number,
      default: 20,
    },
    mapCols: {
      type: Number,
      default: 20,
    },
    numCombat: {
      type: Number,
      default: 5,
    },
    numMinerals: {
      type: Number,
      default: 15,
    },
    numHunting: {
      type: Number,
      default: 5,
    },
    numFishing: {
      type: Number,
      default: 5,
    },
    mapData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      grid: [],
      playerPosition: { x: 10, y: 10 },
      viewSize: 7,
      isZoomedOut: false,
      mapName: null,
      mapLoaded: false,
      playerState: {},
      isPopupOpen: false,
      currentSceneComponent: null,
      currentEncounter: {},
      hoveredCell: null,
      enemies: [],
      enemyPositions: {},
      resourcePositions: {}
    };
  },
  watch: {
    mapType: 'generateMap',
    mapSeed: 'generateMap',
    mapRows: 'generateMap',
    mapCols: 'generateMap',
    numCombat: 'generateMap',
    numMinerals: 'generateMap',
    numHunting: 'generateMap',
    numFishing: 'generateMap'
  },
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
    enemyLevel() {
      return this.currentCharacter.level;
    },
    displayedGrid() {
      if (!this.grid || !this.grid.length) return []; // Ensure grid is defined
      if (this.isZoomedOut) {
        return this.grid;
      } else {
        const halfView = Math.floor(this.viewSize / 2);
        const startRow = Math.max(0, Math.min(this.grid.length - this.viewSize, this.playerPosition.y - halfView));
        const endRow = startRow + this.viewSize;
        const startCol = Math.max(0, Math.min(this.grid[0].length - this.viewSize, this.playerPosition.x - halfView));
        const endCol = startCol + this.viewSize;

        return this.grid.slice(startRow, endRow).map(row => row.slice(startCol, endCol));
      }
    }
  },
  methods: {
    initializeGrid(rows, cols) {
      return Array.from({ length: rows }, () => Array(cols).fill(1));
    },

    generateLocationName(type) {
      if (!modifyingWords.words || !locationDescriptors) {
        console.error('Data not loaded properly');
        return 'Error loading data';
      }

      let descriptor = '';
      const firstWord = helperFunctions.getRandomElement(modifyingWords.words);
      const secondWord = Math.random() > 0.5 ? helperFunctions.getRandomElement(modifyingWords.words) : '';

      if (secondWord && secondWord !== firstWord) {
        descriptor = `${firstWord} ${secondWord}`;
      } else {
        descriptor = firstWord;
      }

      let location;

      switch (type) {
        case 'open':
          if (!locationDescriptors.descriptors.open) {
            console.error('Open areas data not loaded properly');
            return 'Error loading open areas data';
          }
          location = helperFunctions.getRandomElement(locationDescriptors.descriptors.open);
          break;
        case 'closed':
          if (!locationDescriptors.descriptors.closed) {
            console.error('Closed areas data not loaded properly');
            return 'Error loading closed areas data';
          }
          location = helperFunctions.getRandomElement(locationDescriptors.descriptors.closed);
          break;
        case 'civilized':
          if (!locationDescriptors.descriptors.civilized) {
            console.error('Civilized areas data not loaded properly');
            return 'Error loading civilized areas data';
          }
          location = helperFunctions.getRandomElement(locationDescriptors.descriptors.civilized);
          break;
        default:
          throw new Error('Invalid type. Must be "open", "closed", or "civilized".');
      }

      return `${descriptor} ${location}`;
    },

    async generateMapFromData(mapData) {
      this.enemyPositions = {};
      if (!mapData || !mapData.grid || !Array.isArray(mapData.grid)) {
        console.error("Invalid map data");
        return;
      }

      const { grid, enemyLocations, resourceLocations, spawnPoint } = mapData;
      this.grid = grid;

      if (spawnPoint && typeof spawnPoint.x === 'number' && typeof spawnPoint.y === 'number') {
        this.playerPosition = spawnPoint;
      } else {
        console.warn("Spawn point not defined or invalid, using default position");
        this.playerPosition = { x: 10, y: 10 };
      }

      for (const [key, enemyData] of Object.entries(enemyLocations)) {
        const [row, col] = key.split('-').map(Number);
        let enemy;
        console.log('Enemy data:', enemyData);
        if (typeof enemyData.name === 'string') {
          enemy = await this.generateEnemyByNameAndDifficulty(enemyData.name, enemyData.difficulty);
        } else if (typeof enemyData.type === 'string') {
          enemy = await this.generateEnemyByTypeAndDifficulty(enemyData.type, enemyData.difficulty);
        } else {
          console.error(`Invalid enemy data for key ${key}:`, enemyData);
          continue;
        }
        this.enemyPositions[`${row}-${col}`] = enemy;
        this.grid[row][col] = 4;
      }

      for (const [key, resourceData] of Object.entries(resourceLocations)) {
        const [row, col] = key.split('-').map(Number);
        let resource;
        if (typeof resourceData.name === 'string') {
          resource = await this.generateResourceByName(resourceData.name);
        } else if (typeof resourceData.type === 'string') {
          resource = await this.generateResourceByType(resourceData.type);
        } else {
          console.error(`Invalid resource data for key ${key}:`, resourceData);
          continue;
        }
        this.resourcePositions[`${row}-${col}`] = resource;
        this.grid[row][col] = 5;
      }
      console.log('Map data loaded:', this.grid);
      this.enemies = Object.values(this.enemyPositions);
      console.log('Enemy positions:', this.enemyPositions);
      this.resources = Object.values(this.resourcePositions);
      console.log('Resource positions:', this.resourcePositions);
      this.mapLoaded = true;
    },

    async caveGenerator(rows, cols, numCombat, numMinerals, numHunting, numFishing, seed) {
      this.enemyPositions = {};
      this.mapName = this.generateLocationName('closed');
      if (seed === null) {
        seed = generateRandomSeed();
        this.$emit('update-map-seed', `c-${seed}`);
      } else {
        this.$emit('update-map-seed', `c-${seed}`);
      }
      const grid = this.initializeGrid(rows, cols);
      const rand = mulberry32(parseInt(seed, 10));

      const carvePath = (x, y) => {
        const directions = [
          { dx: 1, dy: 0 },
          { dx: -1, dy: 0 },
          { dx: 0, dy: 1 },
          { dx: 0, dy: -1 }
        ];
        const shuffle = array => array.sort(() => rand() - 0.5);

        grid[y][x] = 0;
        shuffle(directions).forEach(({ dx, dy }) => {
          const nx = x + dx * 2;
          const ny = y + dy * 2;
          if (ny > 0 && ny < rows - 1 && nx > 0 && nx < cols - 1 && grid[ny][nx] === 1) {
            grid[ny - dy][nx - dx] = 0;
            carvePath(nx, ny);
          }
        });
      };

      carvePath(1, 1);
      grid[10][10] = 0;

      const enemies = await this.enemyGenerator(this.enemyLevel, 'cave', numCombat);
      this.enemies = enemies;

      enemies.forEach((enemy, index) => {
        let x, y;
        do {
          x = Math.floor(rand() * rows);
          y = Math.floor(rand() * cols);
        } while (grid[x][y] !== 0);
        grid[x][y] = 4;
        this.enemyPositions[`${x}-${y}`] = enemy;
      });

      for (let i = 0; i < numMinerals; i++) {
        let x, y;
        do {
          x = Math.floor(rand() * rows);
          y = Math.floor(rand() * cols);
        } while (grid[x][y] !== 0);
        grid[x][y] = 5;
      }

      for (let i = 0; i < numHunting; i++) {
        let x, y;
        do {
          x = Math.floor(rand() * rows);
          y = Math.floor(rand() * cols);
        } while (grid[x][y] !== 0);
        grid[x][y] = 6;
      }

      for (let i = 0; i < numFishing; i++) {
        let x, y;
        do {
          x = Math.floor(rand() * rows);
          y = Math.floor(rand() * cols);
        } while (grid[x][y] !== 0);
        grid[x][y] = 7;
      }

      return grid;
    },

    async openGenerator(rows, cols, numCombat, numMinerals, numHunting, numFishing, seed) {
      this.enemyPositions = {};
      this.mapName = this.generateLocationName('open');
      if (seed === null) {
        seed = generateRandomSeed();
        this.$emit('update-map-seed', `o-${seed}`);
      } else {
        this.$emit('update-map-seed', `o-${seed}`);
      }
      const rand = mulberry32(parseInt(seed, 10));
      const grid = this.initializeGrid(rows, cols);

      for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
        for (let colIndex = 0; colIndex < cols; colIndex++) {
          if (rowIndex === 0 || rowIndex === rows - 1 || colIndex === 0 || colIndex === cols - 1) {
            grid[rowIndex][colIndex] = 1;
          } else if (rand() < 0.3) {
            grid[rowIndex][colIndex] = 1;
          } else {
            grid[rowIndex][colIndex] = 0;
          }
        }
      }

      grid[10][10] = 0;

      const enemies = await this.enemyGenerator(this.enemyLevel, 'open', numCombat);
      this.enemies = enemies;

      enemies.forEach((enemy, index) => {
        let x, y;
        do {
          x = Math.floor(rand() * rows);
          y = Math.floor(rand() * cols);
        } while (grid[x][y] !== 0);
        grid[x][y] = 4;
        this.enemyPositions[`${x}-${y}`] = enemy;
      });

      for (let i = 0; i < numMinerals; i++) {
        let x, y;
        do {
          x = Math.floor(rand() * rows);
          y = Math.floor(rand() * cols);
        } while (grid[x][y] !== 0);
        grid[x][y] = 5;
      }

      for (let i = 0; i < numHunting; i++) {
        let x, y;
        do {
          x = Math.floor(rand() * rows);
          y = Math.floor(rand() * cols);
        } while (grid[x][y] !== 0);
        grid[x][y] = 6;
      }

      for (let i = 0; i < numFishing; i++) {
        let x, y;
        do {
          x = Math.floor(rand() * rows);
          y = Math.floor(rand() * cols);
        } while (grid[x][y] !== 0);
        grid[x][y] = 7;
      }

      return grid;
    },

    enemyGenerator(level, mapType, count) {
      const type = [];
      if (mapType === 'cave') {
        type.push('spirit beast', 'human');
      } else if (mapType === 'open') {
        type.push('spirit beast', 'awakened');
      }
      return generationWorker.generateEnemySet(this.user, this.currentCharacter, level, type, count);
    },

    async generateEnemyByNameAndDifficulty(name, difficulty) {
      console.log(`Generating enemy: ${name} (${difficulty})`);
      return await generationWorker.generateEnemyByNameAndDifficulty(this.user, this.currentCharacter, name, difficulty);
    },

    async generateEnemyByTypeAndDifficulty(type, difficulty) {
      console.log(`Generating enemy: ${type} (${difficulty})`);
      return await generationWorker.generateEnemyByTypeAndDifficulty(this.user, this.currentCharacter, type, difficulty);
    },

    async generateResourceByName(name) {
      return await generationWorker.generateBaseResourceByName(this.user, this.currentCharacter, name);
    },

    async generateResourceByType(type) {
      return await generationWorker.generateBaseResourceByType(this.user, this.currentCharacter, type);
    },

    async generateMap() {
      const { mapType, mapRows, mapCols, numCombat, numMinerals, numHunting, numFishing, mapSeed, mapData } = this;

      if (mapData) {
        await this.generateMapFromData(mapData);
      } else {
        let seed = mapSeed ? mapSeed.split('-')[1] : generateRandomSeed();
        let grid;

        if (mapType === 'cave') {
          grid = await this.caveGenerator(mapRows, mapCols, numCombat, numMinerals, numHunting, numFishing, seed);
        } else if (mapType === 'open') {
          grid = await this.openGenerator(mapRows, mapCols, numCombat, numMinerals, numHunting, numFishing, seed);
        } else if (mapType === 'town') {
          grid = await this.townGenerator(mapRows, mapCols, numCombat, numMinerals, seed);
        }

        this.grid = grid;
      }

      this.setPositionFromState();
      this.isZoomedOut = false;
    },

    isPlayerPosition(row, col) {
      if (!this.grid || !this.grid.length) return false;
      const playerRow = this.isZoomedOut
        ? this.playerPosition.y
        : this.playerPosition.y - Math.max(0, Math.min(this.grid.length - this.viewSize, this.playerPosition.y - Math.floor(this.viewSize / 2)));
      const playerCol = this.isZoomedOut
        ? this.playerPosition.x
        : this.playerPosition.x - Math.max(0, Math.min(this.grid[0].length - this.viewSize, this.playerPosition.x - Math.floor(this.viewSize / 2)));
      return row === playerRow && col === playerCol;
    },

    movePlayer(row, col) {
      if (this.isZoomedOut) return;
      const halfView = Math.floor(this.viewSize / 2);
      const startRow = Math.max(0, Math.min(this.grid.length - this.viewSize, this.playerPosition.y - halfView));
      const startCol = Math.max(0, Math.min(this.grid[0].length - this.viewSize, this.playerPosition.x - halfView));
      const targetRow = startRow + row;
      const targetCol = startCol + col;

      if (this.isAdjacent(targetRow, targetCol) && this.grid[targetRow][targetCol] !== 1) {
        this.playerPosition = { x: targetCol, y: targetRow };
        this.updatePlayerState();
        this.triggerAction(targetRow, targetCol);
      }
    },

    isAdjacent(targetRow, targetCol) {
      const dx = Math.abs(targetCol - this.playerPosition.x);
      const dy = Math.abs(targetRow - this.playerPosition.y);
      return (dx === 1 && dy === 0) || (dx === 0 && dy === 1);
    },

    isAdjacentCell(row, col) {
      if (this.isZoomedOut) return false;
      const halfView = Math.floor(this.viewSize / 2);
      const startRow = Math.max(0, Math.min(this.grid.length - this.viewSize, this.playerPosition.y - halfView));
      const startCol = Math.max(0, Math.min(this.grid[0].length - this.viewSize, this.playerPosition.x - halfView));
      const targetRow = startRow + row;
      const targetCol = startCol + col;

      return this.isAdjacent(targetRow, targetCol) && this.grid[targetRow][targetCol] !== 1;
    },

    handleCellMouseOver(row, col) {
      if (this.isZoomedOut || this.hoveredCell) return;
      this.hoveredCell = { row, col };
    },

    handleCellMouseLeave(row, col) {
      if (this.isZoomedOut) return;
      this.hoveredCell = null;
    },

    handleCellClick(row, col) {
      if (this.isZoomedOut) return;
      const halfView = Math.floor(this.viewSize / 2);
      const startRow = Math.max(0, Math.min(this.grid.length - this.viewSize, this.playerPosition.y - halfView));
      const startCol = Math.max(0, Math.min(this.grid[0].length - this.viewSize, this.playerPosition.x - halfView));
      const targetRow = startRow + row;
      const targetCol = startCol + col;

      if (this.grid[targetRow][targetCol] === 2) {
        console.log(`Clicked on shop at (${targetRow}, ${targetCol})`);
      }

      if (this.grid[targetRow][targetCol] === 9) {
        console.log('Exit reached');
        this.$emit('close');
      }

      this.movePlayer(row, col);
    },

    triggerAction(row, col) {
      const cellValue = this.grid[row][col];

      if (cellValue === 4) {  // Combat encounter
        const enemyKey = `${row}-${col}`;
        const enemy = this.enemyPositions[enemyKey];

        if (enemy) {
          console.log(`Combat encounter at (${row}, ${col}) with enemy:`, enemy);
          this.currentEncounter = { row, col, enemy };
          this.showScene('combat');
        } else {
          console.error(`No enemy data found for cell (${row}, ${col})`);
        }
      } else if (cellValue === 5) {  // Resource node
        console.log(`Resource node at (${row}, ${col})`);
        const resourceKey = `${row}-${col}`;
        const resource = this.resourcePositions[resourceKey];
        this.currentEncounter = { row, col };
        if (resource) {
          this.currentEncounter = { row, col, resource };
        }

        this.showScene('gathering');

      } else if (cellValue === 6) {  // Hunting encounter
        console.log(`Hunting encounter at (${row}, ${col})`);
        this.currentEncounter = { row, col };
        this.showScene('hunting');
      } else if (cellValue === 7) {  // Fishing spot
        console.log(`Fishing spot at (${row}, ${col})`);
        this.currentEncounter = { row, col };
        this.showScene('fishing');
      }
    },

    updatePlayerState() {
      this.playerState = {
        seed: this.mapSeed,
        position: { ...this.playerPosition },
        type: this.mapType
      };
    },

    setPositionFromState() {
      if (this.mapData && this.mapData.spawnPoint && typeof this.mapData.spawnPoint.x === 'number' && typeof this.mapData.spawnPoint.y === 'number') {
        this.playerPosition = this.mapData.spawnPoint;
      } else if (this.playerState.seed === this.mapSeed && this.playerState.type === this.mapType) {
        this.playerPosition = { ...this.playerState.position };
      } else {
        let playerX, playerY;
        let attempts = 0;
        const maxAttempts = 1000;
        do {
          playerX = Math.floor(Math.random() * this.mapCols);
          playerY = Math.floor(Math.random() * this.mapRows);
          attempts++;
        } while (
          (playerY >= this.mapRows || playerX >= this.mapCols ||
            playerY < 0 || playerX < 0 ||
            this.grid[playerY][playerX] === undefined ||
            (this.grid[playerY][playerX] !== 3 && this.grid[playerY][playerX] !== 0) ||
            (this.grid[playerY - 1] && (this.grid[playerY - 1][playerX] !== 0 && this.grid[playerY - 1][playerX] !== 3)) ||
            (this.grid[playerY + 1] && (this.grid[playerY + 1][playerX] !== 0 && this.grid[playerY + 1][playerX] !== 3)) ||
            (this.grid[playerY][playerX - 1] !== undefined && (this.grid[playerY][playerX - 1] !== 0 && this.grid[playerY][playerX - 1] !== 3)) ||
            (this.grid[playerY][playerX + 1] !== undefined && (this.grid[playerY][playerX + 1] !== 0 && this.grid[playerY][playerX + 1] !== 3)))
          && attempts < maxAttempts
        );
        this.playerPosition = { x: playerX, y: playerY };
      }
    },

    handleSceneClose() {
      console.log('Closing scene');
      this.isPopupOpen = false;
      console.log('popup closed');
      this.currentSceneComponent = null;
      console.log('component cleared');
      if (this.currentEncounter) {
        console.log('Clearing encounter data');
        const { row, col } = this.currentEncounter;
        console.log('Clearing encounter at:', row, col);
        this.grid[row][col] = 0;
        console.log('Grid updated');
        this.currentEncounter = null;
        console.log('Encounter data cleared');
      }
    },

    showScene(sceneType) {
      if (sceneType === 'gathering') {
        this.currentSceneComponent = 'GatheringScene';
      } else if (sceneType === 'combat') {
        this.currentSceneComponent = 'CombatScene';
      } else if (sceneType === 'hunting') {
        this.currentSceneComponent = 'HuntingScene';
      } else if (sceneType === 'fishing') {
        this.currentSceneComponent = 'FishingScene';
      }
      this.isPopupOpen = true;
    }
  },
  async mounted() {
    await this.generateMap();
  }
};
</script>

<style scoped>
.grid-container {
  display: flex;
  flex-direction: column;
  border: 2px solid #333;
  background-color: #fafafa;
  max-width: 400px;
  margin: 0 auto;
  padding: 10px;
}

.grid-container.zoomedOut .cell {
  width: 25px;
  height: 25px;
}

.row {
  display: flex;
}

.cell {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e0e0e0;
  border: 1px solid #ccc;
}

.cell.player {
  background-color: blue !important;
}

.cell:hover {
  cursor: pointer;
}

.cell.blocked {
  background-color: black;
}

.cell.shop {
  background-color: green;
}

.cell.road {
  background-color: gray;
}

.cell.combat {
  background-color: red;
}

.cell.mineral {
  background-color: purple;
}

.cell.hunting {
  background-color: orange;
}

.cell.fishing {
  background-color: lightblue;
}

.cell.spawn {
  background-color: yellow;
}

.cell.exit {
  background-color: red;
}

@media (min-width: 768px) {
  .cell.adjacent:hover {
    background-color: yellow;
  }
}

.button-stack {
  margin-top: 10px;
}

.map-info {
  margin-top: 10px;
  text-align: center;
}
</style>
