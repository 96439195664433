import axios from 'axios';

// Get the API URL from the environment variable
const apiUrl = process.env.VUE_APP_API_URL;

const generateRandomSkill = async function(user, level) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        const token = await user.getIdToken(true);

        const response = await axios.post(`${apiUrl}/api/skills/random`, { level }, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        console.error("Failed to generate random skill", error);
    }
};

const generateSkillByRealm = async function(user, realmName, level) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        const token = await user.getIdToken(true);

        const response = await axios.post(`${apiUrl}/api/skills/realm/${realmName}`, { level }, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        console.error(`Failed to generate skill for realm ${realmName}`, error);
    }
};

const generateSkillByRealmAndType = async function(user, realmName, skillType, level) {
    try {
        console.log("Generating skill for realm", realmName, "and type", skillType);
        console.log("level", level);
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        const token = await user.getIdToken(true);

        const response = await axios.post(`${apiUrl}/api/skills/realm/${realmName}/type/${skillType}`, { level }, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        console.error(`Failed to generate skill for realm ${realmName} and type ${skillType}`, error);
    }
};

const generateSkillByName = async function(user, skillName, level) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        const token = await user.getIdToken(true);

        const response = await axios.post(`${apiUrl}/api/skills/name/${skillName}`, { level }, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        console.error(`Failed to generate skill by name ${skillName}`, error);
    }
};

const generateSkillByEvolution = async function(user, skillName, realmName, level) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        const token = await user.getIdToken(true);

        const response = await axios.post(`${apiUrl}/api/skills/evolution/${skillName}/realm/${realmName}`, { level }, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        console.error(`Failed to generate evolved skill for ${skillName} in realm ${realmName}`, error);
    }
};

export default {
    generateRandomSkill,
    generateSkillByRealm,
    generateSkillByRealmAndType,
    generateSkillByName,
    generateSkillByEvolution
};
