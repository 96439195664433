<template>
    <div id="app" class="game-window">
      <nav>
        <TopNav />
      </nav>

      <!-- This is where your routed components will be displayed -->
      <router-view></router-view>

    </div>
</template>

<script>
import CharacterTicker from './components/character/CharacterTicker.vue';
import TopNav from './components/navigation/TopNav.vue';

export default {
  name: 'App',
  components: {
    CharacterTicker,
    TopNav,
  },
  mounted() {
    this.setViewportHeight();
    window.addEventListener('resize', this.setViewportHeight);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setViewportHeight);
  },
  methods: {
    setViewportHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }
};
</script>

<style>
#app {
  height: calc(var(--vh, 1vh) * 100);
}
</style>