<template>
    <div class="game-container">
        <MiningMinigame :resourceProp="resource" :numTurns="numTurns" :numOres="numOres" :type="type" @close="handleClose" />
    </div>
</template>

<script>
import MiningMinigame from '../minigames/MiningGame.vue';

export default {
    components: {
        MiningMinigame
    },
    props: {
        resource:{
            type: Object,
        },
        numTurns: {
            type: Number,
        },
        numOres: {
            type: Number,
        },
        type: {
            type: String,
        }
    },
    mounted() {
        console.log('Mining scene mounted');
    },
    methods: {
        handleClose() {
            console.log('Closing mining scene');
            setTimeout(() => {
                console.log('Closing combat scene');
                this.$emit('close');
            }, 2000); // Display result for 2 seconds before closing
        }
    }
};
</script>

<style scoped>
.game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
}
</style>