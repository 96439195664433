<template>
    <div>
        <ion-card class="shop-component">
            <div class="currency-display">
                <p>Current Funds:</p>
                <CurrencyDisplay currency="mortal" :value="currentCharacter.mortalCurrency" />
                <CurrencyDisplay currency="immortal" :value="currentCharacter.immortalCurrency" />
            </div>

            <ion-grid>
                <ion-row v-if="shop">
                    <ion-col size="12" class="shop-display">
                        <ion-card>
                            <ion-card-header>
                                <ion-card-title>{{ shop.name }}</ion-card-title>
                                <ion-card-subtitle>{{ shop.shopKeeper }}</ion-card-subtitle>
                            </ion-card-header>

                            <ion-card-content>
                                <ion-list v-if="shop.type !== 'library'">
                                    <ion-item v-for="ware in shop.stock" :key="ware.name">
                                        <ion-label class="shop-item-label">
                                            <div>
                                                <img v-fallback-img class="item-image" v-if="ware.image" :src="ware.image"
                                                    alt="Item Image" />
                                            </div>
                                            <div>
                                                <h2 :style="{ color: getItemColor(ware) }">{{
                                                    capitalizeAllWords(ware.name)
                                                }}</h2>
                                                <p v-if="shop.type === 'weapons'" class="damage-text">Damage: {{
                                                    ware.damage }}
                                                    <Indicator :first="characterWeapon.damage" :second="ware.damage" />
                                                </p>
                                                <p v-if="shop.type === 'armor'" class="damage-text">Armor: {{
                                                    ware.armorValue }}
                                                    <Indicator :first="selectSlotArmor(ware.slot)"
                                                        :second="ware.armorValue" />
                                                </p>
                                                <p v-if="shop.type === 'livestock'">Purchase Price:
                                                    <CurrencyDisplay currency="mortal" :value="ware.purchasePrice" />
                                                </p>
                                                <p v-else>
                                                    <CurrencyDisplay currency="mortal"
                                                        :value="shop.type === 'grocery' ? ware.value * ware.quantity : ware.value" />
                                                </p>
                                                <div v-if="shop.type === 'grocery'">
                                                    <ion-button @click="decreaseQuantity(ware)">-</ion-button>
                                                    <ion-button @click="increaseQuantity(ware)">+</ion-button>
                                                    <p>Quantity: {{ ware.quantity }}</p>
                                                </div>
                                                <ion-button @click="buyItem(ware)">Buy</ion-button>
                                            </div>
                                        </ion-label>

                                    </ion-item>
                                </ion-list>

                                <div v-else>
                                    <h4>Martial Offensive</h4>
                                    <ion-list>
                                        <ion-item v-for="ware in shop.stock.martialOffensive" :key="ware.name">
                                            <ion-label>
                                                <h2>{{ capitalizeAllWords(ware?.name || 'Unknown Skill') }}</h2>
                                                <p>Price:
                                                    <CurrencyDisplay currency="immortal" :value="ware.value" />
                                                </p>
                                            </ion-label>
                                            <ion-button @click="buyItem(ware)">Buy</ion-button>
                                        </ion-item>
                                    </ion-list>
                                    <h4>Martial Defensive</h4>
                                    <ion-list>
                                        <ion-item v-for="ware in shop.stock.martialDefensive" :key="ware.name">
                                            <ion-label>
                                                <h2>{{ capitalizeAllWords(ware?.name || 'Unknown Skill') }}</h2>
                                                <p>Price:
                                                    <CurrencyDisplay currency="immortal" :value="ware.value" />
                                                </p>
                                            </ion-label>
                                            <ion-button @click="buyItem(ware)">Buy</ion-button>
                                        </ion-item>
                                    </ion-list>
                                    <h4>Qi Offensive</h4>
                                    <ion-list>
                                        <ion-item v-for="ware in shop.stock.qiOffensive" :key="ware.name">
                                            <ion-label>
                                                <h2>{{ capitalizeAllWords(ware?.name || 'Unknown Skill') }}</h2>
                                                <p>Price:
                                                    <CurrencyDisplay currency="immortal" :value="ware.value" />
                                                </p>
                                            </ion-label>
                                            <ion-button @click="buyItem(ware)">Buy</ion-button>
                                        </ion-item>
                                    </ion-list>
                                    <h4>Qi Defensive</h4>
                                    <ion-list>
                                        <ion-item v-for="ware in shop.stock.qiDefensive" :key="ware.name">
                                            <ion-label>
                                                <h2>{{ capitalizeAllWords(ware?.name || 'Unknown Skill') }}</h2>
                                                <p>Price:
                                                    <CurrencyDisplay currency="immortal" :value="ware.value" />
                                                </p>
                                            </ion-label>
                                            <ion-button @click="buyItem(ware)">Buy</ion-button>
                                        </ion-item>
                                    </ion-list>
                                </div>
                            </ion-card-content>
                        </ion-card>
                    </ion-col>

                    <ion-col size="12" v-if="playerResources.length > 0 && shop && shop.type === 'grocery'">
                        <ion-card>
                            <ion-card-header>
                                <ion-card-title>Character Resources</ion-card-title>
                            </ion-card-header>
                            <ion-card-content>
                                <ion-list>
                                    <ion-item v-for="resource in playerResources" :key="resource.material">
                                        <ion-label>
                                            <h2>{{ resource.material }}</h2>
                                            <p>Quantity: {{ resource.quantity }}</p>
                                            <p>Value:
                                                <CurrencyDisplay currency="mortal"
                                                    :value="resource.value * resource.sellQuantity" />
                                            </p>
                                            <div>
                                                <ion-button @click="setSellQuantity(resource, 0)">&lt;</ion-button>
                                                <ion-button @click="decreaseSellQuantity(resource)">-</ion-button>
                                                <ion-button @click="increaseSellQuantity(resource)">+</ion-button>
                                                <ion-button
                                                    @click="setSellQuantity(resource, resource.quantity)">&gt;</ion-button>
                                                <p>Quantity: {{ resource.sellQuantity }}</p>
                                            </div>
                                        </ion-label>
                                        <ion-button @click="sellResource(resource)">Sell</ion-button>
                                    </ion-item>
                                </ion-list>
                            </ion-card-content>
                        </ion-card>
                    </ion-col>

                    <ion-col size="12"
                        v-if="currentCharacter.inventory.length > 0 && shop && (shop.type === 'weapons' || shop.type === 'armor')">
                        <ion-card>
                            <ion-card-header>
                                <ion-card-title>Character Inventory</ion-card-title>
                            </ion-card-header>
                            <ion-card-content>
                                <ion-list>
                                    <ion-item v-for="item in currentCharacter.inventory" :key="item.id">
                                        <ion-label class="shop-item-label">
                                            <img v-fallback-img class="item-image" v-if="item.image" :src="item.image"
                                                alt="Item Image" />
                                            <h2 :style="{ color: getItemColor(item) }">{{ capitalizeAllWords(item.name)
                                                }}</h2>
                                            <p>
                                                <CurrencyDisplay currency="mortal" :value="(item.value / 2)" />
                                                <ion-button class="sell-button" @click="sellItem(item)">Sell</ion-button>
                                            </p>
                                        </ion-label>
                                       
                                    </ion-item>
                                </ion-list>
                            </ion-card-content>
                        </ion-card>
                    </ion-col>

                    <ion-col size="12"
                        v-if="currentCharacter.livestock.length > 0 && shop && shop.type === 'livestock'">
                        <ion-card>
                            <ion-card-header>
                                <ion-card-title>Character Livestock</ion-card-title>
                            </ion-card-header>
                            <ion-card-content>
                                <ion-list>
                                    <ion-item v-for="livestock in currentCharacter.livestock" :key="livestock.name">
                                        <ion-label>
                                            <h2>{{ capitalizeAllWords(livestock.name) }}</h2>
                                            <p>Sell for
                                                <CurrencyDisplay currency="mortal"
                                                    :value="livestock.mature ? livestock.sellValue : livestock.purchasePrice" />
                                            </p>
                                        </ion-label>
                                        <ion-button @click="sellLivestock(livestock)">Sell</ion-button>
                                    </ion-item>
                                </ion-list>
                            </ion-card-content>
                        </ion-card>
                    </ion-col>

                    <ion-col size="12" v-if="currentCharacter.skillBooks.length > 0 && shop && shop.type === 'library'">
                        <ion-card>
                            <ion-card-header>
                                <ion-card-title>Character Skill Books</ion-card-title>
                            </ion-card-header>
                            <ion-card-content>
                                <ion-list>
                                    <ion-item v-for="skillBook in currentCharacter.skillBooks" :key="skillBook.name">
                                        <ion-label>
                                            <h2>{{ skillBook.name }}</h2>
                                            <p>Sell for
                                                <CurrencyDisplay currency="immortal" :value="skillBook.value" />
                                            </p>
                                        </ion-label>
                                        <ion-button @click="sellSkillBook(skillBook)">Sell</ion-button>
                                    </ion-item>
                                </ion-list>
                            </ion-card-content>
                        </ion-card>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-card>
        <Spinner :isLoading="isLoading" />
    </div>
</template>


<script>
import skillWorker from "../../workers/skillWorker.js";
import shopWorker from "../../workers/shopWorker.js";
import inventoryWorker from "../../workers/inventoryWorker.js";
import characterWorker from "../../workers/characterWorker.js";
import helperFunctions from '../../../serverless-backend/server/helpers/helperFunctions.js';
import cultivationInformation from '../../../serverless-backend/server/datasets/cultivationInformation.json';

import CurrencyDisplay from "../helpers/CurrencyDisplay.vue";
import Indicator from "../framework/Indicator.vue";
import ButtonStack from '../framework/ButtonStack.vue';
import QJButton from "../framework/QJButton.vue";
import Spinner from "../framework/Spinner.vue";

export default {
    components: {
        CurrencyDisplay,
        Indicator,
        ButtonStack,
        QJButton,
        Spinner
    },
    props: {
        shopType: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            shop: undefined,
            defaultColor: "black",
            playerResources: [],
            realms: cultivationInformation.realms,
            types: [],
            isLoading: false
        };
    },
    computed: {
        currentCharacter() {
            return this.$store.getters.getCurrentCharacter;
        },
        user() {
            return this.$store.getters.getCurrentUser;
        },
        characterWeapon() {
            return this.currentCharacter.equipped.filter(item => item.slot === 'weapon')[0].item;
        },
        characterHeadArmor() {
            return this.currentCharacter.equipped.filter(item => item.slot === 'head')[0].item;
        },
        characterChestArmor() {
            return this.currentCharacter.equipped.filter(item => item.slot === 'chest')[0].item;
        },
        characterArmArmor() {
            return this.currentCharacter.equipped.filter(item => item.slot === 'arms')[0].item;
        },
        characterHandArmor() {
            return this.currentCharacter.equipped.filter(item => item.slot === 'hands')[0].item;
        },
        characterWaistArmor() {
            return this.currentCharacter.equipped.filter(item => item.slot === 'waist')[0].item;
        },
        characterLegArmor() {
            return this.currentCharacter.equipped.filter(item => item.slot === 'legs')[0].item;
        },
        characterFootArmor() {
            return this.currentCharacter.equipped.filter(item => item.slot === 'feet')[0].item;
        }

    },
    methods: {
        async buildShop(type) {
            this.isLoading = true;
            try {
                this.shop = undefined;
                const shop = await shopWorker.buildSpecificShop(this.user, this.currentCharacter, type);
                if (shop) {
                    console.log("Got shop: ", shop);
                    this.isLoading = false;
                    this.shop = shop;
                    console.log("Character Weapon: ", this.characterWeapon);
                } else {
                    console.error("Failed to generate shop: ", type);
                }
            } catch (error) {
                console.error("Error building shop: ", error);
            }
        },
        async buildGroceryStore() {
            this.isLoading = true;
            try {
                this.shop = undefined;
                const shop = await shopWorker.buildGroceryShop();
                if (shop) {
                    console.log("Got grocery shop: ", shop);
                    this.isLoading = false;
                    this.shop = shop;
                    this.shop.stock = this.initializeGroceryStock(this.shop.stock);
                    this.buildPlayerResourceInventory();
                } else {
                    console.error("Failed to generate grocery shop");
                }
            } catch (error) {
                console.error("Error building grocery shop: ", error);
            }
        },
        async buildLivestockStore() {
            this.isLoading = true;
            try {
                this.shop = undefined;
                const shop = await shopWorker.buildLivestockShop();
                if (shop) {
                    console.log("Got livestock shop: ", shop);
                    this.isLoading = false;
                    this.shop = shop;
                } else {
                    console.error("Failed to generate livestock shop");
                }
            } catch (error) {
                console.error("Error building livestock shop: ", error);
            }
        },
        async buildLibraryShop() {
            this.isLoading = true;
            try {
                this.shop = undefined;
                const realm = this.getCurrentRealm();
                const skills = await this.generateLibrarySkills(realm);
                const shop = {
                    name: "Library Shop",
                    shopKeeper: "Sage Li",
                    type: "library",
                    stock: skills
                };
                console.log("Got library shop: ", shop);
                this.isLoading = false;
                this.shop = shop;
            } catch (error) {
                console.error("Error building library shop: ", error);
            }
        },
        initializeGroceryStock(stock) {
            return stock.map(item => ({ ...item, quantity: 1 }));
        },
        async generateLibrarySkills(realm) {
            const level = this.currentCharacter.level;
            const martialOffensive = await this.generateSkills('martial_offensive_skills', realm, level, 5);
            const martialDefensive = await this.generateSkills('martial_defensive_skills', realm, level, 5);
            const qiOffensive = await this.generateSkills('qi_offensive_skills', realm, level, 5);
            const qiDefensive = await this.generateSkills('qi_defensive_skills', realm, level, 5);
            return {
                martialOffensive,
                martialDefensive,
                qiOffensive,
                qiDefensive
            };
        },
        async generateSkills(type, realm, level, count) {
            const skills = [];
            const skillNames = new Set();
            for (let i = 0; i < count; i++) {
                try {
                    const skill = await skillWorker.generateSkillByRealmAndType(this.user, realm, type, level);
                    if (skill && skill.name && !skillNames.has(skill.name)) {
                        skills.push(skill);
                        skillNames.add(skill.name);
                    } else if (skillNames.has(skill.name)) {
                        i--; // Ensure we try again for a unique skill
                    } else {
                        console.error(`Generated skill is invalid:`, skill);
                    }
                } catch (error) {
                    console.error(`Error generating skill of type ${type} for realm ${realm}:`, error);
                }
            }
            return skills;
        },
        selectSlotArmor(slot) {
            switch (slot) {
                case 'head':
                    return this.characterHeadArmor.armorValue;
                case 'chest':
                    return this.characterChestArmor.armorValue;
                case 'arms':
                    return this.characterArmArmor.armorValue;
                case 'hands':
                    return this.characterHandArmor.armorValue;
                case 'waist':
                    return this.characterWaistArmor.armorValue;
                case 'legs':
                    return this.characterLegArmor.armorValue;
                case 'feet':
                    return this.characterFootArmor.armorValue;
                default:
                    return 0;
            }
        },
        getCurrentRealm() {
            return this.currentCharacter.realm;
        },
        setQuantity(ware, quantity) {
            ware.quantity = quantity;
            this.updateShopDisplay();
        },
        setSellQuantity(resource, quantity) {
            resource.sellQuantity = quantity;
            this.updateResourceDisplay();
        },
        increaseQuantity(ware) {
            ware.quantity++;
            this.updateShopDisplay();
        },
        decreaseQuantity(ware) {
            if (ware.quantity > 1) {
                ware.quantity--;
                this.updateShopDisplay();
            }
        },
        async buyItem(ware) {
            if (this.shop.type === 'library') {
                const coreAmount = ware.value;
                const playerCoreAmount = this.currentCharacter.immortalCurrency;
                if (playerCoreAmount >= coreAmount) {
                    await inventoryWorker.addSkillBookToInventory(this.$store, this.user, this.currentCharacter, ware);
                    await characterWorker.modifyCharacterImmortalCurrency(this.$store, this.user, this.currentCharacter, -coreAmount);
                    this.removeItem(ware);
                } else {
                    console.log(`Not enough cores!`);
                }
            } else {
                const totalCost = this.shop.type === 'grocery' ? ware.value * ware.quantity : ware.purchasePrice || ware.value;
                if (this.currentCharacter.mortalCurrency >= totalCost) {
                    if (this.shop.type === 'grocery') {
                        await inventoryWorker.addResourcesToInventory(this.$store, this.user, this.currentCharacter, { name: ware.name, quantity: ware.quantity, value: ware.value });
                    } else if (this.shop.type === 'livestock') {
                        await inventoryWorker.addLivestockToInventory(this.$store, this.user, this.currentCharacter, ware);
                    } else {
                        await inventoryWorker.addToInventory(this.$store, this.user, this.currentCharacter, ware);
                    }
                    await characterWorker.modifyCharacterMortalCurrency(this.$store, this.user, this.currentCharacter, -totalCost);
                    if (this.shop.type === 'weapons' || this.shop.type === 'armor' || this.shop.type === 'library') {
                        this.removeItem(ware);
                    }
                    this.buildPlayerResourceInventory();
                } else {
                    console.log("Not enough mortalCurrency");
                }
            }
        },
        async sellResource(resource) {
            const sellValue = resource.value * resource.sellQuantity;
            await inventoryWorker.removeFromResources(this.$store, this.user, this.currentCharacter, { name: resource.material, quantity: resource.sellQuantity });
            await characterWorker.modifyCharacterMortalCurrency(this.$store, this.user, this.currentCharacter, sellValue);
            this.buildPlayerResourceInventory();
        },
        increaseSellQuantity(resource) {
            if (resource.sellQuantity < resource.quantity) {
                resource.sellQuantity++;
                this.updateResourceDisplay();
            }
        },
        decreaseSellQuantity(resource) {
            if (resource.sellQuantity > 1) {
                resource.sellQuantity--;
                this.updateResourceDisplay();
            }
        },
        async sellSkillBook(skillBook) {
            await inventoryWorker.removeSkillBookFromInventory(this.$store, this.user, this.currentCharacter, skillBook);
            await characterWorker.modifyCharacterImmortalCurrency(this.$store, this.user, this.currentCharacter, skillBook.value);
            this.addItemToLibraryStock(skillBook);
        },
        removeItem(ware) {
            if (this.shop.type === 'library') {
                const categories = ['martialOffensive', 'martialDefensive', 'qiOffensive', 'qiDefensive'];
                for (const category of categories) {
                    const index = this.shop.stock[category].findIndex(item => item.name === ware.name);
                    if (index > -1) {
                        this.shop.stock[category].splice(index, 1);
                        return;
                    }
                }
            } else if (this.shop.type === 'weapons' || this.shop.type === 'armor') {
                const index = this.shop.stock.findIndex(item => item.name === ware.name);
                if (index > -1) {
                    this.shop.stock.splice(index, 1);
                }
            }
        },
        addItemToLibraryStock(skillBook) {
            const categories = {
                'Martial Offensive': 'martialOffensive',
                'Martial Defensive': 'martialDefensive',
                'Qi Offensive': 'qiOffensive',
                'Qi Defensive': 'qiDefensive'
            };
            const category = categories[skillBook.type];
            if (category) {
                this.shop.stock[category].push(skillBook);
            }
        },
        addItemToShopStock(item) {
            if (this.shop.type === 'weapons' || this.shop.type === 'armor') {
                this.shop.stock.push(item);
            }
        },
        buildPlayerResourceInventory() {
            this.playerResources = this.currentCharacter.resources.map(resource => ({
                ...resource,
                sellQuantity: 1
            }));
        },
        async sellItem(item) {
            let sellValue = (item.value / 2);
            await inventoryWorker.removeFromInventory(this.$store, this.user, this.currentCharacter, item);
            await characterWorker.modifyCharacterMortalCurrency(this.$store, this.user, this.currentCharacter, sellValue);
            this.addItemToShopStock(item);
            this.buildPlayerResourceInventory();
        },
        async sellLivestock(livestock) {
            let sellValue;
            if (livestock.mature === true) {
                sellValue = livestock.sellValue;
            } else {
                sellValue = livestock.purchasePrice;
            }
            await inventoryWorker.removeLivestockFromInventory(this.$store, this.user, this.currentCharacter, livestock);
            await characterWorker.modifyCharacterMortalCurrency(this.$store, this.user, this.currentCharacter, sellValue);
            this.buildPlayerResourceInventory();
        },
        updateShopDisplay() {
            this.shop.stock = [...this.shop.stock];
        },
        updateResourceDisplay() {
            this.playerResources = [...this.playerResources];
        },
        capitalizeAllWords(string) {
            return helperFunctions.capitalizeAllWords(string);
        },
        formatSkillValue(value) {
            return Object.entries(value).map(([key, val]) => `${val} ${key}`).join(', ');
        },
        getItemColor(item) {
            return (this.shop.type === 'weapons' || this.shop.type === 'armor') && item.rarity ? item.rarity.color : this.defaultColor;
        },
        getAllTypes() {
            const typesSet = new Set();
            cultivationInformation.realms.forEach(realm => {
                Object.keys(realm).forEach(key => {
                    if (Array.isArray(realm[key])) {
                        typesSet.add(key);
                    }
                });
            });
            return Array.from(typesSet);
        }
    },
    created() {
        this.types = this.getAllTypes();
    },
    mounted() {
        this.buildPlayerResourceInventory();
        console.log("Shop type: ", this.shopType);
        if (this.shopType === "grocery-store") {
            this.buildGroceryStore();
        } else if (this.shopType === "livestock-store") {
            this.buildLivestockStore();
        } else if (this.shopType === "library-store") {
            this.buildLibraryShop();
        } else {
            this.buildShop(this.shopType);
        }
    }
};
</script>

<style scoped>
.shop-component {
    text-align: center;
    margin-top: 20px;
    max-width: 100%;
    box-sizing: border-box;
}

.shop-row {
    padding: 12px;
}

.shop-display {
    border-radius: 10px;
    border: solid #ccc 2px;
    margin-top: 10px;
    display: flex;
    flex-direction: column; /* Ensure it stacks vertically */
    width: 100%;
    box-sizing: border-box; /* Ensure padding/border are included in width */
}

.v-list-item {
    border-radius: 12px;
    border: 1px solid #ccc;
    margin-bottom: 8px;
    overflow: hidden;
}

.shop-item-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
}

.shop-item-label>div:first-child {
    flex: 0 0 auto;
}

.shop-item-label>div:nth-child(2) {
    flex: 1;
    text-align: center;
    margin-right: 10px;
}

.shop-item-label>div:last-child {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.item-image {
    width: 150px;
    height: 150px;
    margin-right: 10px;
}

.sell-button {
    margin-left: 10px;
}

.shop-item-label h2 {
    margin: 0;
}

/* Mobile layout */
@media (max-width: 600px) {

    html,
    body {
        padding: 0;
        margin: 0;
        width: 100%;
        overflow-x: hidden;
    }

    .shop-display {
        width: 100%;
        justify-content: center;
        padding: 0 10px; /* Add padding to ensure it's not too close to the edges */
    }

    .shop-item-label {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 10px;
        border: 1px solid #ddd;
        margin-bottom: 10px;
        border-radius: 5px;
        background-color: #fff;
        width: 100%;
        box-sizing: border-box;
    }

    .shop-item-label>div {
        width: 100%;
        margin: 5px 0;
    }

    .item-image {
        margin: 0 auto;
        display: block;
        max-width: 150px;
        height: auto;
    }

    .shop-item-label h2 {
        margin: 5px 0;
        font-size: 1.2em;
    }

    .shop-item-label p {
        margin: 5px 0;
        font-size: 1em;
    }

    .shop-item-label>div:last-child {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .sell-button {
        margin-left: 5px;
        width: 100%;
    }
}

.damage-text {
    display: flex;
    align-items: center;
}

.damage-text Indicator {
    margin-left: 10px;
}

.button-stack button {
    margin: 5px;
}

.currency-display {
    margin: 10px;
}

.quantity-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -5px;
    margin-bottom: -5px;
}

.quantity-button {
    width: 25px;
    height: 25px;
    border: solid black 2px;
    border-radius: 5px;
    margin: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.item-name {
    font-weight: bold;
}

button {
    margin: 5px;
}

</style>