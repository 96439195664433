<template>
    <div class="indicator">
      <span v-if="first < second || first === undefined" class="up-arrow">▲</span>
      <span v-else-if="first > second" class="down-arrow">▼</span>
      <span v-else class="equal">—</span>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      first: {
        type: Number,
        required: true
      },
      second: {
        type: Number,
        required: true
      }
    }
  }
  </script>
  
  <style scoped>
  .up-arrow {
    color: green;
    font-size: 1.5em;
  }
  
  .down-arrow {
    color: red;
    font-size: 1.5em;
  }
  
  .equal {
    color: gray;
    font-size: 1.5em;
  }
  </style>