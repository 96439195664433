<template>
  <button class="qj-button" expand="block">
    <slot></slot>
  </button>
</template>

<script>

export default {
  name: "QJButton",
};
</script>

<style scoped>
.qj-button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
  height: 70px !important;
  border-radius: 8px !important;
  background-color: #8B4513 !important;
  /* SaddleBrown for primary color */
  background: url('../../../public/assets/images/svgs/border.svg') no-repeat center center;
  background-size: cover;
  color: white !important;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease !important;
  text-align: center;
}

.qj-button:hover {
  background-color: #DAA520 !important;
  /* GoldenRod for hover */
}

@media (min-width: 768px) {
  .qj-button {
    width: 100% !important;
    /* Width for larger screens */
  }
}
</style>