import { defineComponent } from 'vue';
import {
  IonContent, 
  IonGrid, 
  IonRow, 
  IonList, 
  IonTitle,
  IonCol, 
  IonItem, 
  IonLabel, 
  IonInput, 
  IonButton, 
  IonText, 
  IonCard, 
  IonCardHeader, 
  IonCardTitle, 
  IonCardSubtitle, 
  IonCardContent, 
  IonModal, 
  IonThumbnail, 
  IonIcon,
  IonChip,
  IonButtons,
  IonSelectOption,
  IonSelect,
  IonToolbar,
  IonFooter
} from '@ionic/vue';

export default {
  install(app) {
    app.component('IonContent', IonContent);
    app.component('IonGrid', IonGrid);
    app.component('IonRow', IonRow);
    app.component('IonList', IonList);
    app.component('IonTitle', IonTitle);
    app.component('IonCol', IonCol);
    app.component('IonItem', IonItem);
    app.component('IonLabel', IonLabel);
    app.component('IonInput', IonInput);
    app.component('IonButton', IonButton);
    app.component('IonText', IonText);
    app.component('IonCard', IonCard);
    app.component('IonCardHeader', IonCardHeader);
    app.component('IonCardTitle', IonCardTitle);
    app.component('IonCardSubtitle', IonCardSubtitle);
    app.component('IonCardContent', IonCardContent);
    app.component('IonModal', IonModal);
    app.component('IonThumbnail', IonThumbnail);
    app.component('IonIcon', IonIcon);
    app.component('IonChip', IonChip);
    app.component('IonButtons', IonButtons);
    app.component('IonSelectOption', IonSelectOption);
    app.component('IonSelect', IonSelect);
    app.component('IonToolbar', IonToolbar);
    app.component('IonFooter', IonFooter);
  }
};
