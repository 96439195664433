<template>
  <div class="ion-padding">
    <ion-grid>
      <!-- Tabs -->
      <ion-row>
        <ion-col size="12">
          <div class="tabs">
            <QJButton expand="block" @click="activeTab = 'attributes'">Attributes</QJButton>
            <QJButton expand="block" @click="showUpgradeView = false; activeTab = 'skills'">Skills</QJButton>
          </div>
          <QJButton class="enter-world-button" @click="$router.push('/world-map-test/')">Enter World</QJButton>
        </ion-col>
      </ion-row>

      <!-- Spinner -->
      <Spinner :isLoading="loading" />

      <!-- Attributes Tab -->
      <ion-row v-if="activeTab === 'attributes' && currentCharacter && !loading">
        <ion-col size="12">
          <ion-card class="ion-padding text-center">
            <ion-card-header>
              <ion-card-title>{{ currentCharacter.name }}</ion-card-title>
              <ion-card-subtitle>Background: {{ currentCharacter.background }}</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <p>Potential: Realm {{ currentCharacter.potential }}</p>
              <p>Attribute Points: {{ freeAttributePoints }}</p>
              <p>Strength: {{ attributeBank.strength }} <button v-if="freeAttributePoints > 0" class="add-to-attribute-button"
                  @click="addToAttributes('strength')">+</button></p>
              <p>Endurance: {{ attributeBank.endurance }} <button v-if="freeAttributePoints > 0" class="add-to-attribute-button"
                  @click="addToAttributes('endurance')">+</button></p>
              <p>Intelligence: {{ attributeBank.intelligence }} <button v-if="freeAttributePoints > 0" class="add-to-attribute-button"
                  @click="addToAttributes('intelligence')">+</button></p>
              <p>Agility: {{ attributeBank.agility }} <button v-if="freeAttributePoints > 0" class="add-to-attribute-button"
                  @click="addToAttributes('agility')">+</button></p>
              <p>Spirit: {{ attributeBank.spirit }} <button v-if="freeAttributePoints > 0" class="add-to-attribute-button"
                  @click="addToAttributes('spirit')">+</button></p>
              <p>Charisma: {{ attributeBank.charisma }} <button v-if="freeAttributePoints > 0" class="add-to-attribute-button"
                  @click="addToAttributes('charisma')">+</button></p>
              <p>Luck: {{ attributeBank.luck }} <button v-if="freeAttributePoints > 0" class="add-to-attribute-button"
                  @click="addToAttributes('luck')">+</button></p>
              <button v-if="applyingAttributes" @click="updateCharacterAttributes">Apply Attributes</button>
              <p>Realm: {{ currentCharacter.realm }}</p>
              <p>Stage: {{ currentCharacter.stage }}</p>
              <p>Rebirth: {{ currentCharacter.rebirth }}</p>
              <p>Qi: {{ currentCharacter.qiTotal }} / {{ currentCharacter.qiToNextLevel }}</p>
              <p>Health: {{ currentCharacter.health }} / {{ currentCharacter.maxHealth }}</p>
              <p>Stamina: {{ currentCharacter.stamina }} / {{ currentCharacter.maxStamina }}</p>
              <p>Vital Energy: {{ currentCharacter.vitalEnergy }} / {{ currentCharacter.maxVitalEnergy }}</p>
              <p>Resistance: {{ currentCharacter.resistance }}</p>
              <p>Armor: {{ currentCharacter.armorValue }}</p>
              <p>Carry Weight: {{ currentCharacter.carryWeight }} / {{ currentCharacter.maxCarryWeight }}</p>
              <p>Currency:</p>
              <p>Mortal:
                <CurrencyDisplay currency="mortal" :value="currentCharacter.mortalCurrency" />
              </p>
              <p>Immortal:
                <CurrencyDisplay currency="immortal" :value="currentCharacter.immortalCurrency" />
              </p>
            </ion-card-content>
            <ion-card-footer>
              <QJButton v-if="currentCharacter.stage === 9" expand="block" @click="goToTribulation">Start
                Tribulation</QJButton>
            </ion-card-footer>
          </ion-card>
        </ion-col>
      </ion-row>

      <!-- Skills Tab -->
      <ion-row v-if="activeTab === 'skills' && currentCharacter && !loading" :key="currentCharacter">
        <ion-col size="12" v-if="!showUpgradeView">
          <h2>Skills</h2>
          <ion-list>
            <ion-item v-for="skill in currentCharacter.skills" :key="skill.name">
              <ion-card class="ion-padding-bottom ion-margin-bottom">
                <ion-card-header>
                  <ion-card-title>{{ skill.name }}</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <p>Realm: {{ skill.realm }}</p>
                  <p>Type: {{ skill.type }}</p>
                  <p v-if="skill.requirements">Requirements:</p>
                  <p v-for="(value, key) in skill.requirements" :key="key">{{ key }}: {{ value }}</p>
                  <p v-if="skill.effect">Effect:</p>
                  <p v-for="(value, key) in skill.effect" :key="key">{{ key }}: {{ value }}</p>
                  <p>Value: {{ skill.value }}</p>
                  <p>Level: {{ skill.level }}</p>
                </ion-card-content>
                <ion-card-footer>
                  <QJButton v-if="currentCharacter.level > skill.level" expand="block"
                    @click="prepareUpgradeSkill(skill)">Upgrade Skill</QJButton>
                </ion-card-footer>
              </ion-card>
            </ion-item>
          </ion-list>
        </ion-col>

        <!-- Upgrade View -->
        <ion-col size="12" v-if="showUpgradeView" class="upgrade-view">
          <ion-row class="skill-comparison">
            <ion-col size="5" class="current-skill">
              <ion-card class="ion-padding-bottom ion-margin-bottom">
                <ion-card-header>
                  <ion-card-title>Current Skill</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <p>Name: {{ skillToUpgrade.name }}</p>
                  <p>Realm: {{ skillToUpgrade.realm }}</p>
                  <p>Type: {{ skillToUpgrade.type }}</p>
                  <p v-if="skillToUpgrade.requirements">Requirements:</p>
                  <p v-for="(value, key) in skillToUpgrade.requirements" :key="key">{{ key }}: {{ value }}</p>
                  <p v-if="skillToUpgrade.effect">Effect:</p>
                  <p v-for="(value, key) in skillToUpgrade.effect" :key="key">{{ key }}: {{ value }}</p>
                  <p>Value: {{ skillToUpgrade.value }}</p>
                  <p>Level: {{ skillToUpgrade.level }}</p>
                </ion-card-content>
              </ion-card>
            </ion-col>

            <ion-col size="2" class="arrow ion-text-center">
              <ion-icon name="arrow-forward-outline" size="large"></ion-icon>
            </ion-col>

            <ion-col size="5" class="new-skill">
              <ion-card class="ion-padding-bottom ion-margin-bottom">
                <ion-card-header>
                  <ion-card-title>Upgraded Skill</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <p>Name: {{ upgradedSkill.name }}</p>
                  <p>Realm: {{ upgradedSkill.realm }}</p>
                  <p>Type: {{ upgradedSkill.type }}</p>
                  <p v-if="upgradedSkill.requirements">Requirements:</p>
                  <p v-for="(value, key) in upgradedSkill.requirements" :key="key">{{ key }}: {{ value }}</p>
                  <p v-if="upgradedSkill.effect">Effect:</p>
                  <p v-for="(value, key) in upgradedSkill.effect" :key="key">{{ key }}: {{ value }}</p>
                  <p>Value: {{ upgradedSkill.value }}</p>
                  <p>Level: {{ upgradedSkill.level }}</p>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
          <ion-row class="button-stack ion-text-center">
            <ion-col>
              <QJButton color="primary" expand="block" @click="confirmUpgradeSkill">Confirm</QJButton>
              <QJButton color="secondary" expand="block" @click="cancelUpgrade">Cancel</QJButton>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import axios from 'axios';
import { getAuth, useDeviceLanguage } from "firebase/auth";
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import _ from 'lodash';
import CurrencyDisplay from '../helpers/CurrencyDisplay.vue';
import skillWorker from '../../workers/skillWorker.js';
import characterWorker from '../../workers/characterWorker.js';
import Spinner from '../framework/Spinner.vue';
import QJButton from '../framework/QJButton.vue';

const apiUrl = process.env.VUE_APP_API_URL;

export default {
  components: {
    CurrencyDisplay,
    Spinner, 
    QJButton
  },
  setup() {
    const router = useRouter();

    const goBackToCharacterList = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const userId = user.uid;
        router.push(`/characters/${userId}`);
      } else {
        console.error("User not authenticated");
      }
    };

    const goToGenerateEquipment = () => {
      router.push(`/equipment-test`);
    };
    const goToGenerateEnemy = () => {
      router.push(`/enemy-test`);
    };
    const goToCombat = () => {
      router.push(`/random-combat`);
    };

    return { goBackToCharacterList, goToGenerateEquipment, goToGenerateEnemy, goToCombat };
  },
  data() {
    return {
      activeTab: 'attributes',
      currentCharacter: null,
      showUpgradeView: false,
      skillToUpgrade: null,
      upgradedSkill: null,
      loading: true,
      freeAttributePoints: 0,
      applyingAttributes: false,
      attributeBank: {
        strength: 0,
        endurance: 0,
        intelligence: 0,
        agility: 0,
        spirit: 0,
        charisma: 0,
        luck: 0,
      }
    }
  },
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
  },
  methods: {
    addToAttributes(attribute) {
      this.attributeBank[attribute] += 1;
      this.freeAttributePoints -= 1;
      this.applyingAttributes = true;
    },

    updateCharacterAttributes() {
      let clonedCharacter = _.cloneDeep(this.currentCharacter);
      Object.entries(this.attributeBank).forEach(([key]) => {
        clonedCharacter[key] = this.attributeBank[key];
      });

      clonedCharacter.attributePoints = this.freeAttributePoints;

      clonedCharacter = characterWorker.levelUpCharacterStats(this.currentCharacter, clonedCharacter);
      console.log("Leveled Up Character:", clonedCharacter);
      characterWorker.updateCharacter(this.$store, this.user, clonedCharacter);
      console.log("Updated Character:", clonedCharacter);

      this.applyingAttributes = false;
      console.log("Applying Attributes:", this.applyingAttributes);
    },

    async prepareUpgradeSkill(skill) {
      try {
        const newSkill = await skillWorker.generateSkillByName(this.user, skill.name, this.currentCharacter.level);
        this.skillToUpgrade = skill;
        this.upgradedSkill = newSkill;
        this.showUpgradeView = true;
      } catch (error) {
        console.error("Failed to prepare skill upgrade", error);
      }
    },

    async confirmUpgradeSkill() {
      try {
        const clonedCharacter = _.cloneDeep(this.currentCharacter);

        if (this.skillToUpgrade.realm === clonedCharacter.realm) {
          // Upgrade realm and stats
          this.upgradedSkill.level = clonedCharacter.level;
          this.upgradedSkill.realm = clonedCharacter.realm;
        } else {
          // Only upgrade stats
          this.upgradedSkill.level = clonedCharacter.level;
        }

        if (clonedCharacter.immortalCurrency >= this.upgradedSkill.value) {
          clonedCharacter.immortalCurrency -= this.upgradedSkill.value;
          const skillIndex = clonedCharacter.skills.findIndex(s => s.name === this.skillToUpgrade.name);
          if (skillIndex !== -1) {
            clonedCharacter.skills.splice(skillIndex, 1, this.upgradedSkill);
          }
          await characterWorker.updateCharacter(this.$store, this.user, clonedCharacter);
          this.$store.commit('setCurrentCharacter', clonedCharacter);
          this.showUpgradeView = false;
        } else {
          this.$message.error('Not enough immortal currency.');
        }
      } catch (error) {
        console.error("Failed to upgrade skill", error);
      }
    },
    cancelUpgrade() {
      this.showUpgradeView = false;
      this.skillToUpgrade = null;
      this.upgradedSkill = null;
    },
    formatRequirements(requirements) {
      return Object.entries(requirements).map(([key, value]) => `${key}: ${value}`).join(', ');
    },
    goToTribulation() {
      this.$router.push('/tribulation');
    }
  },
  async mounted() {
    const characterId = this.$route.params.id;

    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const token = await user.getIdToken(true);

      const response = await axios.get(`${apiUrl}/api/characters/${characterId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (response.status === 403) {
        return;
      }

      this.currentCharacter = response.data;
      this.$store.commit('setCurrentCharacter', this.currentCharacter);
      console.log('Current character:', this.currentCharacter);
    } else {
      router.push('/auth');
    }
    this.freeAttributePoints = this.currentCharacter.attributePoints;
    this.attributeBank = {
      strength: this.currentCharacter.strength,
      endurance: this.currentCharacter.endurance,
      intelligence: this.currentCharacter.intelligence,
      agility: this.currentCharacter.agility,
      spirit: this.currentCharacter.spirit,
      charisma: this.currentCharacter.charisma,
      luck: this.currentCharacter.luck,
    };
    this.loading = false;
  }
};
</script>

<style scoped>
.character-info {
  padding: 10px;
  margin: 10px;
  border: 2px solid black;
  border-radius: 10px;
  text-align: center;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.skill-card {
  border: 2px solid black;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
}

button {
  margin: 0 10px;
  padding: 10px;
  cursor: pointer;
}

.enter-world-button {
  width: 75%!important;
  margin-left: auto;
  margin-right: auto;
}

.upgrade-view {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skill-comparison {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.current-skill,
.new-skill {
  border: 2px solid black;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  width: 200px;
}

.arrow {
  font-size: 2em;
  margin: 0 20px;
}

.ion-card-text p {
  margin-bottom: 8px;
  /* Add some gap between lines */
}
</style>
