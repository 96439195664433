<template>
  <div class="testing-container">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <ion-text>
            <h1>Minigame Testing</h1>
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ButtonStack :startOpen="true" class="minigame-selection">
            <QJButton @click="currentMinigame = 'reflex'">Reflex Minigame</QJButton>
            <QJButton @click="currentMinigame = 'mining'">Mining Minigame</QJButton>
            <QJButton @click="currentMinigame = 'memory'">Memory Minigame</QJButton>
            <QJButton @click="currentMinigame = 'hunting'">Hunting Minigame</QJButton>
            <QJButton @click="currentMinigame = 'fishing'">Fishing Minigame</QJButton>
          </ButtonStack>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <div class="minigame-display">
            <ReflexMinigame v-if="currentMinigame === 'reflex'" :difficulty="difficulty" :skill="skill"
              :startRunning="true" @score="receiveScore" @close="handleClose" />
            <MiningMinigame v-else-if="currentMinigame === 'mining'" :numTurns="numTurns" :numOres="numOres"
              @close="handleClose" />
            <MemoryMinigame v-else-if="currentMinigame === 'memory'" @close="handleClose" />
            <HuntingMinigame v-else-if="currentMinigame === 'hunting'" @close="handleClose" />
            <FishingMinigame v-else-if="currentMinigame === 'fishing'" @close="handleClose" />
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import ReflexMinigame from '../minigames/ReflexGame.vue';
import MiningMinigame from '../minigames/MiningGame.vue';
import MemoryMinigame from '../minigames/MemoryGame.vue';
import HuntingMinigame from '../minigames/HuntingGame.vue';
import FishingMinigame from '../minigames/FishingGame.vue';

import ButtonStack from '../framework/ButtonStack.vue';
import QJButton from '../framework/QJButton.vue';

export default {
  components: {
    ButtonStack,
    QJButton,
    ReflexMinigame,
    MiningMinigame,
    MemoryMinigame,
    HuntingMinigame,
    FishingMinigame
  },
  data() {
    return {
      currentMinigame: null,
      difficulty: 7,
      skill: 4,
      numTurns: 15,
      numOres: 10
    };
  },
  methods: {
    handleClose() {
      this.currentMinigame = null;
    },
    receiveScore(score) {
      console.log('Score:', score);
    }
  }
};
</script>

<style scoped>
.testing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.minigame-selection {
  margin-bottom: 20px;
}

.minigame-selection button {
  height: 80px;
  width: 100px;
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
</style>