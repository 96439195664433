<template>
  <div>
    <ion-text>
      <h2>Memory Game</h2>
    </ion-text>
    <div class="grid">
      <div v-for="(card, index) in cards" :key="index" class="card" @click="flipCard(index)"
        :class="{ 'flipped': card.flipped || card.matched }">
        <div class="card-inner">
          <div class="card-front">{{ card.character }}</div>
          <div class="card-back"></div>
        </div>
      </div>
    </div>
    <div class="controls">
      <QJButton @click="resetGame">Reset Game</QJButton>
      <br>
      <ion-text>
        <p>Guesses left: {{ guessesLeft }}</p>
        <p v-if="gameOver">Score: {{ score }}</p>
      </ion-text>
    </div>
  </div>
</template>

<script>
import QJButton from '../framework/QJButton.vue'

export default {
  data() {
    return {
      characters: [
        '好', '学', '习', '生', '教', '师', '文', '化',
        '天', '地', '山', '河', '日', '月', '风', '雨',
        '龙', '凤', '虎', '熊', '猫', '狗', '鼠', '鸟',
        '鱼', '马', '牛', '羊', '猪', '鸡', '兔', '蛇'
      ],
      cards: [],
      flippedCards: [],
      guessesLeft: 30,
      score: 0,
      gameOver: false,
    };
  },
  created() {
    this.resetGame();
  },
  methods: {
    initializeCards() {
      const shuffledCharacters = this.characters.sort(() => 0.5 - Math.random()).slice(0, 8);
      const pairs = [...shuffledCharacters, ...shuffledCharacters];
      this.cards = pairs.sort(() => 0.5 - Math.random()).map(character => ({
        character,
        flipped: false,
        matched: false,
      }));
    },
    flipCard(index) {
      if (this.cards[index].flipped || this.cards[index].matched || this.flippedCards.length === 2) return;

      this.cards[index].flipped = true;
      this.flippedCards.push(index);

      if (this.flippedCards.length === 2) {
        this.checkForMatch();
      }
    },
    checkForMatch() {
      const [firstIndex, secondIndex] = this.flippedCards;
      if (this.cards[firstIndex].character === this.cards[secondIndex].character) {
        this.cards[firstIndex].matched = true;
        this.cards[secondIndex].matched = true;
        this.flippedCards = [];
        if (this.cards.every(card => card.matched)) {
          this.calculateScore(true);
        }
      } else {
        setTimeout(() => {
          this.cards[firstIndex].flipped = false;
          this.cards[secondIndex].flipped = false;
          this.flippedCards = [];
          this.guessesLeft -= 1;
          if (this.guessesLeft === 0) {
            this.calculateScore(false);
          }
        }, 1000);
      }
    },
    calculateScore(allMatched) {
      if (allMatched) {
        this.score = 100;
      } else {
        const matchedPairs = this.cards.filter(card => card.matched).length / 2;
        this.score = Math.round((matchedPairs / 8) * 100);
      }
      this.gameOver = true;
      this.$emit('game-finished', this.score);
    },
    resetGame() {
      this.initializeCards();
      this.flippedCards = [];
      this.guessesLeft = 30;
      this.score = 0;
      this.gameOver = false;
    },
  },
};
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(4, 100px);
  gap: 10px;
  margin: 20px auto;
}

.card {
  width: 100px;
  height: 100px;
  perspective: 1000px;
}

@media screen and (max-width: 600px) {
  .grid {
    grid-template-columns: repeat(4, 75px);
  }

  .card {
    width: 75px;
    height: 75px;
  }

}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card.flipped .card-inner,
.card.matched .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}

.card-front {
  background: #fff;
  border: 1px solid #ddd;
  transform: rotateY(180deg);
}

.card-back {
  background: #333;
  color: #fff;
}

.controls {
  text-align: center;
  margin-top: 20px;
}
</style>
