import axios from 'axios';

// Get the API URL from the environment variable
const apiUrl = process.env.VUE_APP_API_URL;

const generateRandomQuest = async function(user, character) {
    try {
        // Check if user exists
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = {
            character: character,
        };

        const response = await axios.post(`${apiUrl}/api/quests/generate-random-quest/`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let quest = response.data;
        return quest;
    } catch (error) {
        console.error("Failed to generate random quest", error);
    }
}

export default {
  generateRandomQuest
}
