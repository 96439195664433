<template>
  <div>
    <ion-grid>
      <ion-row>
        <ion-col>
          <div class="button-stack">
            <ion-button expand="block" @click="generateRandomEnemy">Generate Enemy</ion-button>
          </div>
        </ion-col>
      </ion-row>

      <ion-row v-if="generatedEnemy">
        <ion-col>
          <ion-card class="display-card">
            <ion-card-header>
              <ion-card-title>Generated Enemy</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <p>Name: {{ generatedEnemy.name }}</p>
              <p>Level: {{ generatedEnemy.level }}</p>
              <p>Danger Level: <span :style="{ color: generatedEnemy.dangerLevel.color }">{{
              generatedEnemy.dangerLevel.name }}</span></p>
              <p>Type: {{ generatedEnemy.type.name }}</p>
              <p>Weapon: {{ generatedEnemy.weapon.name }}</p>
              <p>Health: {{ generatedEnemy.health }}</p>
              <p>Armor: {{ generatedEnemy.armorValue }}</p>
              <p>Damage: {{ generatedEnemy.weapon.damage }}</p>
              <p>Speed: {{ generatedEnemy.speed }}</p>
              <p>Loot:</p>
              <p>
                <CurrencyDisplay currency="mortal" :value="generatedEnemy.value" />
              </p>
              <p v-if="generatedEnemy.immortalValue">
                <CurrencyDisplay currency="immortal" :value="generatedEnemy.immortalValue" />
              </p>
              <ul>
                <li v-for="item in generatedEnemy.loot" :key="item.name">
                  {{ item.quantity }} {{ capitalizeAllWords(item.name) }}
                </li>
              </ul>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import axios from 'axios';
import { getAuth } from "firebase/auth";
import generationWorker from '../../workers/generationWorker.js';

import CurrencyDisplay from '../helpers/CurrencyDisplay.vue';
import helperFunctions from '../../../serverless-backend/server/helpers/helperFunctions.js';

export default {
  components: {
    CurrencyDisplay,
  },
  data() {
    return {
      generatedEnemy: undefined,
    };
  },
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
  },
  methods: {
    async generateRandomEnemy() {
      try {
        const response = await generationWorker.generateRandomEnemy(this.$store.getters.getCurrentUser, this.currentCharacter);
        console.log(response);
        this.generatedEnemy = response;
      } catch (error) {
        console.error(error);
      }
    },
    capitalizeAllWords(string) {
      return helperFunctions.capitalizeAllWords(string);
    }
  }
};
</script>