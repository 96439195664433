const { capitalize } = require("lodash");
const cultivationInformation = require('../datasets/cultivationInformation.json');

exports.mulberry32 = (a) => {
  return function () {
    var t = a += 0x6D2B79F5;
    t = Math.imul(t ^ t >>> 15, t | 1);
    t ^= t + Math.imul(t ^ t >>> 7, t | 61);
    return ((t ^ t >>> 14) >>> 0) / 4294967296;
  }
}

// Helper function to get random element from an array
exports.getRandomElement = (array) => {
  return array[Math.floor(Math.random() * array.length)];
};

// Helper function to get random subset from an array
exports.getRandomSubset = (array, count) => {
  const shuffled = array.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

// Helper function to weight an array towards certain outcomes ie: weapon type being ranged more often than melee or explo
// Weights is an object wherein element is the element to be weighted, and weight is the number of times it should be added to the array
exports.getWeightedArray = (weights, array) => {
  const weightedArray = [...array];
  weights.forEach(({ element, weight }) => {
    for (let i = 0; i < weight; i++) {
      weightedArray.push(element);
    }
  });
  return weightedArray;
};

// Helper function to add standard level deviation to other calculations being done.
// Currently returns between 85% and 120% of passed-in value.
// Will need fine-tuning for difficulty, and maybe changes based on what the deviation is being used for.
exports.standardLevelDeviation = (level) => {
  const min = Math.ceil(level * 0.85);
  const max = Math.floor(level * 1.2);
  const modifiedLevel = Math.floor(Math.random() * (max - min + 1)) + min;
  return Math.round(modifiedLevel);
};

exports.rollDice = (sides) => {
  return Math.floor(Math.random() * sides) + 1;
};

exports.calculateMortalCurrency = (currency) => {
  let totalAmount = currency;

  let copper = 0;
  let silver = 0;
  let gold = 0;
  let platinum = 0;

  // Calculate platinum
  platinum = Math.floor(totalAmount / 1000000);
  totalAmount %= 1000000;

  // Calculate gold
  gold = Math.floor(totalAmount / 10000);
  totalAmount %= 10000;

  // Calculate silver
  silver = Math.floor(totalAmount / 100);
  totalAmount %= 100;

  // Copper is whatever remains
  copper = totalAmount;

  // Create an object to store and return the results
  const currencyObject = {
      platinum: platinum,
      gold: gold,
      silver: silver,
      copper: copper
  };

  return currencyObject;
}

exports.calculateImmortalCurrency = (currency) => {
  let totalAmount = currency;

  let dim = 0;
  let bright = 0;
  let radiant = 0;
  let immortal = 0;
  let divine = 0;
  let celestial = 0;
  let eternal = 0;

  eternal = Math.floor(totalAmount / 1000000000000);
  totalAmount %= 1000000000000;

  celestial = Math.floor(totalAmount / 10000000000);
  totalAmount %= 10000000000;

  divine = Math.floor(totalAmount / 100000000);
  totalAmount %= 100000000;

  immortal = Math.floor(totalAmount / 1000000);
  totalAmount %= 1000000;

  radiant = Math.floor(totalAmount / 10000);
  totalAmount %= 10000;

  bright = Math.floor(totalAmount / 100);
  totalAmount %= 100;

  dim = totalAmount;

  // Create an object to store and return the results
  const currencyObject = {
      dim: dim,
      bright: bright,
      radiant: radiant,
      immortal: immortal,
      divine: divine,
      celestial: celestial,
      eternal: eternal
  };

  return currencyObject;
}

// Helper function to generate a unique ID
exports.generateUniqueId = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let id = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    id += characters.charAt(randomIndex);
  }
  return id;
};

exports.capitalizeFirstCharacter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

exports.capitalizeAllWords = (string) => {
  return string.split(' ').map(this.capitalizeFirstCharacter).join(' ');
};

exports.singularize = (word, number) => {
  if (number === 1) {
    return word.endsWith('s') ? word.slice(0, -1) : word;
  }
  return word;
}

exports.getArticle = (word) => {
  if (!word || typeof word !== 'string') {
    return '';
  }

  const vowels = ['a', 'e', 'i', 'o', 'u'];
  const firstLetter = word.trim().charAt(0).toLowerCase();

  // Check if the first letter is a vowel
  if (vowels.includes(firstLetter)) {
    return 'an';
  } else {
    return 'a';
  }
};

exports.getRealm = (level) => {
  console.log("GETTING REALM")
  console.log("Level: " + level);
  const stagesPerRealm = 10;
  const realms = cultivationInformation.realms;
  const totalRealms = realms.length;

  const levelWithinRebirth = (level - 1) % (totalRealms * stagesPerRealm);
  const realmIndex = Math.floor(levelWithinRebirth / stagesPerRealm);

  const realm =  realms[realmIndex].name;
  console.log("Realm: " + realm);
  return realm;
}

exports.getRealmNumber = (level) => {
  console.log("GETTING REALM")
  console.log("Level: " + level);
  const stagesPerRealm = 10;
  const realms = cultivationInformation.realms;
  const totalRealms = realms.length;

  const levelWithinRebirth = (level - 1) % (totalRealms * stagesPerRealm);
  const realm = Math.floor(levelWithinRebirth / stagesPerRealm) + 1;
  console.log("Realm: " + realm);
  return realm;
}

exports.normalizeForS3 = (string) => {
  console.log("String: " + string);
  const normalizedString = string
  .normalize('NFD')
  .replace(/[\u0300-\u036f]/g, '') 
  .replace(/[^a-zA-Z]/g, '') 
  .toLowerCase(); 
  console.log("Normalized String: " + normalizedString);
  return normalizedString;
}