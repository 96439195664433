<template>
  <div v-if="isOpen" class="popup-overlay" :is-open="isOpen">
    <div class="popup-content game-window">
      <CharacterTicker /><br />
      <p v-if="componentLoaded === 'MapComponent'" class="text-center">Red: Enemy (or exit), Purple: Resource Node, Orange: Hunting, Light
        Blue: Fishing</p>
      <slot @close="close"></slot>
    </div>
  </div>
</template>

<script>
import CharacterTicker from '../character/CharacterTicker.vue';

export default {
  components: {
    CharacterTicker
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    componentLoaded: {
      type: String,
      default: ''
    },
  },
  mounted() {
    console.log('Popup mounted');
    console.log('Component loaded:', this.componentLoaded);
  },
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
  },
  methods: {
    close() {
      console.log('Closing popup');
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  z-index: 1000;
}

.popup-content {
  position: absolute;
  top: 0;
  height: calc(var(--vh, 1vh) * 100);
  /* Use --vh for full viewport height */
  width: 100%;
  max-width: 800px;
  overflow-y: auto;
  scrollbar-width: none;
  box-sizing: border-box;
  background-color: white;
  padding: 50px;
  border: solid black 6px;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .popup-content {
    max-width: 100%;
    min-width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    /* Ensure full height on small screens */
    padding: 20px;
    /* Adjust padding for small screens */
  }
}
</style>