<template>
  <div>
    <ion-grid>
      <ion-row v-if="!isRefining && !selectedMedicine" :key="setRefresh">
        <ion-col size="12">
          <ion-label>Select Medicine:</ion-label>
          <ion-text>
            <h2>Select Medicine</h2>
          </ion-text>
          <ion-list class="medicine-list medicine-cards">
            <ion-item v-for="medicine in medicineList" :key="medicine.name" @click="handleMedicineClick(medicine)"
              :class="{ selected: selectedMedicine === medicine.name }" :style="{ color: getMedicineColor(medicine) }">
              <ion-label>{{ medicine.name }}</ion-label>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>

      <ion-row v-if="!isRefining && selectedMedicine">
        <ion-col size="12">
          <ion-text>
            <h2>{{ selectedMedicine }}</h2>
          </ion-text>
          <ion-text>
            <p>{{ selectedMedicineObject.description }}</p>
          </ion-text>
          <div>
            <ion-text>
              <h4>Effects:</h4>
            </ion-text>
            <ion-list>
              <ion-item v-for="(value, key) in selectedMedicineObject.effects" :key="key">
                <ion-label>{{ capitalizeAllWords(key) }}: {{ value }}</ion-label>
              </ion-item>
            </ion-list>
          </div>
          <ion-text>
            <p><strong>Value:</strong>
              <CurrencyDisplay currency="mortal" :value="selectedMedicineObject.value" />
            </p>
          </ion-text>
          <div v-if="selectedMedicineObject.components && (
            (selectedMedicineObject.components.herbs && selectedMedicineObject.components.herbs.length > 0) ||
            (selectedMedicineObject.components.minerals && selectedMedicineObject.components.minerals.length > 0) ||
            (selectedMedicineObject.components.wood && selectedMedicineObject.components.wood.length > 0))">
            <ion-text>
              <h3>Components required:</h3>
            </ion-text>
            <ion-list>
              <ion-item v-for="component in [
                ...(selectedMedicineObject.components.herbs || []),
                ...(selectedMedicineObject.components.minerals || []),
                ...(selectedMedicineObject.components.wood || [])
              ]" :key="component.name">
                <ion-label>{{ capitalizeAllWords(component.name) }}: {{ component.quantity }}</ion-label>
              </ion-item>
            </ion-list>
          </div>
          <div v-if="missingResources.length > 0">
            <ion-text>
              <h3>Missing Resources:</h3>
            </ion-text>
            <ion-list>
              <ion-item v-for="resource in missingResources" :key="resource.name">
                <ion-label>{{ capitalizeAllWords(resource.name) }}: {{ resource.quantity }}</ion-label>
              </ion-item>
            </ion-list>
          </div>
          <QJButton v-if="missingResources.length === 0" @click="startRefiningProcess">Refine</QJButton>
          <QJButton @click="clearSelectedMedicine">Back to Medicine Book</QJButton>
        </ion-col>
      </ion-row>

      <ion-row v-if="isRefining">
        <ion-col size="12">
          <ion-text>
            <h3>Refining Process</h3>
          </ion-text>
          <div class="refining-stages">
            <ion-chip v-for="(stage, index) in refiningStages" :key="index" :class="['stage', stage.status]">
              {{ index + 1 }}
            </ion-chip>
          </div>
          <ReflexGame v-if="currentStage < 4" :difficulty="7" :skill="6" :startRunning="true"
            @game-finished="handleReflexScore" />
          <div v-if="currentStage === 4">
            <ion-text>
              <h3>Average Score: {{ averageScore }}</h3>
            </ion-text>
            <QJButton @click="finalizeRefiningProcess">Finalize Refining</QJButton>
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="end-message" v-if="showMessage">
        <ion-col size="12">
          <ion-text>
            <p>{{ endMessage }}</p>
          </ion-text>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import ReflexGame from '../minigames/ReflexGame.vue';
import inventoryWorker from '@/workers/inventoryWorker';
import characterWorker from '@/workers/characterWorker';
import helperFunctions from '../../../serverless-backend/server/helpers/helperFunctions.js';

import CurrencyDisplay from '../helpers/CurrencyDisplay.vue';
import QJButton from '../framework/QJButton.vue';

const recipeData = require('../../../serverless-backend/server/datasets/recipes.json');

export default {
  components: {
    ReflexGame,
    CurrencyDisplay,
    QJButton
  },
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    }
  },
  data() {
    return {
      selectedMedicine: '',
      selectedMedicineObject: {},
      medicineList: [],
      missingResources: [],
      isRefining: false,
      refiningStages: [
        { status: 'empty' },
        { status: 'empty' },
        { status: 'empty' },
        { status: 'empty' }
      ],
      currentStage: 0,
      scores: [],
      averageScore: 0,
      showMessage: false,
      endMessage: '',
      setRefresh: 0
    };
  },
  mounted() {
    this.initializeAlchemy();
  },
  methods: {
    async initializeAlchemy() {
      if (!this.currentCharacter.professions.alchemy) {
        let updatedCharacter = this.currentCharacter;
        updatedCharacter.professions.alchemy = {
          level: 1,
          xp: 0,
          medicines: []
        };
        await this.updateCharacter(updatedCharacter);
      }

      this.loadMedicinesForLevel();
      this.checkAllMedicines();
    },
    async updateCharacter(character) {
      await characterWorker.updateCharacter(this.$store, this.user, character);
    },
    loadMedicinesForLevel() {
      const alchemy = this.currentCharacter.professions.alchemy;
      const availableMedicines = recipeData.recipes.medicine.filter(medicine => medicine.requiredAlchemyLevel <= alchemy.level);

      if (!alchemy.medicines || alchemy.medicines.length === 0) {
        alchemy.medicines = availableMedicines.map(medicine => medicine.name);
        this.updateCharacter(this.currentCharacter);
      }

      this.medicineList = availableMedicines.filter(medicine => alchemy.medicines.includes(medicine.name));
    },
    handleMedicineClick(medicine) {
      this.selectedMedicine = medicine.name;
      this.selectedMedicineObject = medicine;
      this.checkMissingResources();
    },
    clearSelectedMedicine() {
      this.selectedMedicine = '';
      this.selectedMedicineObject = {};
      this.missingResources = [];
    },
    checkMissingResources() {
      this.missingResources = [];
      const allComponents = [
        ...(this.selectedMedicineObject.components.herbs || []),
        ...(this.selectedMedicineObject.components.minerals || []),
        ...(this.selectedMedicineObject.components.wood || [])
      ];
      allComponents.forEach(component => {
        const characterResource = this.currentCharacter.resources.find(resource => resource.material === component.name);
        if (!characterResource || characterResource.quantity < component.quantity) {
          const missingQuantity = characterResource ? component.quantity - characterResource.quantity : component.quantity;
          this.missingResources.push({ name: component.name, quantity: missingQuantity });
        }
      });
    },
    checkAllMedicines() {
      this.medicineList.forEach(medicine => {
        let hasAll = true;
        let hasSome = false;
        const allComponents = [
          ...(medicine.components.herbs || []),
          ...(medicine.components.minerals || []),
          ...(medicine.components.wood || [])
        ];
        allComponents.forEach(component => {
          const characterResource = this.currentCharacter.resources.find(resource => (resource.material).toLowerCase() === (component.name).toLowerCase());

          if (!characterResource || characterResource.quantity < component.quantity) {
            hasAll = false;
          }
          if (characterResource && characterResource.quantity > 0) {
            hasSome = true;
          }
        });
        if (hasAll) {
          medicine.status = 'all';
        } else if (hasSome) {
          medicine.status = 'some';
        } else {
          medicine.status = 'none';
        }
      });
    },
    getMedicineColor(medicine) {
      if (medicine.status === 'all') {
        return 'green';
      } else if (medicine.status === 'some') {
        return 'orange';
      } else {
        return 'red';
      }
    },
    startRefiningProcess() {
      this.isRefining = true;
      this.removeResourcesFromCharacter(this.selectedMedicineObject.components);
      this.currentStage = 0;
      this.scores = [];
      this.refiningStages = [
        { status: 'empty' },
        { status: 'empty' },
        { status: 'empty' },
        { status: 'empty' }
      ];
    },
    async removeResourcesFromCharacter(components) {
      if (components.herbs) {
        await inventoryWorker.removeFromResources(this.$store, this.$store.getters.getCurrentUser, this.currentCharacter, components.herbs);
      }
      if (components.minerals) {
        await inventoryWorker.removeFromResources(this.$store, this.$store.getters.getCurrentUser, this.currentCharacter, components.minerals);
      }
      if (components.wood) {
        await inventoryWorker.removeFromResources(this.$store, this.$store.getters.getCurrentUser, this.currentCharacter, components.wood);
      }
    },
    handleReflexScore(score) {
      this.scores.push(score);
      this.refiningStages[this.currentStage].status = this.getStageStatus(score);
      this.currentStage++;
      if (this.currentStage === 4) {
        this.calculateAverageScore();
      }
    },
    getStageStatus(score) {
      if (score === 100) {
        return 'green';
      } else if (score === 75) {
        return 'yellow';
      } else {
        return 'red';
      }
    },
    calculateAverageScore() {
      const totalScore = this.scores.reduce((acc, score) => acc + score, 0);
      this.averageScore = totalScore / this.scores.length;
    },
    async finalizeRefiningProcess() {
      const medicine = {
        name: this.selectedMedicineObject.name,
        effects: this.selectedMedicineObject.effects,
        description: this.selectedMedicineObject.description,
        value: this.selectedMedicineObject.value,
        quantity: 1
      };

      await inventoryWorker.addToConsumables(this.$store, this.user, this.currentCharacter, medicine);

      this.isRefining = false;
      this.clearSelectedMedicine();
      this.showMessage = true;
      this.displayEndMessage("Refining completed successfully!");
    },
    displayEndMessage(message) {
      this.endMessage = message;
      this.checkAllMedicines();
      setTimeout(() => {
        this.showMessage = false;
      }, 2000); // Display result for 2 seconds
    },
    capitalizeAllWords(string) {
      return helperFunctions.capitalizeAllWords(string);
    }
  }
};
</script>

<style scoped>
.medicine-list {
  list-style-type: none;
  padding: 0;
}

.medicine-list .ion-item {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.medicine-list .ion-item:hover {
  background-color: #f0f0f0;
}

.medicine-list .ion-item.selected {
  background-color: #e0e0e0;
  border-color: #ccc;
}

.end-message {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(128, 128, 128, 0.9);
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  font-size: 20px;
  color: white;
}

.refining-stages {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.qj-button {
  margin-bottom: 5px;
}

.stage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stage.empty {
  background-color: #fff;
}

.stage.red {
  background-color: #f00;
}

.stage.yellow {
  background-color: #ff0;
}

.stage.green {
  background-color: #0f0;
}
</style>
