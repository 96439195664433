<template>
  <div class="ion-padding journal">
    <div class="journal-header">
      <h2>Journal</h2>
      <ion-button @click="closeJournal">Close</ion-button>
    </div>
    <div class="quest-board">
      <div v-for="(quest, index) in currentCharacter.quests" :key="index" class="quest-tile"
        @click="selectQuest(quest)">
        <h3>{{ quest.name }}</h3>
      </div>
    </div>
    <div v-if="selectedQuest" class="quest-details">
      <h3>{{ selectedQuest.name }}</h3>
      <p>{{ selectedQuest.text }}</p>
      <ion-list>
        <ion-item v-for="(objective, objIndex) in selectedQuest.objectives" :key="objIndex">
          <ion-checkbox slot="start" v-model="objective.completed" disabled></ion-checkbox>
          <ion-label>
            {{ objective.description }} ({{ objective.progress }}/{{ objective.required }})
          </ion-label>
        </ion-item>
      </ion-list>
      <div class="rewards">
        <p>XP: {{ selectedQuest.rewards.qi }}</p>
        <p>Mortal Currency: <CurrencyDisplay :value="selectedQuest.rewards.mortalCurrency" currency="mortal" /></p>
      </div>
      <ion-button v-if="isQuestComplete(selectedQuest)" @click="turnInQuest">Turn In Quest</ion-button>
      <ion-button v-else @click="giveUpQuest">Give Up Quest</ion-button>
    </div>
  </div>
</template>

<script>
import characterWorker from '../../workers/characterWorker.js';
import CurrencyDisplay from '../helpers/CurrencyDisplay.vue';

export default {
  name: "CharacterJournal",
  components: {
    CurrencyDisplay
  },
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    }
  },
  data() {
    return {
      selectedQuest: null
    };
  },
  methods: {
    closeJournal() {
      this.$emit('closeJournal');
    },
    selectQuest(quest) {
      this.selectedQuest = quest;
    },
    isQuestComplete(quest) {
      return quest.objectives.every(obj => obj.completed);
    },
    async turnInQuest() {
      console.log("complete");
      let updatedCharacter = this.currentCharacter;
      console.log("QUEST:", this.selectedQuest)
      updatedCharacter.qiTotal += this.selectedQuest.rewards.qi;
      updatedCharacter.mortalCurrency += this.selectedQuest.rewards.mortalCurrency;
      console.log("Quest Turn In:", updatedCharacter);
      updatedCharacter.quests = this.currentCharacter.quests.filter(q => q !== this.selectedQuest);
      await characterWorker.updateCharacter(this.$store, this.user, updatedCharacter);
      this.selectedQuest = null;
    },
    async giveUpQuest() {
      // Update character quests locally first
      this.character.quests = this.character.quests.filter(q => q !== this.selectedQuest);
      await characterWorker.updateCharacter(this.$store, this.user, this.character);
      this.selectedQuest = null;
    }
  },
  watch: {
    currentCharacter: {
      handler(newCharacter) {
        this.character = newCharacter;
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.journal {
  position: fixed;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  background: white;
  border: 1px solid #ccc;
  padding: 20px;
  overflow-y: auto;
}

.journal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quest-board {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
}

.quest-tile {
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
}

.quest-tile:hover {
  background-color: #f0f0f0;
}

.quest-details {
  border: 1px solid #ccc;
  padding: 20px;
  margin-top: 20px;
}

.rewards {
  margin-top: 10px;
}
</style>