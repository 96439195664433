import generationWorker from "./generationWorker";
import resourceData from "../../serverless-backend/server/datasets/resources.json";
import livestockData from "../../serverless-backend/server/datasets/livestock.json"; // Assuming you have a dataset for livestock

const buildSpecificShop = async function(user, character, type) {
    const shop = await generationWorker.generateRandomShop(user, character);
    const stock = await stockTheShelves(user, character, shop, type);

    let shopData = {
        name: shop.shopName,
        shopKeeper: shop.shopkeeperName,
        type: type,
        stock: stock,
    }
    return shopData;
}

const stockTheShelves = async function(user, character, shop, type) {
    console.log('Stocking the shelves');
    console.log('Shop:', shop);
    let stockAmount = shop.stockVolume;
    let stock = [];

    if (type === 'weapons') {
        console.log('Generating weapon set');
        stock = await generationWorker.generateWeaponSet(user, character, character.level, stockAmount);
    }
    else if (type === 'armor') {
        stock = await generationWorker.generateArmorSet(user, character, character.level, stockAmount);
    }

    return stock;
}

const buildGroceryShop = async function() {
    const cookingItems = resourceData.resources.cooking_items;
    console.log('Cooking items:', cookingItems);
    let shopData = {
        name: "Grocery Shop",
        shopKeeper: "Grocery Keeper",
        type: "grocery",
        stock: cookingItems.map(item => ({
            name: item.name,
            value: item.value,
            quantity: 1 // Initialize quantity to 1
        })),
    }
    return shopData;
}

const buildLivestockShop = async function() {
    const livestockItems = livestockData.livestock;
    let shopData = {
        name: "Livestock Shop",
        shopKeeper: "Old MacDonald",
        type: "livestock",
        stock: livestockItems.map(item => ({
            name: item.name,
            value: item.value,
            mature: item.mature,
            timeToMature: item.timeToMature,
            sellValue: item.sellValue,
            purchasePrice: item.purchasePrice
        })),
    }
    return shopData;
}

export default {
    buildSpecificShop,
    buildGroceryShop,
    buildLivestockShop
}
