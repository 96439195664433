<template>
  <ion-grid class="button-stack-set" style="padding: 0 !important;">
    <ion-row v-show="!isCollapsed || !expandable" :class="{
      'horizontal-stack': horizontal,
      'vertical-stack': !horizontal,
      expanded: !isCollapsed,
    }" @click="handleButtonClick" style="margin-top: 0 !important; margin-bottom: 0 !important;">
      <slot></slot>
    </ion-row>
    <ion-row v-if="expandable" style="margin-top: 0 !important; margin-bottom: 0 !important;">
      <ion-col size="12" class="ion-text-center">
        <button expand="block" height="25px" class="expand-button" @click="toggleCollapse">
          |||
        </button>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>

export default {
  name: 'ButtonStack',
  props: {
    horizontal: {
      type: Boolean,
      default: false,
    },
    expandable: {
      type: Boolean,
      default: true,
    },
    startOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCollapsed: !this.startOpen,
    };
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    handleButtonClick() {
      if (this.expandable) {
        this.isCollapsed = true;
      }
    },
  },
};
</script>

<style scoped>
.button-stack-set {
  width: 100%;
  min-height: 0px !important;
  box-sizing: border-box;
  overflow: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.button-stack-set::-webkit-scrollbar {
  display: none;
}

.vertical-stack {
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.vertical-stack>.ion-col {
  width: 100%;
  margin-bottom: 5px;
}

.horizontal-stack {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  max-height: 0;
  transition: max-height 0.3s ease;
  overflow: hidden;
}

.horizontal-stack.expanded {
  max-height: none;
}

.horizontal-stack>.ion-col {
  width: 100%;
  margin-bottom: 5px;
}

.expand-button {
  background-color: #8B4513 !important;
  color: white;
  height: 35px;
  width: 100%;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.icon-white {
  color: white;
}

@media (min-width: 768px) {
  .button-stack-set {
    margin: 0 auto;
  }

  .vertical-stack>.ion-col {
    width: 100%;
    margin-bottom: 5px;
  }

  .horizontal-stack {
    max-height: 0;
  }

  .horizontal-stack.expanded {
    max-height: none;
  }

  .horizontal-stack>.ion-col {
    width: 100%;
    margin-bottom: 5px;
  }
}
</style>
