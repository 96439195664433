<template>
  <div>
    <div v-if="!showMessage">
      <ion-text>
        <h2>Level-Up Tribulation</h2>
        <h4>Score: {{ score }}</h4>
      </ion-text>
      <div class="stage-tracker">
        <div v-for="stageIndex in 5" :key="stageIndex" class="stage-circle" :class="{ active: stageIndex <= stage }">
          {{ stageIndex }}
        </div>
      </div>
      <MemoryGame v-if="stage === 1" @game-finished="handleMemoryGameFinished" />
      <div v-else-if="stage > 1 && stage < 5">
        <ReflexGame v-if="currentRound <= 4" :startRunning="true" :difficulty="7" :skill="7"
          @game-finished="handleReflexGameFinished" :key="currentRound" />
        <ion-text v-else>
          <h3>Calculating Results...</h3>
        </ion-text>
      </div>
      <div v-else-if="stage === 5">
        <ion-text>
          <h3>Results</h3>
          <p v-if="passed">Congratulations! You passed the tribulation.</p>
          <p v-else>Sorry, you failed the tribulation. Try again.</p>
        </ion-text>
        <ion-button expand="block" @click="resetGame">Retry</ion-button>
      </div>
    </div>
    <div v-if="showMessage">
      <ion-text>
        <p>{{ message }}</p>
      </ion-text>
    </div>
  </div>
</template>

<script>
import MemoryGame from './MemoryGame.vue';
import ReflexGame from './ReflexGame.vue';

import characterWorker from '../../workers/characterWorker.js';

export default {
  components: {
    MemoryGame,
    ReflexGame,
  },
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    }
  },
  data() {
    return {
      stage: 1,
      score: 0,
      currentRound: 1,
      passed: false,
      showMessage: false,
      message: '',
    };
  },
  methods: {
    handleMemoryGameFinished(score) {
      this.score += score;
      this.stage = 2;
    },
    handleReflexGameFinished(score) {
      this.score += score;
      if (this.currentRound === 4) {
        this.calculateResults();
      } else {
        this.currentRound += 1;
      }
    },
    calculateResults() {
      const overallScore = this.score / 4;
      this.passed = overallScore >= 90;
      this.stage = 5;
      console.log("Overall score: ", overallScore);
      console.log(this.passed ? 'You passed the tribulation!' : 'You failed the tribulation.');
      if (this.passed) {
        const character = characterWorker.levelUpCharacter(this.$store, this.user, this.currentCharacter, true);
        this.message = `Congratulations! You passed the tribulation. You have advanced to the ${character.realm} realm.`;
        this.showMessage = true;
      }
      else {
        characterWorker.levelDownCharacter(this.$store, this.user, this.currentCharacter, 1);
        this.message = "You have failed the tribulation. You suffer a setback in your cultivation.";
        this.showMessage = true;
      }
    },
    resetGame() {
      this.stage = 1;
      this.score = 0;
      this.currentRound = 1;
      this.passed = false;
    },
  },
};
</script>

<style scoped>
.stage-tracker {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.stage-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  font-size: 16px;
}

.stage-circle.active {
  background-color: green;
  color: white;
}
</style>
