<template>
    <div>
        <ion-card class="display-card">
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <div class="button-stack narrow-stack75">
                            <ion-button expand="block" @click="gatherBasicResource('common')">First</ion-button>
                            <ion-button expand="block" @click="gatherBasicResource('uncommon')">Second</ion-button>
                            <ion-button expand="block" @click="gatherBasicResource('rare')">Third</ion-button>
                        </div>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-text>
                            <h3>Resource Gathered:</h3>
                            <p>{{ gatheredResource }}</p>
                        </ion-text>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-card>
    </div>
</template>

<script>
import helperFunctions from '../../../serverless-backend/server/helpers/helperFunctions.js'
import resourceWorker from '@/workers/resourceWorker';

export default {
    data() {
        return {
            gatheredResource: ''
        };
    },
    computed: {
        currentCharacter() {
            return this.$store.getters.getCurrentCharacter;
        },
        user() {
            return this.$store.getters.getCurrentUser;
        }
    },
    methods: {
        async gatherBasicResource(location) {
            const gatheredResource = await resourceWorker.generateRandomBaseResource(this.user, this.currentCharacter, location);
            this.gatheredResource = helperFunctions.capitalizeFirstCharacter(gatheredResource);
        }
    }
};
</script>

<style scoped>
/* Add your component-specific styles here */
</style>