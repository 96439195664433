<template>
  <div class="hunting-game">
    <p>Tap the target before time runs out.</p>
    <ion-text class="timer">{{ timeLeft.toFixed(2) }}s</ion-text>
    <div class="hunting-field" ref="huntingField">
      <div v-if="targetVisible" class="target" :style="{ top: targetPosition.y + 'px', left: targetPosition.x + 'px' }"
        @click="handleClick"></div>
    </div>
    <ion-text v-if="showEndMessage" class="end-message">
      <p>{{ endMessage }}</p>
    </ion-text>
  </div>
</template>

<script>
import helperFunctions from '../../../serverless-backend/server/helpers/helperFunctions';
import resourcesData from '../../../serverless-backend/server/datasets/resources.json';
import inventoryWorker from '../../workers/inventoryWorker.js';

export default {
  data() {
    return {
      meat: {},
      parts: {},
      hides: {},
      timeLeft: 5.00,
      targetPosition: { x: 0, y: 0 },
      gameOver: false,
      success: false,
      timer: null,
      targetVisible: true,
      endMessage: '',
      showEndMessage: false,
    };
  },
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    }
  },
  methods: {
    startGame() {
      const validMeats = [
        "beef steak", "chicken breast", "pork loin", "lamb chop", "venison steak",
        "rabbit meat", "duck breast", "goose liver", "buffalo meat", "elk meat"
      ];
      const meats = resourcesData.resources.meats.filter(meat => validMeats.includes(meat.name));
      this.meat = meats[Math.floor(Math.random() * meats.length)];
      let meatObject = this.meat;
      this.meat = { name: meatObject.name, quantity: 2, value: meatObject.value };
      if (["chicken breast", "duck breast", "goose liver"].includes(this.meat.name)) {
        this.parts = { name: "feather", quantity: 5, value: 20 };
        this.hides = { name: "Thin Hide", quantity: 1, value: 100 };
      } else {
        this.parts = { name: "bone", quantity: 5, value: 15 };
        this.hides = { name: "Thick Hide", quantity: 2, value: 50 };
      }
      this.moveTarget();
      this.startTimer();
    },
    moveTarget() {
      const huntingField = this.$refs.huntingField;
      const maxX = huntingField.clientWidth - 50; // 50 is the width of the target
      const maxY = huntingField.clientHeight - 50; // 50 is the height of the target

      this.targetPosition = {
        x: Math.random() * maxX,
        y: Math.random() * maxY,
      };

      this.targetVisible = true;

      // Move target every second
      setTimeout(() => {
        if (!this.gameOver) {
          this.targetVisible = false;
          this.moveTarget();
        }
      }, 750);
    },
    startTimer() {
      this.timeLeft = 5.00;
      this.timer = setInterval(() => {
        this.timeLeft -= 0.01;
        if (this.timeLeft <= 0) {
          this.endGame(false);
        }
      }, 10);
    },
    handleClick() {
      this.endGame(true);
    },
    async endGame(success) {
      console.log("Meat: ", this.meat);
      console.log("Parts: ", this.parts);
      clearInterval(this.timer);
      this.gameOver = true;
      this.success = success;
      let resourcesGathered = [
        this.meat, this.parts, this.hides
      ]
      await inventoryWorker.addResourcesToInventory(this.$store, this.user, this.currentCharacter, resourcesGathered);
      this.displayEndMessage(success ? `You procure ${this.capitalizeAllWords(this.meat.name)}!` : 'You failed to hunt the target.');
    },
    displayEndMessage(message) {
      this.endMessage = message;
      this.showEndMessage = true;
      setTimeout(() => {
        this.showEndMessage = false;
        this.$emit('close');
      }, 1500); // Display result for 2 seconds before closing
    },
    resetGame() {
      this.gameOver = false;
      this.success = false;
      this.startGame();
    },
    capitalizeAllWords(string) {
      return helperFunctions.capitalizeAllWords(string);
    },
  },
  mounted() {
    this.startGame();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.hunting-game {
  text-align: center;
}

.hunting-field {
  position: relative;
  width: 500px;
  height: 500px;
  border: 2px solid black;
  border-radius: 10px;
  margin-top: 20px;
  background-color: #8B4513;
}

@media screen and (max-width: 600px) {
  .hunting-field {
    width: 300px;
    height: 300px;
  }

}

.target {
  width: 50px;
  height: 50px;
  background-color: red;
  position: absolute;
  border: solid black 2px;
  border-radius: 50%;
  cursor: pointer;
}

.timer {
  font-size: 24px;
  color: red;
}

.result {
  margin-top: 20px;
}

.end-message {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(128, 128, 128, 0.9);
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  font-size: 20px;
  color: white;
}
</style>