<template>
    <ion-text>
        <ion-text v-if="prefix">{{ prefix }}</ion-text>
        <ion-text v-if="formattedValues">
            <ion-text v-if="currency === 'mortal'">
                <ion-text v-if="formattedValues.platinum">{{ formattedValues.platinum }} Platinum</ion-text>
                <ion-text v-if="formattedValues.gold"> {{ formattedValues.gold }} Gold </ion-text>
                <ion-text v-if="formattedValues.silver">{{ formattedValues.silver }} Silver </ion-text>
                <ion-text v-if="formattedValues.copper != 0">{{ formattedValues.copper }} Copper </ion-text>
            </ion-text>
            <ion-text v-if="currency === 'immortal'">
                <ion-text v-if="formattedValues.dim">{{ formattedValues.dim }} Dim {{ singularize("Cores",
            formattedValues.dim) }}</ion-text>
                <ion-text v-if="formattedValues.bright">{{ formattedValues.bright }} Bright {{ singularize("Cores",
            formattedValues.bright) }}</ion-text>
                <ion-text v-if="formattedValues.radiant">{{ formattedValues.radiant }} Radiant {{ singularize("Cores",
            formattedValues.radiant) }}</ion-text>
                <ion-text v-if="formattedValues.immortal">{{ formattedValues.immortal }} Immortal {{
            singularize("Cores", formattedValues.immortal) }}</ion-text>
                <ion-text v-if="formattedValues.divine">{{ formattedValues.divine }} Divine {{ singularize("Cores",
            formattedValues.divine) }}</ion-text>
                <ion-text v-if="formattedValues.celestial">{{ formattedValues.celestial }} Celestial {{
            singularize("Cores", formattedValues.celestial) }}</ion-text>
                <ion-text v-if="formattedValues.eternal">{{ formattedValues.eternal }} Eternal {{ singularize("Cores",
                    formattedValues.eternal) }}</ion-text>
            </ion-text>
        </ion-text>
    </ion-text>
</template>

<script>
import helperFunctions from '../../../serverless-backend/server/helpers/helperFunctions.js';

export default {
    props: {
        value: {
            type: Number,
            required: true
        },
        prefix: {
            type: String,
            default: ''
        },
        currency: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            formattedValues: undefined
        };
    },
    mounted() {
        this.formattedValues = this.formatCurrency(this.value);
    },
    methods: {
        formatCurrency(value) {
            if (this.currency === 'immortal') {
                return helperFunctions.calculateImmortalCurrency(value);
            }
            else {
                return helperFunctions.calculateMortalCurrency(value);
            }
        },
        singularize(string, number) {
            return helperFunctions.singularize(string, number);
        }
    },
};
</script>