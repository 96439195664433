<template>
    <div>
        <ion-grid>
            <ion-row>
                <ion-col size="12">
                    <ButtonStack :horizontal="false" :expandable="true" :startOpen="true">
                        <QJButton @click="showAlchemyLab">Alchemy Lab</QJButton>
                        <QJButton @click="showBlacksmithForge">Blacksmith Forge</QJButton>
                        <QJButton @click="showKitchen">Kitchen</QJButton>
                    </ButtonStack>
                </ion-col>
            </ion-row>

            <div v-if="currentView">
                <AlchemyLab v-if="currentView === 'alchemyLab'" />
                <BlacksmithForge v-if="currentView === 'blacksmithForge'" />
                <Kitchen v-if="currentView === 'kitchen'" />
            </div>
        </ion-grid>
    </div>
</template>

<script>
import ButtonStack from '../framework/ButtonStack.vue';
import QJButton from '../framework/QJButton.vue';
import AlchemyLab from '../professions/AlchemyLab.vue';
import BlacksmithForge from '../professions/BlacksmithForge.vue';
import Kitchen from '../professions/Kitchen.vue';

export default {
    components: {
        ButtonStack,
        QJButton,
        AlchemyLab,
        BlacksmithForge,
        Kitchen,
    },
    data() {
        return {
            currentView: null,
        };
    },
    methods: {
        showAlchemyLab() {
            this.currentView = 'alchemyLab';
            console.log('currentView set to', this.currentView);
        },
        showBlacksmithForge() {
            this.currentView = 'blacksmithForge';
            console.log('currentView set to', this.currentView);
        },
        showKitchen() {
            this.currentView = 'kitchen';
            console.log('currentView set to', this.currentView);
        },
    },
};
</script>

<style scoped>
.display-card {
    padding: 20px;
    margin-top: 20px;
}
</style>