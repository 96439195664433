<template>
    <div class="game-container">
        <QJButton @click="handleClose" class="close-button">Leave the Shop</QJButton>
        <ShopComponent :shopType="shopType"/>
    </div>
</template>


<script>
import QJButton from '../framework/QJButton.vue';
import ShopComponent from '../shops/ShopComponent.vue';

export default {
    components: {
        ShopComponent,
        QJButton
    },
    props: {
        shopType: {
            type: String,
            required: true
        }
    },
    methods: {
        handleClose() {
            setTimeout(() => {
                console.log('Closing combat scene');
                this.$emit('close');
            }, 500); // Display result for 2 seconds before closing
        }
    }
};
</script>