<template>
  <div>
    <ion-card class="shop-test">
      <ion-grid>
        <ion-row>
          <ButtonStack>
            <QJButton expand="full" @click="buildShop('weapons')">Generate Weapon Shop</QJButton>
            <QJButton expand="full" @click="buildShop('armor')">Generate Armor Shop</QJButton>
            <QJButton expand="full" @click="buildGroceryStore">Generate Grocery Shop</QJButton>
            <QJButton expand="full" @click="buildLivestockStore">Generate Livestock Shop</QJButton>
            <QJButton expand="full" @click="buildLibraryShop">Generate Library Shop</QJButton>
          </ButtonStack>
        </ion-row>
      </ion-grid>

      <div class="currency-display">
        <p>Current Funds:</p>
        <CurrencyDisplay currency="mortal" :value="currentCharacter.mortalCurrency" />
        <CurrencyDisplay currency="immortal" :value="currentCharacter.immortalCurrency" />
      </div>

      <ion-grid>
        <ion-row v-if="shop">
          <ion-col size="12" class="shop-display">
            <ion-card>
              <ion-card-header>
                <ion-card-title>{{ shop.name }}</ion-card-title>
                <ion-card-subtitle>{{ shop.shopKeeper }}</ion-card-subtitle>
              </ion-card-header>

              <ion-card-content>
                <ion-list v-if="shop.type !== 'library'">
                  <ion-item v-for="ware in shop.stock" :key="ware.name">
                    <ion-label class="text-center">
                      <img v-fallback-img :src="ware.image" class="equipment-image" alt="Equipment Image" />
                      <h2 :style="{ color: getItemColor(ware) }">{{ capitalizeAllWords(ware.name) }}</h2>
                      <p v-if="shop.type === 'weapons'">Damage: {{ ware.damage }}</p>
                      <p v-if="shop.type === 'armor'">Armor: {{ ware.armorValue }}</p>
                      <p v-if="shop.type === 'livestock'">Purchase Price:
                        <CurrencyDisplay currency="mortal" :value="ware.purchasePrice" />
                      </p>
                      <p v-else>
                        <CurrencyDisplay currency="mortal"
                          :value="shop.type === 'grocery' ? ware.value * ware.quantity : ware.value" />
                      </p>
                      <div v-if="shop.type === 'grocery'">
                        <button @click="decreaseQuantity(ware)">-</button>
                        <button @click="increaseQuantity(ware)">+</button>
                        <p>Quantity: {{ ware.quantity }}</p>
                      </div>
                      <QJButton class="item-action-button" @click="buyItem(ware)">Buy</QJButton>
                    </ion-label>
                  </ion-item>
                </ion-list>

                <div v-else>
                  <h4>Martial Offensive</h4>
                  <ion-list>
                    <ion-item v-for="ware in shop.stock.martialOffensive" :key="ware.name">
                      <ion-label>
                        <h2>{{ capitalizeAllWords(ware?.name || 'Unknown Skill') }}</h2>
                        <p>Damage: {{ ware.effect.damage }}</p>
                        <p>Price:
                          <CurrencyDisplay currency="immortal" :value="ware.value" />
                        </p>
                      </ion-label>
                      <QJButton class="item-action-button" @click="buyItem(ware)">Buy</QJButton>
                    </ion-item>
                  </ion-list>
                  <h4>Martial Defensive</h4>
                  <ion-list>
                    <ion-item v-for="ware in shop.stock.martialDefensive" :key="ware.name">
                      <ion-label>
                        <h2>{{ capitalizeAllWords(ware?.name || 'Unknown Skill') }}</h2>
                        <p>Price:
                          <CurrencyDisplay currency="immortal" :value="ware.value" />
                        </p>
                      </ion-label>
                      <QJButton class="item-action-button" @click="buyItem(ware)">Buy</QJButton>
                    </ion-item>
                  </ion-list>
                  <h4>Qi Offensive</h4>
                  <ion-list>
                    <ion-item v-for="ware in shop.stock.qiOffensive" :key="ware.name">
                      <ion-label>
                        <h2>{{ capitalizeAllWords(ware?.name || 'Unknown Skill') }}</h2>
                        <p>Damage: {{ ware.effect.damage }}</p>
                        <p>Price:
                          <CurrencyDisplay currency="immortal" :value="ware.value" />
                        </p>
                      </ion-label>
                      <QJButton class="item-action-button" @click="buyItem(ware)">Buy</QJButton>
                    </ion-item>
                  </ion-list>
                  <h4>Qi Defensive</h4>
                  <ion-list>
                    <ion-item v-for="ware in shop.stock.qiDefensive" :key="ware.name">
                      <ion-label>
                        <h2>{{ capitalizeAllWords(ware?.name || 'Unknown Skill') }}</h2>
                        <p>Price:
                          <CurrencyDisplay currency="immortal" :value="ware.value" />
                        </p>
                      </ion-label>
                      <QJButton class="item-action-button" @click="buyItem(ware)">Buy</QJButton>
                    </ion-item>
                  </ion-list>
                </div>
              </ion-card-content>
            </ion-card>
          </ion-col>

          <ion-col size="12" v-if="playerResources.length > 0 && shop && shop.type === 'grocery'">
            <ion-card>
              <ion-card-header>
                <ion-card-title>Character Resources</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <ion-list>
                  <ion-item v-for="resource in playerResources" :key="resource.material">
                    <ion-label>
                      <h2>{{ resource.material }}</h2>
                      <p>Quantity: {{ resource.quantity }}</p>
                      <p>Value:
                        <CurrencyDisplay currency="mortal" :value="resource.value * resource.sellQuantity" />
                      </p>
                      <div>
                        <button @click="setSellQuantity(resource, 0)">&lt;</button>
                        <button @click="decreaseSellQuantity(resource)">-</button>
                        <button @click="increaseSellQuantity(resource)">+</button>
                        <button @click="setSellQuantity(resource, resource.quantity)">&gt;</button>
                        <p>Quantity: {{ resource.sellQuantity }}</p>
                      </div>
                    </ion-label>
                    <QJButton class="item-action-button" @click="sellResource(resource)">Sell</QJButton>
                  </ion-item>
                </ion-list>
              </ion-card-content>
            </ion-card>
          </ion-col>

          <ion-col size="12"
            v-if="currentCharacter.inventory.length > 0 && shop && (shop.type === 'weapons' || shop.type === 'armor')">
            <ion-card>
              <ion-card-header>
                <ion-card-title>Character Inventory</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <ion-list>
                  <ion-item v-for="item in currentCharacter.inventory" :key="item.id">
                    <ion-label>
                      <h2 :style="{ color: getItemColor(item) }">{{ capitalizeAllWords(item.name) }}</h2>
                      <p>Sell for
                        <CurrencyDisplay currency="mortal" :value="item.value" />
                      </p>
                    </ion-label>
                    <QJButton class="item-action-button" @click="sellItem(item)">Sell</QJButton>
                  </ion-item>
                </ion-list>
              </ion-card-content>
            </ion-card>
          </ion-col>

          <ion-col size="12" v-if="currentCharacter.livestock.length > 0 && shop && shop.type === 'livestock'">
            <ion-card>
              <ion-card-header>
                <ion-card-title>Character Livestock</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <ion-list>
                  <ion-item v-for="livestock in currentCharacter.livestock" :key="livestock.name">
                    <ion-label>
                      <h2>{{ capitalizeAllWords(livestock.name) }}</h2>
                      <p>Sell for
                        <CurrencyDisplay currency="mortal"
                          :value="livestock.mature ? livestock.sellValue : livestock.purchasePrice" />
                      </p>
                    </ion-label>
                    <QJButton class="item-action-button" @click="sellLivestock(livestock)">Sell</QJButton>
                  </ion-item>
                </ion-list>
              </ion-card-content>
            </ion-card>
          </ion-col>

          <ion-col size="12" v-if="currentCharacter.skillBooks.length > 0 && shop && shop.type === 'library'">
            <ion-card>
              <ion-card-header>
                <ion-card-title>Character Skill Books</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <ion-list>
                  <ion-item v-for="skillBook in currentCharacter.skillBooks" :key="skillBook.name">
                    <ion-label>
                      <h2>{{ skillBook.name }}</h2>
                      <p>Sell for
                        <CurrencyDisplay currency="immortal" :value="skillBook.value" />
                      </p>
                    </ion-label>
                    <QJButton class="item-action-button" @click="sellSkillBook(skillBook)">Sell</QJButton>
                  </ion-item>
                </ion-list>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>
    <Spinner :isLoading="isLoading" />
  </div>
</template>


<script>
import skillWorker from "../../workers/skillWorker.js";
import shopWorker from "../../workers/shopWorker.js";
import inventoryWorker from "../../workers/inventoryWorker.js";
import characterWorker from "../../workers/characterWorker.js";
import helperFunctions from '../../../serverless-backend/server/helpers/helperFunctions.js';
import cultivationInformation from '../../../serverless-backend/server/datasets/cultivationInformation.json';

import CurrencyDisplay from "../helpers/CurrencyDisplay.vue";
import ButtonStack from '../framework/ButtonStack.vue';
import QJButton from "../framework/QJButton.vue";
import Spinner from "../framework/Spinner.vue";

export default {
  components: {
    CurrencyDisplay,
    ButtonStack,
    QJButton,
    Spinner
  },
  data() {
    return {
      shop: undefined,
      defaultColor: "black",
      playerResources: [],
      realms: cultivationInformation.realms,
      types: [],
      isLoading: false
    };
  },
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    }
  },
  methods: {
    async buildShop(type) {
      this.isLoading = true;
      try {
        this.shop = undefined;
        const shop = await shopWorker.buildSpecificShop(this.user, this.currentCharacter, type);
        if (shop) {
          console.log("Got shop: ", shop);
          this.isLoading = false;
          this.shop = shop;
        } else {
          console.error("Failed to generate shop: ", type);
        }
      } catch (error) {
        console.error("Error building shop: ", error);
      }
    },
    async buildGroceryStore() {
      this.isLoading = true;
      try {
        this.shop = undefined;
        const shop = await shopWorker.buildGroceryShop();
        if (shop) {
          console.log("Got grocery shop: ", shop);
          this.isLoading = false;
          this.shop = shop;
          this.shop.stock = this.initializeGroceryStock(this.shop.stock);
          this.buildPlayerResourceInventory();
        } else {
          console.error("Failed to generate grocery shop");
        }
      } catch (error) {
        console.error("Error building grocery shop: ", error);
      }
    },
    async buildLivestockStore() {
      this.isLoading = true;
      try {
        this.shop = undefined;
        const shop = await shopWorker.buildLivestockShop();
        if (shop) {
          console.log("Got livestock shop: ", shop);
          this.isLoading = false;
          this.shop = shop;
        } else {
          console.error("Failed to generate livestock shop");
        }
      } catch (error) {
        console.error("Error building livestock shop: ", error);
      }
    },
    async buildLibraryShop() {
      this.isLoading = true;
      try {
        this.shop = undefined;
        const realm = this.getCurrentRealm();
        const skills = await this.generateLibrarySkills(realm);
        const shop = {
          name: "Library Shop",
          shopKeeper: "Sage Li",
          type: "library",
          stock: skills
        };
        console.log("Got library shop: ", shop);
        this.isLoading = false;
        this.shop = shop;
      } catch (error) {
        console.error("Error building library shop: ", error);
      }
    },
    initializeGroceryStock(stock) {
      return stock.map(item => ({ ...item, quantity: 1 }));
    },
    async generateLibrarySkills(realm) {
      const level = this.currentCharacter.level;
      const martialOffensive = await this.generateSkills('martial_offensive_skills', realm, level, 5);
      const martialDefensive = await this.generateSkills('martial_defensive_skills', realm, level, 5);
      const qiOffensive = await this.generateSkills('qi_offensive_skills', realm, level, 5);
      const qiDefensive = await this.generateSkills('qi_defensive_skills', realm, level, 5);
      return {
        martialOffensive,
        martialDefensive,
        qiOffensive,
        qiDefensive
      };
    },
    async generateSkills(type, realm, level, count) {
      const skills = [];
      const skillNames = new Set();
      for (let i = 0; i < count; i++) {
        try {
          const skill = await skillWorker.generateSkillByRealmAndType(this.user, realm, type, level);
          if (skill && skill.name && !skillNames.has(skill.name)) {
            skills.push(skill);
            skillNames.add(skill.name);
          } else if (skillNames.has(skill.name)) {
            i--; // Ensure we try again for a unique skill
          } else {
            console.error(`Generated skill is invalid:`, skill);
          }
        } catch (error) {
          console.error(`Error generating skill of type ${type} for realm ${realm}:`, error);
        }
      }
      return skills;
    },
    getCurrentRealm() {
      return this.currentCharacter.realm;
    },
    setQuantity(ware, quantity) {
      ware.quantity = quantity;
      this.updateShopDisplay();
    },
    setSellQuantity(resource, quantity) {
      resource.sellQuantity = quantity;
      this.updateResourceDisplay();
    },
    increaseQuantity(ware) {
      ware.quantity++;
      this.updateShopDisplay();
    },
    decreaseQuantity(ware) {
      if (ware.quantity > 1) {
        ware.quantity--;
        this.updateShopDisplay();
      }
    },
    async buyItem(ware) {
      if (this.shop.type === 'library') {
        const coreAmount = ware.value;
        const playerCoreAmount = this.currentCharacter.immortalCurrency;
        if (playerCoreAmount >= coreAmount) {
          await inventoryWorker.addSkillBookToInventory(this.$store, this.user, this.currentCharacter, ware);
          await characterWorker.modifyCharacterImmortalCurrency(this.$store, this.user, this.currentCharacter, -coreAmount);
          this.removeItem(ware);
        } else {
          console.log(`Not enough cores!`);
        }
      } else {
        const totalCost = this.shop.type === 'grocery' ? ware.value * ware.quantity : ware.purchasePrice || ware.value;
        if (this.currentCharacter.mortalCurrency >= totalCost) {
          if (this.shop.type === 'grocery') {
            await inventoryWorker.addResourcesToInventory(this.$store, this.user, this.currentCharacter, { name: ware.name, quantity: ware.quantity, value: ware.value });
          } else if (this.shop.type === 'livestock') {
            await inventoryWorker.addLivestockToInventory(this.$store, this.user, this.currentCharacter, ware);
          } else {
            await inventoryWorker.addToInventory(this.$store, this.user, this.currentCharacter, ware);
          }
          await characterWorker.modifyCharacterMortalCurrency(this.$store, this.user, this.currentCharacter, -totalCost);
          if (this.shop.type === 'weapons' || this.shop.type === 'armor' || this.shop.type === 'library') {
            this.removeItem(ware);
          }
          this.buildPlayerResourceInventory();
        } else {
          console.log("Not enough mortalCurrency");
        }
      }
    },
    async sellResource(resource) {
      const sellValue = resource.value * resource.sellQuantity;
      await inventoryWorker.removeFromResources(this.$store, this.user, this.currentCharacter, { name: resource.material, quantity: resource.sellQuantity });
      await characterWorker.modifyCharacterMortalCurrency(this.$store, this.user, this.currentCharacter, sellValue);
      this.buildPlayerResourceInventory();
    },
    increaseSellQuantity(resource) {
      if (resource.sellQuantity < resource.quantity) {
        resource.sellQuantity++;
        this.updateResourceDisplay();
      }
    },
    decreaseSellQuantity(resource) {
      if (resource.sellQuantity > 1) {
        resource.sellQuantity--;
        this.updateResourceDisplay();
      }
    },
    async sellSkillBook(skillBook) {
      await inventoryWorker.removeSkillBookFromInventory(this.$store, this.user, this.currentCharacter, skillBook);
      await characterWorker.modifyCharacterImmortalCurrency(this.$store, this.user, this.currentCharacter, skillBook.value);
      this.addItemToLibraryStock(skillBook);
    },
    removeItem(ware) {
      if (this.shop.type === 'library') {
        const categories = ['martialOffensive', 'martialDefensive', 'qiOffensive', 'qiDefensive'];
        for (const category of categories) {
          const index = this.shop.stock[category].findIndex(item => item.name === ware.name);
          if (index > -1) {
            this.shop.stock[category].splice(index, 1);
            return;
          }
        }
      } else if (this.shop.type === 'weapons' || this.shop.type === 'armor') {
        const index = this.shop.stock.findIndex(item => item.name === ware.name);
        if (index > -1) {
          this.shop.stock.splice(index, 1);
        }
      }
    },
    addItemToLibraryStock(skillBook) {
      const categories = {
        'Martial Offensive': 'martialOffensive',
        'Martial Defensive': 'martialDefensive',
        'Qi Offensive': 'qiOffensive',
        'Qi Defensive': 'qiDefensive'
      };
      const category = categories[skillBook.type];
      if (category) {
        this.shop.stock[category].push(skillBook);
      }
    },
    addItemToShopStock(item) {
      if (this.shop.type === 'weapons' || this.shop.type === 'armor') {
        this.shop.stock.push(item);
      }
    },
    buildPlayerResourceInventory() {
      this.playerResources = this.currentCharacter.resources.map(resource => ({
        ...resource,
        sellQuantity: 1
      }));
    },
    async sellItem(item) {
      let sellValue = item.value;
      await inventoryWorker.removeFromInventory(this.$store, this.user, this.currentCharacter, item);
      await characterWorker.modifyCharacterMortalCurrency(this.$store, this.user, this.currentCharacter, sellValue);
      this.addItemToShopStock(item);
      this.buildPlayerResourceInventory();
    },
    async sellLivestock(livestock) {
      let sellValue;
      if (livestock.mature === true) {
        sellValue = livestock.sellValue;
      } else {
        sellValue = livestock.purchasePrice;
      }
      await inventoryWorker.removeLivestockFromInventory(this.$store, this.user, this.currentCharacter, livestock);
      await characterWorker.modifyCharacterMortalCurrency(this.$store, this.user, this.currentCharacter, sellValue);
      this.buildPlayerResourceInventory();
    },
    updateShopDisplay() {
      this.shop.stock = [...this.shop.stock];
    },
    updateResourceDisplay() {
      this.playerResources = [...this.playerResources];
    },
    capitalizeAllWords(string) {
      return helperFunctions.capitalizeAllWords(string);
    },
    formatSkillValue(value) {
      return Object.entries(value).map(([key, val]) => `${val} ${key}`).join(', ');
    },
    getItemColor(item) {
      return (this.shop.type === 'weapons' || this.shop.type === 'armor') && item.rarity ? item.rarity.color : this.defaultColor;
    },
    getAllTypes() {
      const typesSet = new Set();
      cultivationInformation.realms.forEach(realm => {
        Object.keys(realm).forEach(key => {
          if (Array.isArray(realm[key])) {
            typesSet.add(key);
          }
        });
      });
      return Array.from(typesSet);
    }
  },
  created() {
    this.types = this.getAllTypes();
  },
  mounted() {
    this.buildPlayerResourceInventory();
  }
};
</script>

<style scoped>
.shop-test {
  text-align: center;
  margin-top: 20px;
}

.shop-row {
  padding: 12px;
}

.shop-display {
  border-radius: 10px;
  border: solid #ccc 2px;
  margin-top: 10px;
}

.v-list-item {
  border-radius: 12px;
  border: 1px solid #ccc;
  /* Optional: Add a border */
  margin-bottom: 8px;
  /* Space between items */
  overflow: hidden;
  /* To ensure the content doesn't overflow the rounded corners */
}

.button-stack button {
  margin: 5px;
}

.currency-display {
  margin: 10px;
}

.quantity-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -5px;
  margin-bottom: -5px;
}

.quantity-button {
  width: 25px;
  height: 25px;
  border: solid black 2px;
  border-radius: 5px;
  margin: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.item-name {
  font-weight: bold;
}

.equipment-image {
  width: 200px;
  height: 200px;
}

button {
  margin: 5px;
}

.item-action-button {
  width: 20%!important;
  height: 50px!important;
  margin-left: auto;
  margin-right: auto;
}
</style>
