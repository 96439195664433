<template>
  <div class="ion-padding">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <h1>Create Your Character</h1>
        </ion-col>
      </ion-row>
      <form @submit.prevent="createCharacter">
        <ion-row>
          <ion-col size="12">
            <ion-item>
              <ion-label position="floating">Name</ion-label>
              <ion-input v-model="character.name" required></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-item>
              <ion-label position="floating">Background</ion-label>
              <ion-select v-model="character.background" placeholder="Select Background" interface="alert" required>
                <ion-select-option v-for="background in backgrounds" :key="background" :value="background">
                  {{ background }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-button expand="block" type="submit" color="primary">
              Create
            </ion-button>
          </ion-col>
        </ion-row>
      </form>
    </ion-grid>
  </div>
</template>

<script>
import axios from 'axios';
import { getAuth } from "firebase/auth";
import { useRouter } from 'vue-router';
import characterWorker from '../../workers/characterWorker';

const apiUrl = process.env.VUE_APP_API_URL;
console.log('API URL:', apiUrl);

export default {
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
      userId: '',
      character: {
        userId: '',
        name: '',
        background: '',
        potential: 3,
        attributePoints: 0,
        strength: 10,
        endurance: 10,
        intelligence: 10,
        agility: 10,
        spirit: 10,
        charisma: 10,
        luck: 10,
        realm: '',
        stage: 0,
        rebirth: 0,
        level: 1,
        qiTotal: 0,
        qiToNextLevel: 25,
        health: 0,
        maxHealth: 0,
        stamina: 0,
        maxStamina: 0,
        vitalEnergy: 0,
        maxVitalEnergy: 0,
        resistance: 10,
        armorValue: 0,
        carryWeight: 0,
        maxCarryWeight: 0,
        skills: [],
        professions: [
          {
            name: "Blacksmithing",
            level: 0,
            xp: 0,
            recipes: []
          },
          {
            name: "Alchemy",
            level: 0,
            xp: 0,
            recipes: []
          },
          {
            name: "Enchanting",
            level: 0,
            xp: 0,
            recipes: []
          },
          {
            name: "Inscription",
            level: 0,
            xp: 0,
            recipes: []
          },
          {
            name: "Farming",
            level: 0,
            xp: 0
          },
          {
            name: "Cooking",
            level: 0,
            xp: 0,
            recipes: []
          }
        ],
        professionSkills: [
          {
            name: "Hunting",
            level: 0,
            xp: 0
          },
          {
            name: "Fishing",
            level: 0,
            xp: 0
          },
          {
            name: "Mining",
            level: 0,
            xp: 0
          },
          {
            name: "Herbalism",
            level: 0,
            xp: 0
          },
          {
            name: "Skinning",
            level: 0,
            xp: 0
          }
        ],
        quests: [],
        bonuses: {
          xp: 0,
          health: 0,
          accuracy: 0,
          damage: 0,
          income: 0
        },
        inventory: [],
        skillBooks: [],
        consumables: [],
        resources: [],
        mustardSeed: {},
        livestock: [],
        seeds: [],
        equipped: [
          {
            "slot": "weapon",
            "item": {
              "id": "l7VRahCcENWcKBzZ14Ok",
              "slot": "weapon",
              "name": "Rusty Dao",
              "level": 1,
              "type": "Melee",
              "rarity": {
                "name": "Mortal",
                "color": "gray",
                "bonus": 0.8
              },
              "subtype": "Dao",
              "damage": 11,
              "accuracy": 11,
              "criticalChance": 5,
              "weight": 1,
              "maintainence": 24,
              "attachments": {
                "striking-surface": {},
                "hilt": {},
                "charm": {},
                "guard": {},
                "pommel": {}
              },
              "image": "https://willoftheimmortals-images.s3.amazonaws.com/weaponimages/rustydao.png",
              "baseStats": {
                "id": "l7VRahCcENWcKBzZ14Ok",
                "slot": "weapon",
                "name": "Rusty Dao",
                "level": 1,
                "type": "Melee",
                "rarity": {
                  "name": "Mortal",
                  "color": "gray",
                  "bonus": 0.8
                },
                "subtype": "Dao",
                "damage": 11,
                "accuracy": 11,
                "criticalChance": 5,
                "weight": 1,
                "maintainence": 24,
                "attachments": {
                  "striking-surface": {},
                  "hilt": {},
                  "charm": {},
                  "guard": {},
                  "pommel": {}
                },
                "image": "https://willoftheimmortals-images.s3.amazonaws.com/weaponimages/rustydao.png"
              },
              "rarities": [
                {
                  "name": "Mortal",
                  "color": "gray",
                  "bonus": 0.8
                },
                {
                  "name": "Earthly",
                  "color": "green",
                  "bonus": 1
                },
                {
                  "name": "Heavenly",
                  "color": "blue",
                  "bonus": 1.15
                },
                {
                  "name": "Immortal",
                  "color": "purple",
                  "bonus": 1.3
                },
                {
                  "name": "Divine",
                  "color": "orange",
                  "bonus": 1.5
                },
                {
                  "name": "Celestial",
                  "color": "red",
                  "bonus": 2
                }
              ],
              "value": 200
            }
          },
          {
            "slot": "head",
            "item": {}
          },
          {
            "slot": "neck",
            "item": {}
          },
          {
            "slot": "chest",
            "item": {
              "id": "cSJjXyl1rFASE8sjjBon",
              "name": "Frayed Robe",
              "level": 5,
              "rarity": {
                "name": "Mortal",
                "color": "gray",
                "bonus": 0.8
              },
              "armorValue": 1,
              "maintainence": 5,
              "slot": "chest",
              "type": "cloth",
              "rarities": [
                {
                  "name": "Mortal",
                  "color": "gray",
                  "bonus": 0.8
                },
                {
                  "name": "Earthly",
                  "color": "green",
                  "bonus": 1
                },
                {
                  "name": "Heavenly",
                  "color": "blue",
                  "bonus": 1.15
                },
                {
                  "name": "Immortal",
                  "color": "purple",
                  "bonus": 1.3
                },
                {
                  "name": "Divine",
                  "color": "orange",
                  "bonus": 1.5
                },
                {
                  "name": "Celestial",
                  "color": "red",
                  "bonus": 2
                }
              ],
              "value": 200,
              "image": "https://willoftheimmortals-images.s3.amazonaws.com/armorimages/frayedshenyirobe.png"
            }
          },
          {
            "slot": "waist",
            "item": {
              "id": "zo7hwG6xW15YaI2X09nA",
              "name": "Tattered Belt",
              "level": 1,
              "rarity": {
                "name": "Mortal",
                "color": "gray",
                "bonus": 0.8
              },
              "armorValue": 2,
              "maintainence": 14,
              "slot": "waist",
              "type": "leather",
              "rarities": [
                {
                  "name": "Mortal",
                  "color": "gray",
                  "bonus": 0.8
                },
                {
                  "name": "Earthly",
                  "color": "green",
                  "bonus": 1
                },
                {
                  "name": "Heavenly",
                  "color": "blue",
                  "bonus": 1.15
                },
                {
                  "name": "Immortal",
                  "color": "purple",
                  "bonus": 1.3
                },
                {
                  "name": "Divine",
                  "color": "orange",
                  "bonus": 1.5
                },
                {
                  "name": "Celestial",
                  "color": "red",
                  "bonus": 2
                }
              ],
              "value": 200,
              "image": "https://willoftheimmortals-images.s3.amazonaws.com/armorimages/tatteredhifubelt.png"
            }
          },
          {
            "slot": "legs",
            "item": {
              "id": "Cds5Gg345GFw4sdg4gd34",
              "name": "Worn Pants",
              "level": 1,
              "rarity": {
                "name": "Mortal",
                "color": "gray",
                "bonus": 0.8
              },
              "armorValue": 4,
              "maintainence": 5,
              "slot": "legs",
              "type": "cloth",
              "rarities": [
                {
                  "name": "Mortal",
                  "color": "gray",
                  "bonus": 0.8
                },
                {
                  "name": "Earthly",
                  "color": "green",
                  "bonus": 1
                },
                {
                  "name": "Heavenly",
                  "color": "blue",
                  "bonus": 1.15
                },
                {
                  "name": "Immortal",
                  "color": "purple",
                  "bonus": 1.3
                },
                {
                  "name": "Divine",
                  "color": "orange",
                  "bonus": 1.5
                },
                {
                  "name": "Celestial",
                  "color": "red",
                  "bonus": 2
                }
              ],
              "value": 200,
              "image": "https://willoftheimmortals-images.s3.amazonaws.com/armorimages/wornchangpaotrousers.png"
            }
          },
          {
            "slot": "feet",
            "item": {
              "id": "5Mn9UWjzJdA6u92tDr9D",
              "name": "Old Sandals",
              "level": 1,
              "rarity": {
                "name": "Mortal",
                "color": "gray",
                "bonus": 0.8
              },
              "armorValue": 1,
              "maintainence": 12,
              "slot": "feet",
              "type": "leather",
              "rarities": [
                {
                  "name": "Mortal",
                  "color": "gray",
                  "bonus": 0.8
                },
                {
                  "name": "Earthly",
                  "color": "green",
                  "bonus": 1
                },
                {
                  "name": "Heavenly",
                  "color": "blue",
                  "bonus": 1.15
                },
                {
                  "name": "Immortal",
                  "color": "purple",
                  "bonus": 1.3
                },
                {
                  "name": "Divine",
                  "color": "orange",
                  "bonus": 1.5
                },
                {
                  "name": "Celestial",
                  "color": "red",
                  "bonus": 2
                }
              ],
              "value": 200,
              "image": "https://willoftheimmortals-images.s3.amazonaws.com/armorimages/frayedgetasandals.png"
            }
          },
          {
            "slot": "arms",
            "item": {}
          },
          {
            "slot": "hands",
            "item": {}
          },
          {
            "slot": "ring1",
            "item": {}
          },
          {
            "slot": "ring2",
            "item": {}
          },
          {
            "slot": "ring3",
            "item": {}
          },
          {
            "slot": "ring4",
            "item": {}
          }
        ],
        mortalCurrency: 1001,
        immortalCurrency: 25,
        zone: "red-grass-town",
        dateCreated: new Date(),
      },
      backgrounds: [
        'Farmer',
        'Merchant',
        'Mercenary',
        'Blacksmith',
        'Alchemist',
        'Researcher'
      ]
    };
  },
  async mounted() {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      this.userId = user.uid;
      this.character.userId = this.userId;
    } else {
      alert("User not authenticated");
    }
    console.log(this.backgrounds)
  },
  methods: {
    async createCharacter() {
      try {
        const character = { ...this.character };
        const characterData = characterWorker.calculateRealmStageRebirth(character);
        const response = await axios.post(`${apiUrl}/api/characters`, characterData);
        if (response.status === 201 && response.data && response.data.insertedId) {
          this.$router.push(`/characters/${this.userId}`);
        }
      } catch (error) {
        console.error('An error occurred while creating the character:', error);
      }
    },
  },
};
</script>

<style scoped></style>
