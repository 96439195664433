import axios from 'axios';
import characterWorker from './characterWorker';
import inventoryCalculations from '../../serverless-backend/server/utilities/inventoryCalculations';

const getCharacterInventory = async (user, id) => {
  try {
    const character = await characterWorker.getCharacter(user, id);
    return character;
  } catch (error) {
    console.error("Failed to get character inventory", error);
  }
}

const addToInventory = async (store, user, character, item) => {
  try {
    let updatedCharacter = character;
    updatedCharacter.inventory.push(item);
    await characterWorker.updateCharacter(store, user, updatedCharacter);
  } catch (error) {
    console.error("Failed to add item to inventory", error);
  }
}

const removeFromInventory = async (store, user, character, item) => {
  try {
    let updatedCharacter = character;
    updatedCharacter.inventory = updatedCharacter.inventory.filter(i => i.id !== item.id);
    await characterWorker.updateCharacter(store, user, updatedCharacter);
  } catch (error) {
    console.error("Failed to remove item from inventory", error);
  }
}

const addResourcesToInventory = async (store, user, character, resources) => {
  try {
    console.log("RESOURCES: ", resources);
    let updatedCharacter = { ...character };

    if (!Array.isArray(resources)) {
      resources = [resources];
    }

    resources.forEach(resource => {
      let resourceExists = false;

      for (let i = 0; i < updatedCharacter.resources.length; i++) {
        if (updatedCharacter.resources[i].material === resource.name) {
          updatedCharacter.resources[i].quantity += parseInt(resource.quantity, 10);
          resourceExists = true;
          break;
        }
      }

      if (!resourceExists) {
        updatedCharacter.resources.push({
          material: resource.name,
          quantity: parseInt(resource.quantity, 10),
          value: resource.value
        });
      }
    });

    await characterWorker.updateCharacter(store, user, updatedCharacter);
  } catch (error) {
    console.error("Failed to add resources to inventory", error);
  }
}

const removeFromResources = async (store, user, character, resources) => {
  try {
    let updatedCharacter = character;

    console.log("Removing resources: ", resources);
    console.log("Character resources: ", updatedCharacter.resources);

    if (!Array.isArray(resources)) {
      resources = [resources];
    }
    console.log("Resources BEFORE:", resources);
    resources.forEach(resource => {
      updatedCharacter.resources = updatedCharacter.resources.map(res => {
        if (res.material === resource.name) {
          console.log("Removing: ", resource.quantity, " of ", resource.name);
          res.quantity = parseInt(res.quantity, 10) - parseInt(resource.quantity, 10);
        }
        return res;
      }).filter(res => res.quantity > 0);
    });

    console.log("Updated resources: ", updatedCharacter.resources);
    await characterWorker.updateCharacter(store, user, updatedCharacter);
  } catch (error) {
    console.error("Failed to remove items from resources", error);
  }
}

const addLivestockToInventory = async (store, user, character, livestock) => {
  try {
    let updatedCharacter = { ...character };

    if (!Array.isArray(livestock)) {
      livestock = [livestock];
    }

    livestock.forEach(animal => {
      let livestockExists = false;

      for (let i = 0; i < updatedCharacter.livestock.length; i++) {
        if (updatedCharacter.livestock[i].name === animal.name) {
          updatedCharacter.livestock[i].quantity += 1;
          livestockExists = true;
          break;
        }
      }

      if (!livestockExists) {
        updatedCharacter.livestock.push({
          name: animal.name,
          resources: animal.resources,
          purchasePrice: animal.purchasePrice,
          timeToMature: animal.timeToMature,
          mature: animal.mature,
          sellValue: animal.sellValue,
          quantity: 1
        });
      }
    });

    await characterWorker.updateCharacter(store, user, updatedCharacter);
  } catch (error) {
    console.error("Failed to add livestock to inventory", error);
  }
}

const removeLivestockFromInventory = async (store, user, character, livestock) => {
  try {
    let updatedCharacter = { ...character };

    if (!Array.isArray(livestock)) {
      livestock = [livestock];
    }

    livestock.forEach(animal => {
      updatedCharacter.livestock = updatedCharacter.livestock.map(l => {
        if (l.name === animal.name) {
          l.quantity -= 1;
        }
        return l;
      }).filter(l => l.quantity > 0);
    });

    await characterWorker.updateCharacter(store, user, updatedCharacter);
  } catch (error) {
    console.error("Failed to remove livestock from inventory", error);
  }
}

const addSkillBookToInventory = async (store, user, character, skillBook) => {
  try {
    let updatedCharacter = { ...character };
    updatedCharacter.skillBooks.push(skillBook);
    await characterWorker.updateCharacter(store, user, updatedCharacter);
  } catch (error) {
    console.error("Failed to add skill book to inventory", error);
  }
}

const removeSkillBookFromInventory = async (store, user, character, skillBook) => {
  try {
    let updatedCharacter = { ...character };
    updatedCharacter.skillBooks = updatedCharacter.skillBooks.filter(book => book.name !== skillBook.name);
    await characterWorker.updateCharacter(store, user, updatedCharacter);
  } catch (error) {
    console.error("Failed to remove skill book from inventory", error);
  }
}

const equipItem = async (store, user, character, item) => {
  try {
    let itemSlot = item.slot;
    let updatedCharacter = character;

    const index = updatedCharacter.equipped.findIndex(i => i.slot === itemSlot);
    if (index !== -1) {
      if (updatedCharacter.equipped[index].item.id) {
        addToInventory(store, user, updatedCharacter, updatedCharacter.equipped[index].item);
        updatedCharacter.equipped[index].item = {};
      }
      updatedCharacter.equipped[index].item = item;
      updatedCharacter = inventoryCalculations.calculateArmorStats(updatedCharacter);
      removeFromInventory(store, user, updatedCharacter, item);
      return await characterWorker.updateCharacter(store, user, updatedCharacter);
    } else {
      console.log("Error: Item not equipped")
    }
  }
  catch (error) {
    console.error("Failed to equip item", error);
  }
}

const unequipItem = async (store, user, character, item) => {
  try {
    let updatedCharacter = character;

    for (let i = 0; i < updatedCharacter.equipped.length; i++) {
      if (updatedCharacter.equipped[i].slot === item.slot) {
        addToInventory(store, user, updatedCharacter, updatedCharacter.equipped[i].item);
        updatedCharacter.equipped[i].item = {};
        updatedCharacter = inventoryCalculations.calculateArmorStats(updatedCharacter);
        return await characterWorker.updateCharacter(store, user, updatedCharacter);
      }
    }
  }
  catch (error) {
    console.error("Failed to unequip item", error);
  }
}

const addToConsumables = async (store, user, character, item) => {
  let updatedCharacter = character;

  const existingItem = updatedCharacter.consumables.find(consumable => consumable.name === item.name);

  if (existingItem) {
    existingItem.quantity += 1;
  } else {
    updatedCharacter.consumables.push(item);
  }

  return await characterWorker.updateCharacter(store, user, updatedCharacter);
}

const removeFromConsumables = async (store, user, character, item) => {
  let updatedCharacter = character;
  console.log("ITEM: ", item);
  const existingItem = updatedCharacter.consumables.find(consumable => consumable.name === item.name);
  console.log("EXISTING ITEM: ", existingItem);
  if (existingItem) {
    existingItem.quantity -= 1;
    if (existingItem.quantity <= 0) {
      updatedCharacter.consumables = updatedCharacter.consumables.filter(consumable => consumable.name !== item.name);
    }
  }
  console.log("UPDATED CHARACTER: ", updatedCharacter);
  return await characterWorker.updateCharacter(store, user, updatedCharacter);
}

export default {
  getCharacterInventory,
  addToInventory,
  addResourcesToInventory,
  addToConsumables,
  removeFromInventory,
  removeFromResources,
  removeFromConsumables,
  addLivestockToInventory,
  removeLivestockFromInventory,
  addSkillBookToInventory,
  removeSkillBookFromInventory,
  equipItem,
  unequipItem
}
