<template>
  <div class="ion-padding display-card landing-page">
    <img  src="https://willoftheimmortals-images.s3.amazonaws.com/siteimages/monklogo.png" class="monk-logo" alt="Monk Logo">
    <h1>Will of the Immortals</h1>
    <h3>Coming Soon™</h3>
    <br>
    <p>If you are here right now, you are probably very lost. This game is very much in development and nowhere near
      ready for you to play it.</p>
    <br>
    <p>That said, feel free to poke around and break stuff—it's entirely set up for testing various mechanics as I build
      them. The button to open the menu is above you.</p>
    <br>
    <p>This game is based on Chinese Cultivation fantasy. If you are a fan of the genre, you'll feel right at home. If
      you aren't a fan of the genre, you should consider checking it out.</p>
    <br>
    <p>Check out the Cradle series, The Immortal Alchemist Returns, YAMA: From Shadows to Sovereignty, among others (All
      Available on Amazon).</p>
  </div>
</template>

<script>

export default {
  name: 'LandingPage',
};
</script>

<style scoped>
.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 700px;
  padding: 50px;
}

.monk-logo {
  width: 200px;
  height: 200px;
  background-color: #010038;
  border: solid wheat 3px;
  border-radius: 50%;

}
</style>