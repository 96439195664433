<template>
  <div class="object-list">
    <div v-for="(item, index) in sortedItems" :key="index" class="list-item text-center">
      <p class="item-name">{{ item.name }}</p> <p class="item-level">Realm: {{ item.realm }}</p> <p>Stage: {{ item.stage }}</p>
    </div>
  </div>
  <QJButton @click="closeScene">Close</QJButton>
</template>

<script>
import QJButton from '../framework/QJButton.vue';

export default {
  name: 'SortedList',
  components: {
    QJButton
  },
  props: {
    items: {
      type: Array,
      required: true,
      validator: (value) => {
        return value.every(item => 'name' in item && 'level' in item);
      }
    }
  },
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
    sortedItems() {
      return this.items.slice().sort((a, b) => b.level - a.level);
    }
  },
  mounted() {
    console.log('Items:', this.items);
  },
  methods: {
    closeScene() {
      console.log('Closing scene');
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.object-list {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 2px solid #000;
  border-radius: 10px;
  background-color: #f9f9f9;
  max-width: 300px;
  margin: 0 auto;
}

.character-ticker {
  display: none;
}

.list-item {
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
}

.list-item:last-child {
  border-bottom: none;
}

.item-name {
  font-weight: bold;
}

.item-level {
  color: #555;
}
</style>