<template>
  <div class="container">
    <ButtonStack :startOpen="true">
      <QJButton @click="setMapType('cave')">New Cave Map</QJButton>
      <QJButton @click="setMapType('open')">New Open Map</QJButton>
      <QJButton @click="setMapType('town')">New Town Map</QJButton>
    </ButtonStack>

    <ion-text>
      <h4>{{ mapName }}</h4>
    </ion-text>

    <ion-item>
      <ion-label>Select a Map</ion-label>
      <ion-select v-model="selectedMap" @ionChange="loadMap(selectedMap.name)">
        <ion-select-option v-for="map in availableMaps" :key="map._id" :value="map">{{ map.name }}</ion-select-option>
      </ion-select>
    </ion-item>

    <MapComponent
      :map-type="mapType"
      :map-seed="mapSeed"
      :map-rows="mapRows"
      :map-cols="mapCols"
      :num-combat="numCombat"
      :num-minerals="numMinerals"
      :num-hunting="numHunting"
      :num-fishing="numFishing"
      :map-data="selectedMap ? selectedMap : null"
      @update-map-seed="updateMapSeed"
    />

    <ion-grid class="seed-container button-stack">
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-input v-model="inputSeed" type="text" placeholder="Enter seed (e.g., c-345645)"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col>
          <QJButton @click="generateMapWithSeed">Pull Map w/ Seed</QJButton>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import MapComponent from '../map/MapComponent.vue';
import ButtonStack from '../framework/ButtonStack.vue';
import QJButton from '../framework/QJButton.vue';

import mapWorker from '../../workers/mapWorker';

export default {
  components: {
    MapComponent,
    ButtonStack,
    QJButton,
  },
  data() {
    return {
      mapType: 'cave',
      mapSeed: null,
      mapName: null,
      inputSeed: null,
      mapRows: 20,
      mapCols: 20,
      numCombat: 10,
      numMinerals: 15,
      numHunting: 5,
      numFishing: 5,
      isMapPropertiesCollapsed: true,
      availableMaps: [],
      selectedMap: null,
    };
  },
  methods: {
    setMapType(type) {
      this.mapType = type;
      this.mapSeed = null;
      this.selectedMap = null; // Reset selected map
    },
    async generateMapWithSeed() {
      this.mapSeed = this.inputSeed;
      this.selectedMap = null; // Reset selected map
    },
    updateMapSeed(newSeed) {
      this.mapSeed = newSeed;
    },
    async loadAvailableMaps() {
      try {
        // Assume mapWorker is an imported module that handles map fetching from the database
        this.availableMaps = await mapWorker.loadAvailableMaps(this.$store.getters.getCurrentUser);
      } catch (error) {
        console.error('Error loading available maps:', error);
      }
    },
    async loadMap(mapName) {
      if (!mapName) return;
      try {
        this.selectedMap = await mapWorker.loadMap(this.$store.getters.getCurrentUser, mapName);
        this.mapType = this.selectedMap.type || 'cave'; // Set the map type based on the loaded map
        this.mapSeed = null; // Reset the seed when loading a map from the database
      } catch (error) {
        console.error(`Error loading map "${mapName}":`, error);
      }
    },
  },
  async mounted() {
    await this.loadAvailableMaps(); // Load available maps when component is mounted
  },
};

</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-stack {
  margin-bottom: 20px;
}

.seed-container {
  margin-bottom: 20px;
}

.size-container {
  margin-bottom: 20px;
}

.map-properties-container {
  margin-bottom: 20px;
}
</style>
