<template>
  <div class="container">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <div class="settings button-stack narrow-stack50">
            <!-- Inputs for ores and turns -->
            <!-- Rows/Cols Inputs -->
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-button expand="block" @click="resetGrid" class="reset-button">Reset</ion-button>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <div class="grid">
            <div v-for="(row, rowIndex) in grid" :key="rowIndex" class="row">
              <div v-for="(cell, colIndex) in row" :key="colIndex" :class="cellClass(cell)" @mouseover="hoverCell(cell)"
                @mouseleave="unhoverCell(cell)" @click="clickCell(cell)" class="cell"></div>
            </div>
          </div>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <div class="info">
            <p>Turns left: {{ turnsLeft }}</p>
            <p>Ore found: {{ oreFound }}/{{ numOres }}</p>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
export default {
  data() {
    return {
      grid: [],
      turnsLeft: 15,
      oreFound: 0,
      numOres: 10,
      numTurns: 15,
      numRows: 5,
      numCols: 5,
    };
  },
  created() {
    this.initializeGrid();
  },
  methods: {
    initializeGrid() {
      this.grid = Array.from({ length: this.numRows }, () =>
        Array.from({ length: this.numCols }, () => ({ hasOre: false, state: 'default' }))
      );
      this.turnsLeft = this.numTurns;
      this.oreFound = 0;
      this.placeOre();
    },
    placeOre() {
      let oreCount = 0;
      while (oreCount < this.numOres) {
        const row = Math.floor(Math.random() * this.numRows);
        const col = Math.floor(Math.random() * this.numCols);
        if (!this.grid[row][col].hasOre) {
          this.grid[row][col].hasOre = true;
          oreCount++;
        }
      }
    },
    cellClass(cell) {
      return {
        cell: true,
        'cell-default': cell.state === 'default',
        'cell-hover': cell.state === 'hover',
        'cell-ore': cell.state === 'ore',
        'cell-empty': cell.state === 'empty',
      };
    },
    hoverCell(cell) {
      if (cell.state === 'default') {
        cell.state = 'hover';
      }
    },
    unhoverCell(cell) {
      if (cell.state === 'hover') {
        cell.state = 'default';
      }
    },
    clickCell(cell) {
      if ((cell.state === 'default' || cell.state === 'hover') && this.turnsLeft > 0) {
        this.turnsLeft--;
        if (cell.hasOre) {
          cell.state = 'ore';
          this.oreFound++;
          if (this.oreFound === this.numOres) {
            this.onWin();
          }
        } else {
          cell.state = 'empty';
          if (this.turnsLeft === 0) {
            this.onLose();
          }
        }
      }
    },
    onWin() {
      console.log(`All ore found in ${this.numTurns - this.turnsLeft} turns!`);
      this.resetGrid();
    },
    onLose() {
      console.log(`Out of turns! Ore found: ${this.oreFound}`);
      this.resetGrid();
    },
    resetGrid() {
      this.initializeGrid();
    },
  },
};
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.settings {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.reset-button {
  margin-left: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.grid {
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px;
}

.row {
  display: flex;
}

.cell {
  width: 40px;
  height: 40px;
  margin: 2px;
  border: 1px solid #ccc;
  transition: background-color 0.3s;
}

.cell-default {
  background-color: gray;
}

.cell-hover {
  background-color: darkgray;
  cursor: url('../../../public/svgs/mining-cursor.svg'), auto;
}

.cell-ore {
  background-color: blue;
  cursor: url('../../../public/svgs/disabled-cursor.svg'), auto;
}

.cell-empty {
  background-color: black;
  cursor: url('../../../public/svgs/disabled-cursor.svg'), auto;
}

.info {
  margin-top: 20px;
  text-align: center;
}
</style>