const helperFunctions = require('../helpers/helperFunctions.js');

exports.calculateMaxCarryWeight = function (strength) {
  return Math.ceil(strength * 10);
};

exports.calculateMaxHealth = function (endurance) {
  return Math.ceil(endurance * 5);
};

exports.calculateMaxStamina = function (agility) {
  return Math.ceil(agility * 5);
}

exports.calculateMaxVitalEnergy = function (spirit) {
  return Math.ceil(spirit * 5);
}

exports.calculateBonuses = function (character) {
  let bonuses = {
    strengthBonus: 0,
    enduranceBonus: 0,
    intelligenceBonus: 0,
    agilityBonus: 0,
    charismaBonus: 0,
    spiritBonus: 0,
    staminaBonus: 0,
    xpBonus: 0,
    blacksmithingBonus: 0,
    alchemyBonus: 0,
    cookingBonus: 0,
    farmingBonus: 0,
    enchantingBonus: 0,
    incomeBonus: 0
  }

  switch (character.background) {
    case "Farmer":
      bonuses.enduranceBonus = 3;
      bonuses.farmingBonus = 3;
      break;

    case "Merchant":
      bonuses.charismaBonus = 3;
      bonuses.incomeBonus = 3;
      break;

    case "Researcher":
      bonuses.intelligenceBonus = 3;
      bonuses.enchantingBonus = 3;
      break;

    case "Mercenary":
      bonuses.agilityBonus = 3;
      bonuses.staminaBonus = 3;
      break;

    case "Alchemist":
      bonuses.spiritBonus = 3;
      bonuses.alchemyBonus = 3;
      break;

    case "Blacksmith":
      bonuses.strengthBonus = 3;
      bonuses.blacksmithingBonus = 3;
      break;
  }

  return bonuses;
};

exports.calculateStatBonuses = function (character) {
  let updatedBonuses = character.bonuses;
  updatedBonuses.xpBonus += (character.luck / 2);
  updatedBonuses.spiritBonus += (character.intelligence / 2);
  updatedBonuses.staminaBonus += (character.endurance / 2);
  updatedBonuses.accuracyBonus += (character.agility / 2);
  updatedBonuses.damageBonus += (character.strength / 2);
  updatedBonuses.incomeBonus += (character.charisma / 2);
  return updatedBonuses;
}

exports.calculateXpToLevel = function (level) {
  return level * 50;
}

exports.calculateNewHealth = function (character) {
  return Math.ceil(character.maxHealth + (helperFunctions.rollDice(10) * character.level));
}

exports.calculateNewStamina = function (character) {
  return Math.ceil(character.maxStamina + (helperFunctions.rollDice(10) * character.level));
}

exports.calculateNewVitalEnergy = function (character) {
  return Math.ceil(character.maxVitalEnergy + (helperFunctions.rollDice(10) * character.level));
}