<template>
    <div class="game-container">
        <FishingMinigame @close="handleClose" />
    </div>
</template>

<script>
import FishingMinigame from '../minigames/FishingGame.vue';

export default {
    components: {
        FishingMinigame
    },
    methods: {
        handleClose() {
            setTimeout(() => {
                console.log('Closing combat scene');
                this.$emit('close');
            }, 2000); // Display result for 2 seconds before closing
        }
    }
};
</script>

<style scoped>
.game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
}
</style>