<template>
  <div>
    <ion-modal [is-open]="dialog" :css-class="isMobile ? 'fullscreen-modal' : 'custom-size-modal'"
      @ionBackdropTap="closeDialog">
      <div class="background-container" :style="{ backgroundImage: 'url(' + backgroundImage + ')' }">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="end">
              <ion-button @click="closeDialog">
                <ion-icon name="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <div class="scrollable-text">
          <div class="text-background">
            <div v-html="formattedStoryText"></div>
          </div>
        </div>
      </div>
    </ion-modal>
  </div>
</template>

<script>
export default {
  props: {
    fileName: {
      type: String,
      required: true
    },
    background: {
      type: String,
      default: 'city' // 'city', 'forest', or 'cave'
    }
  },
  data() {
    return {
      dialog: true,
      storyText: '',
      backgrounds: {
        city: '/assets/images/backgrounds/citybackground.png',
        forest: '/assets/images/backgrounds/forestbackground.png',
        cave: '/assets/images/backgrounds/cavebackground.png'
      }
    };
  },
  computed: {
    backgroundImage() {
      return this.backgrounds[this.background];
    },
    isMobile() {
      return window.innerWidth <= 600; // Adjust this breakpoint as needed
    },
    formattedStoryText() {
      return this.storyText.replace(/\n/g, '<br>');
    }
  },
  mounted() {
    this.loadText();
  },
  methods: {
    async loadText() {
      try {
        const response = await fetch(`/assets/exposition/${this.fileName}`);
        this.storyText = await response.text();
      } catch (error) {
        console.error('Failed to load story text:', error);
      }
    },
    closeDialog() {
      this.dialog = false;
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.background-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.scrollable-text {
  overflow-y: auto;
  flex: 1;
}

.text-background {
  background: rgba(0, 0, 0, 0.5);
  padding: 16px;
  border-radius: 16px;
  color: white;
  max-width: 100%;
  /* Ensure text fits within the container */
  box-sizing: border-box;
  /* Include padding in the element's total width and height */
  word-wrap: break-word;
  /* Wrap long words */
  word-break: break-word;
  /* Break words that are too long */
  white-space: pre-wrap;
  /* Preserve whitespace and wrap as needed */
}
</style>