import { createRouter, createWebHistory } from '@ionic/vue-router';
import Landing from '../components/framework/Landing.vue';
import AuthForm from '../components/account/AuthForm.vue';
import CharacterCreate from '@/components/character/CharacterCreate.vue';
import CharacterStatusScreen from '@/components/character/CharacterStatusScreen.vue';
import SkillsTest from '@/components/testing/SkillsTest.vue';
import Tribulation from '@/components/minigames/Tribulation.vue';
import LoadCharacters from '@/components/account/LoadCharacters.vue';
import EquipmentGenerationTest from '@/components/testing/EquipmentGenerationTest.vue';
import EnemyGenerationTest from '@/components/testing/EnemyGenerationTest.vue';
import RandomCombatTest from '@/components/testing/RandomCombatTest.vue';
import CharacterInventory from '@/components/character/CharacterInventory.vue';
import ShopGenerationTest from '@/components/testing/ShopGenerationTest.vue';
import ResourceGenerationTest from '@/components/testing/ResourceGenerationTest.vue';
import MapTest from '@/components/testing/MapTest.vue';
import WorldMapTest from '@/components/testing/WorldMapTest.vue';
import MapGenerator from '@/components/map/MapGenerator.vue';
import LargeMapGenerator from '@/components/map/LargeMapGenerator.vue';
import GatheringTest from '@/components/testing/GatheringTest.vue';
import MustardSeed from '@/components/scenes/MustardSeed.vue';
import WeaponAttachmentTest from '@/components/testing/WeaponAttachmentTest.vue';
import Kitchen from '@/components/professions/Kitchen.vue';
import MinigameTest from '@/components/testing/MinigameTest.vue';
import ProfessionTest from '@/components/testing/ProfessionTest.vue';
import QuestboardTest from '@/components/testing/QuestboardTest.vue';
import StoryTest from '@/components/testing/StoryTest.vue';
import TransmogTest from '@/components/testing/TransmogTest.vue';
import TestBed from '@/components/testing/TestBed.vue';

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/auth',
    name: 'Auth',
    component: AuthForm
  },
  {
    path: '/create-character',
    name: 'CreateCharacter',
    component: CharacterCreate
  },
  {
    path: '/character/:id',
    component: CharacterStatusScreen,
    name: 'CharacterStatusScreen'
  },
  {
    path: '/characters/:userId',
    component: LoadCharacters,
    name: 'LoadCharacters'
  },
  {
    path: '/skills-test/',
    component: SkillsTest,
    name: 'SkillsTest'
  },
  {
    path: '/equipment-test/',
    component: EquipmentGenerationTest,
    name: 'EquipmentGenerationTest'
  },
  {
    path: '/enemy-test/',
    component: EnemyGenerationTest,
    name: 'EnemyGenerationTest'
  },
  {
    path: '/random-combat/',
    component: RandomCombatTest,
    name: 'RandomCombatTest'
  },
  {
    path: '/minigame-test/',
    component: MinigameTest,
    name: 'MinigameTest'
  },
  {
    path: '/tribulation/',
    component: Tribulation,
    name: 'Tribulation'
  },
  {
    path: '/character-inventory/',
    component: CharacterInventory,
    name: 'CharacterInventory'
  },
  {
    path: '/shop-test/',
    component: ShopGenerationTest,
    name: 'ShopGenerationTest' 
  },
  {
    path: '/test-bed/',
    component: TestBed,
    name: 'TestBed'
  },
  {
    path: '/resource-test/',
    component: ResourceGenerationTest,
    name: 'ResourceGenerationTest'
  },
  {
    path: '/map-test/',
    component: MapTest,
    name: 'MapTest'
  },
  {
    path: '/world-map-test/',
    component: WorldMapTest,
    name: 'WorldMapTest'
  },
  {
    path: '/map-generator/',
    component: MapGenerator,
    name: 'MapGenerator'
  },
  {
    path: '/large-map-generator/',
    component: LargeMapGenerator,
    name: 'LargeMapGenerator'
  },
  {
    path: '/gathering-test/',
    component: GatheringTest,
    name: 'GatheringTest'
  },
  {
    path: '/mustard-seed-test/',
    component: MustardSeed,
    name: 'MustardSeed'
  },
  {
    path: '/kitchen/',
    component: Kitchen,
    name: 'Kitchen'
  },
  {
    path: '/quest-test/',
    component: QuestboardTest,
    name: 'QuestboardTest'
  },
  {
    path: '/weapon-attachment-test/',
    component: WeaponAttachmentTest,
    name: 'WeaponAttachmentTest'
  },
  {
    path: '/profession-test/',
    component: ProfessionTest,
    name: 'ProfessionTest'
  },
  {
    path: '/story-test/',
    component: StoryTest,
    name: 'StoryTest'
  },
  {
    path: '/transmog-test/',
    component: TransmogTest,
    name: 'TransmogTest'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;