<template>
    <div class="info-popup">
        <div>
            <ion-modal [is-open]="dialog" :css-class="isMobile ? 'fullscreen-modal' : 'custom-width-modal'"
                @ionBackdropTap="closeDialog">
                <ion-header>
                    <ion-toolbar>
                        <ion-buttons slot="end">
                            <ion-button @click="closeDialog">
                                <ion-icon name="close"></ion-icon>
                            </ion-button>
                        </ion-buttons>
                    </ion-toolbar>
                </ion-header>
                <div class="scrollable-text">
                    <div class="text-background">
                        <div v-html="formattedText"></div>
                    </div>
                </div>
            </ion-modal>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            dialog: true
        };
    },
    computed: {
        isMobile() {
            return window.innerWidth <= 600; // Adjust this breakpoint as needed
        },
        formattedText() {
            return this.text.replace(/\n/g, '<br>');
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false;
            this.$emit('close');
        }
    }
};
</script>

<style scoped>
.scrollable-text {
    overflow-y: auto;
    flex: 1;
}

.text-background {
    background: rgba(0, 0, 0, 0.5);
    padding: 16px;
    border-radius: 8px;
    color: white;
    max-width: 100%;
    box-sizing: border-box;
    word-wrap: break-word;
    word-break: break-word;
    white-space: pre-wrap;
}
</style>
