<template>
    <div class="armor-grid">
        <ion-grid>
            <ion-row>
                <!-- Loop through all categories -->
                <ion-col v-for="(types, part) in armor" :key="part" size="auto" class="armor-category">
                    <div v-for="(rarities, type) in types" :key="type" class="armor-type">
                        <div v-for="(items, rarity) in rarities" :key="rarity" class="armor-rarity">
                            <h1 class="text-center">{{ capitalizeAllWords(part) }} - {{ capitalizeAllWords(type) }} - {{
                    capitalizeAllWords(rarity) }}</h1>
                            <div class="armor-images-row">
                                <div v-for="item in items" :key="item" class="armor-image-container">
                                    <img v-fallback-img :src="getImageUrl(item)" alt="Armor Image" class="armor-image" />
                                    <div class="armor-label">{{ item }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</template>

<script>
import armor from '../../../serverless-backend/server/datasets/armorNames.json';
import helperFunctions from '../../../serverless-backend/server/helpers/helperFunctions';

export default {
    data() {
        return {
            armor, // This will hold the imported JSON
            s3BaseUrl: 'https://willoftheimmortals-images.s3.amazonaws.com/armorimages/',
        };
    },
    methods: {
        getImageUrl(itemName) {
            const normalizedItemName = helperFunctions.normalizeForS3(itemName);
            return `${this.s3BaseUrl}${normalizedItemName}.png`;
        },
        capitalizeAllWords(string) {
            return helperFunctions.capitalizeAllWords(string);
        }
    },
};
</script>

<style scoped>
.armor-grid {
    padding: 20px;
}

.armor-category {
    margin-bottom: 60px;
}

.armor-type {
    margin-bottom: 20px;
}

.armor-rarity {
    margin-bottom: 10px;
}

.armor-images-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.armor-image-container {
    text-align: center;
    flex-basis: calc(33.33% - 10px);
    /* 3 images per row, accounting for gap */
    margin-bottom: 20px;
    /* Add some space between rows */
}

.armor-image {
    width: 150px;
    height: 150px;
    min-width: 150px;
    min-height: 150px;
    object-fit: cover;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.armor-label {
    font-size: 14px;
    color: #333;
}
</style>