import axios from 'axios';
import { h } from 'vue';

// Get the API URL from the environment variable
const apiUrl = process.env.VUE_APP_API_URL;

const loadAvailableMaps = async function(user) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        const token = await user.getIdToken(true);

        const response = await axios.get(`${apiUrl}/api/map/list`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        console.error('Failed to load available maps', error);
        throw error;
    }
};

const loadMap = async function(user, mapName) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        const token = await user.getIdToken(true);
        console.log(`Loading map "${mapName}"`);   
        
        const response = await axios.get(`${apiUrl}/api/map/${mapName}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        console.log("MAP LOADED", response.data);
        return response.data;
    } catch (error) {
        console.error(`Failed to load map "${mapName}"`, error);
        throw error;
    }
};

const createMap = async function(user, mapData) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = {
            name: mapData.name,
            type: mapData.type,
            grid: mapData.grid,
            spawnPoint: mapData.spawnPoint,
            numRows: mapData.numRows,
            numCols: mapData.numCols,
            enemyLocations: mapData.enemyLocations,
            resourceLocations: mapData.resourceLocations,
        };

        const response = await axios.post(`${apiUrl}/api/map/create`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        console.error('Failed to create map', error);
        throw error;
    }
};

const saveMap = async function(user, mapData) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        const token = await user.getIdToken(true);

        console.log(`Saving map "${mapData.name}"`);
        console.log(mapData);
        
        const payload = {
            mapData: mapData
        };

        const response = await axios.put(`${apiUrl}/api/map/save`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        console.error('Failed to save map', error);
        throw error;
    }
};

const deleteMap = async function(user, mapName) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        const token = await user.getIdToken(true);

        const response = await axios.delete(`${apiUrl}/api/map/${mapName}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        console.error(`Failed to delete map "${mapName}"`, error);
        throw error;
    }
};

// World Maps

const loadAvailableWorldMaps = async function(user) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        const token = await user.getIdToken(true);
        console.log("Loading available world maps");
        const response = await axios.get(`${apiUrl}/api/worldmap/list`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        console.error('Failed to load available world maps', error);
        throw error;
    }
};

const loadWorldMap = async function(user, mapName) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        const token = await user.getIdToken(true);
        console.log(`Loading world map "${mapName}"`);   
        
        const response = await axios.get(`${apiUrl}/api/worldmap/name/${mapName}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        console.log("World Map Loaded", response.data);
        return response.data;
    } catch (error) {
        console.error(`Failed to load world map "${mapName}"`, error);
        throw error;
    }
};

const createWorldMap = async function(user, mapData) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        const token = await user.getIdToken(true);
        console.log(`Creating world map "${mapData.name}"`);
        const payload = {
            name: mapData.name,
            height: mapData.height,
            width: mapData.width,
            pointsOfInterest: mapData.pointsOfInterest,
            backgroundImageFilename: mapData.backgroundImageFilename,
        };

        const response = await axios.post(`${apiUrl}/api/worldmap/create`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        console.error('Failed to create world map', error);
        throw error;
    }
};

const saveWorldMap = async function(user, mapData) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        const token = await user.getIdToken(true);

        console.log(`Saving world map "${mapData.name}"`);
        console.log(mapData);
        
        const payload = {
            mapData: mapData
        };

        const response = await axios.put(`${apiUrl}/api/worldmap/save`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        console.error('Failed to save world map', error);
        throw error;
    }
};

const deleteWorldMap = async function(user, mapName) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        const token = await user.getIdToken(true);

        const response = await axios.delete(`${apiUrl}/api/worldmap/name/${mapName}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        console.error(`Failed to delete world map "${mapName}"`, error);
        throw error;
    }
};

// Export both map and world map functions
export default {
    loadAvailableMaps,
    loadMap,
    createMap,
    saveMap,
    deleteMap,
    loadAvailableWorldMaps,
    loadWorldMap,
    createWorldMap,
    saveWorldMap,
    deleteWorldMap
};
