<template>
  <!-- <div class="map-selection">
    <select v-model="selectedMap" @change="loadMap">
      <option disabled value="">Select a map</option>
      <option v-for="map in availableMaps" :key="map._id" :value="map">{{ map.name }}</option>
    </select>
  </div> -->
  <MapComponent :mapData="mapData" @change-location="handleLocationChange"/>
</template>

<script>
import { map } from 'lodash';
import MapComponent from '../map/WorldMap.vue';
import mapWorker from '@/workers/mapWorker';

export default {
  components: {
    MapComponent,
  },
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    }
  },
  data() {
    return {
      availableMaps: [],
      selectedMap: undefined,
      mapData: {}
    };
  },
  mounted() {
    this.loadAvailableMaps();
    this.loadCurrentZone();
  },
  methods: {
    async loadAvailableMaps() {
      this.availableMaps = await mapWorker.loadAvailableWorldMaps(this.user);
      console.log(this.availableMaps);
    },
    async loadCurrentZone() {
      this.selectedMap = await mapWorker.loadWorldMap(this.user, this.currentCharacter.zone);
      this.loadMap();
    },
    loadMap() {
      console.log('Loading map')
      console.log(this.selectedMap);
      console.log(this.mapData);
      this.mapData = this.selectedMap;
    },
    async handleLocationChange(location) {
      console.log('Changing location to', location);
      this.selectedMap = await mapWorker.loadWorldMap(this.user, location);
      this.loadMap();
    }
  },
};
</script>

<style scoped>
.map-selection {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.character-ticker {
  display: none;
}
</style>