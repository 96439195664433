import axios from 'axios';

// Get the API URL from the environment variable
const apiUrl = process.env.VUE_APP_API_URL;

const generateRandomEnemy = async function(user, character) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = {
            character: character,
        };

        const response = await axios.post(`${apiUrl}/api/enemy/generate-random-enemy`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let generatedEnemy = response.data;
        console.log(generatedEnemy);
        return generatedEnemy;
    } catch (error) {
        console.error("Failed to generate enemy", error);
    }
};

const generateEnemySet = async function(user, character, level, type, count) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = {
            level: level,
            type: type,
            count: count
        };

        const response = await axios.post(`${apiUrl}/api/enemy/generate-enemy-set`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let generatedEnemySet = response.data;
        console.log(generatedEnemySet);
        return generatedEnemySet;
    } catch (error) {
        console.error("Failed to generate enemy set", error);
    }
};

const generateEnemyByNameAndDifficulty = async function(user, character, name, difficulty) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = { character, name, difficulty };

        const response = await axios.post(`${apiUrl}/api/enemy/generate-enemy-by-name-and-difficulty`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let generatedEnemy = response.data;
        console.log(generatedEnemy);
        return generatedEnemy;
    } catch (error) {
        console.error("Failed to generate enemy by name and difficulty", error);
    }
};

const generateEnemyByTypeAndDifficulty = async function(user, character, type, difficulty) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = { character, type, difficulty };
        console.log("sending request to enemy route");
        console.log(payload);
        const response = await axios.post(`${apiUrl}/api/enemy/generate-enemy-by-type-and-difficulty`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let generatedEnemy = response.data;
        console.log(generatedEnemy);
        return generatedEnemy;
    } catch (error) {
        console.error("Failed to generate enemy by type and difficulty", error);
    }
};

const generateWeaponSet = async function(user, character, level, count) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = {
            level: level,
            count: count
        };

        const response = await axios.post(`${apiUrl}/api/equipment/generate-weapon-set`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let generatedWeaponSet = response.data;
        console.log(generatedWeaponSet);
        return generatedWeaponSet;
    } catch (error) {
        console.error("Failed to generate weapon set", error);
    }
};
const generateRandomWeapon = async function(user, character) {
    try {      
        console.log("GENERATE WEAPON WORKER")
        // Check if user exists
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
        }
        const token = await user.getIdToken(true);

        const payload = {
            character: character,
        };
        console.log(payload)
        const response = await axios.post(`${apiUrl}/api/equipment/generate-random-weapon/`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let generatedWeapon = response.data;
        console.log(generatedWeapon);
        return generatedWeapon;
    } catch (error) {
        console.error("Failed to generate weapon", error);
    }
};

const generateRandomWeaponByRarity = async function(user, character, rarity) {
    try {
        // Check if user exists
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
        }
        const token = await user.getIdToken(true);

        const payload = {
            character: character,
            rarity: rarity
        };
        console.log(payload)
        const response = await axios.post(`${apiUrl}/api/equipment/generate-random-weapon-by-rarity/`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let generatedWeapon = response.data;
        console.log(generatedWeapon);
        return generatedWeapon;
    } catch (error) {
        console.error("Failed to generate weapon", error);
    }
};

const generateWeaponBySubtype = async function(user, character, type, subtype) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = {
            character: character,
            type: type,
            subtype: subtype
        };

        const response = await axios.post(`${apiUrl}/api/equipment/generate-weapon-by-subtype/`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let generatedWeapon = response.data;
        console.log(generatedWeapon);
        return generatedWeapon;
    } catch (error) {
        console.error("Failed to generate specific weapon", error);
    }
};

const generateWeaponByTypeAndRarity = async function(user, character, category, type, rarity) {
    try {
        console.log("GENERATE WEAPON WORKER", category, type, rarity)
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = {
            character: character,
            type: category,
            subtype: type,
            rarity: rarity
        };

        const response = await axios.post(`${apiUrl}/api/equipment/generate-weapon-by-type-and-rarity/`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let generatedWeapon = response.data;
        console.log(generatedWeapon);
        return generatedWeapon;
    } catch (error) {
        console.error("Failed to generate specific weapon", error);
    }
};

const updateWeaponStats = async function(user, weapon) {
    try {
        console.log("UPDATE WEAPON WORKER", weapon)
        // Check if user exists
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = {
            weapon: weapon,
        };

        const response = await axios.post(`${apiUrl}/api/equipment/update-weapon-stats/`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let updatedWeapon = response.data;
        console.log('UPDATED Weapon = ', updatedWeapon);
        return updatedWeapon;
    } catch (error) {
        console.error("Failed to update weapon", error);
    }
};

const generateRandomAttachment = async function(user, character) {
    try {
        // Check if user exists
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
        }
        const token = await user.getIdToken(true);

        const payload = {
            character: character,
        };

        const response = await axios.post(`${apiUrl}/api/equipment/generate-random-attachment/`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let generatedAttachment = response.data;
        console.log(generatedAttachment);
        return generatedAttachment;
    } catch (error) {
        console.error("Failed to generate attachment", error);
    }
};

const generateArmorSet = async function(user, character, level, count) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = {
            level: level,
            count: count
        };

        const response = await axios.post(`${apiUrl}/api/equipment/generate-armor-set`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let generatedArmorSet = response.data;
        console.log(generatedArmorSet);
        return generatedArmorSet;
    } catch (error) {
        console.error("Failed to generate armor set", error);
    }
};

const generateRandomArmor = async function(user, character) {
    try {
        // Check if user exists
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
        }
        const token = await user.getIdToken(true);

        const payload = {
            character: character,
        };

        const response = await axios.post(`${apiUrl}/api/equipment/generate-random-armor/`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let generatedArmor = response.data;
        console.log(generatedArmor);
        return generatedArmor;
    } catch (error) {
        console.error("Failed to generate armor", error);
    }
};

const generateArmorBySlot = async function(user, character, slot) {
    try {
        // Check if user exists
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = {
            character: character,
            slot: slot,
        };

        const response = await axios.post(`${apiUrl}/api/equipment/generate-armor-by-slot/`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let generatedArmor = response.data;
        console.log(generatedArmor);
        return generatedArmor;
    } catch (error) {
        console.error("Failed to generate armor by slot", error);
    }
};

const generateArmorByType = async function(user, character, type) {
    try {
        // Check if user exists
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = {
            character: character,
            type: type,
        };

        const response = await axios.post(`${apiUrl}/api/equipment/generate-armor-by-type/`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let generatedArmor = response.data;
        console.log(generatedArmor);
        return generatedArmor;
    } catch (error) {
        console.error("Failed to generate armor by type", error);
    }
};

const generateArmorBySlotAndType = async function(user, character, slot, type) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = {
            character: character,
            slot: slot,
            type: type
        };
        console.log("sending request to armor route");
        const response = await axios.post(`${apiUrl}/api/equipment/generate-armor-by-slot-and-type/`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let generatedArmor = response.data;
        console.log(generatedArmor);
        return generatedArmor;
    } catch (error) {
        console.error("Failed to generate armor by slot and type", error);
    }
};

const generateArmorBySlotAndMaterial = async function(user, character, slot, type) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = {
            character: character,
            slot: slot,
            type: type
        };
        console.log("sending request to armor route");
        const response = await axios.post(`${apiUrl}/api/equipment/generate-armor-by-slot-and-material/`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let generatedArmor = response.data;
        console.log(generatedArmor);
        return generatedArmor;
    } catch (error) {
        console.error("Failed to generate armor by slot and type", error);
    }
};

const generateRandomConsumable = async function(user, character) {
    try {
        // Check if user exists
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
        }
        const token = await user.getIdToken(true);

        const payload = {
            character: character,
        };

        const response = await axios.post(`${apiUrl}/api/equipment/generate-random-consumable/`, payload, {
        headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let consumableInfo = response.data;
        return consumableInfo;
    } catch (error) {
        console.error("Failed to generate consumable", error);
    }    
};

const generateConsumableByType = async function(user, character, type) {
    try {
        // Check if user exists
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = {
            character: character,
            type: type,
        };

        const response = await axios.post(`${apiUrl}/api/equipment/generate-consumable-by-type/`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let generatedConsumable = response.data;
        console.log(generatedConsumable);
        return generatedConsumable;
    } catch (error) {
        console.error("Failed to generate consumable by type", error);
    }
};

const generateMedicinalConsumableByName = async function(user, character, name) {
    try {
        // Check if user exists
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = {
            character: character,
            name: name,
        };

        const response = await axios.post(`${apiUrl}/api/equipment/generate-medicinal-consumable-by-name/`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let generatedConsumable = response.data;
        console.log(generatedConsumable);
        return generatedConsumable;
    } catch (error) {
        console.error("Failed to generate consumable by name", error);
    }
};

const generateRandomShop = async function(user, character) {
    try {
        // Check if user exists
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = {
            character: character,
        };
        console.log("sending request to shop route")
        const response = await axios.post(`${apiUrl}/api/shop/generate-random-shop/`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let shopInfo = response.data;
        return shopInfo;
    } catch (error) {
        console.error("Failed to generate shop", error);
    }    
};

const generateSpecificShop = async function(user, character, type) {
    try {
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = {
            character: character,
            type: type
        };
        const response = await axios.post(`${apiUrl}/api/shop/generate-specific-shop/`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let shopInfo = response.data;
        return shopInfo;
    } catch (error) {
        console.error("Failed to generate shop", error);
    }
};

const generateRandomBaseResource = async function(user, character, location) {
    console.log("generatorWorker generateBaseResource")
    try {
        // Check if user exists
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = {
            location: location,
        };
        console.log("sending request to resource route")
        const response = await axios.post(`${apiUrl}/api/resources/generate-random-base-resource/`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let baseResource = response;
        return baseResource;
    } catch (error) {
        console.error("Failed to generate base resource", error);
    }
};

const generateBaseResourceByType = async function(user, character, type) {
    console.log("generatorWorker generateBaseResourceByType")
    try {
        // Check if user exists
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = {
            type
        };
        console.log("sending request to resource route")
        const response = await axios.post(`${apiUrl}/api/resources/generate-base-resource-by-type/`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let baseResource = response.data;
        return baseResource;
    } catch (error) {
        console.error("Failed to generate base resource by type", error);
    }
};

const generateBaseResourceByName = async function(user, character, name) {
    console.log("generatorWorker generateBaseResourceByName")
    try {
        // Check if user exists
        if (!user) {
            console.error("No user is currently signed in");
            return;
        }
        if (!character) {
            console.error("No character data loaded");
            return;
        }
        const token = await user.getIdToken(true);

        const payload = {
            name
        };
        console.log("sending request to resource route")
        const response = await axios.post(`${apiUrl}/api/resources/generate-base-resource-by-name/`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        let baseResource = response.data;
        return baseResource;
    } catch (error) {
        console.error("Failed to generate base resource by name", error);
    }
}

export default {
    generateRandomEnemy,
    generateEnemyByNameAndDifficulty,
    generateEnemyByTypeAndDifficulty,
    generateEnemySet,
    generateWeaponSet,
    generateRandomWeapon,
    generateWeaponBySubtype,
    generateWeaponByTypeAndRarity,
    generateRandomWeaponByRarity,
    updateWeaponStats,
    generateRandomAttachment,
    generateArmorSet,
    generateRandomArmor,
    generateArmorBySlot,
    generateArmorByType,
    generateArmorBySlotAndType,
    generateArmorBySlotAndMaterial,
    generateRandomConsumable,
    generateConsumableByType,
    generateMedicinalConsumableByName,
    generateRandomShop,
    generateSpecificShop,
    generateRandomBaseResource,
    generateBaseResourceByType,
    generateBaseResourceByName
};
