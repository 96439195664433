<template>
  <div class="weapon-container" v-if="weapon">
    <ion-card class="weapon-stats">
      <ion-card-header>
        <ion-card-title>{{ weapon.name }} (Level {{ weapon.level }})</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <p>Type: {{ weapon.type }}</p>
        <p>Rarity: {{ weapon.rarity?.name }} ({{ weapon.rarity?.color }})</p>
        <p>Damage: {{ weapon.damage }}</p>
        <p>Accuracy: {{ weapon.accuracy }}</p>
        <p>Critical Chance: {{ weapon.criticalChance }}%</p>
        <p>Rate of Fire: {{ weapon.rateOfFire }}</p>
        <p>Weight: {{ weapon.weight }}</p>
        <p>Maintenance: {{ weapon.maintainence }}</p>
        <p>Effective Range: {{ weapon.effectiveRange }}</p>
        <p>Value: {{ weapon.value }}</p>
      </ion-card-content>
    </ion-card>

    <ion-grid class="attachment-slots">
      <ion-row>
        <ion-col v-for="slot in attachmentSlots" :key="slot" size="6">
          <ion-card class="display-card" @click="showAttachments(slot)">
            <ion-card-header>
              <ion-card-title>{{ slot }}</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <div v-if="weapon.attachments && weapon.attachments[slot.toLowerCase().replace('-', '')]">
                <p>{{ weapon.attachments[slot.toLowerCase().replace('-', '')]?.name }}</p>
                <span class="subtext" v-if="weapon.attachments[slot.toLowerCase().replace('-', '')]?.modifier">
                  {{ weapon.attachments[slot.toLowerCase().replace('-', '')]?.modifier }}: {{
    weapon.attachments[slot.toLowerCase().replace('-', '')]?.amount }}
                </span>
                <ion-button class="small-button"
                  v-if="Object.keys(weapon.attachments[slot.toLowerCase().replace('-', '')]).length"
                  @click.stop="removeAttachment(slot)">X</ion-button>
              </div>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-modal v-if="showingAttachments" @did-dismiss="showingAttachments = false">
      <ion-header>
        <ion-toolbar>
          <ion-title>Select Attachment for {{ selectedSlot }}</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="showingAttachments = false">Close</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <div>
        <ion-list>
          <ion-item v-for="attachment in getAttachmentsForSlot(selectedSlot)" :key="attachment.id"
            @click="attachItem(attachment, selectedSlot)" class="attachment display-card">
            {{ attachment.name }}
            <span class="subtext" v-if="attachment.modifier">
              {{ attachment.modifier }}: {{ attachment.amount }}
            </span>
          </ion-item>
        </ion-list>
      </div>
    </ion-modal>
  </div>
</template>

<script>
import inventoryWorker from '../../workers/inventoryWorker.js';
import generationWorker from '../../workers/generationWorker.js';

export default {
  data() {
    return {
      weapon: null,
      showingAttachments: false,
      selectedSlot: '',
    };
  },
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
    attachmentSlots() {
      if (this.weapon) {
        if (this.weapon.type === 'Ranged') {
          return ['Optic', 'Muzzle', 'Stock', 'Grip', 'Barrel', 'Internal'];
        } else if (this.weapon.type === 'Melee') {
          return ['Striking-surface', 'Handle', 'Modifiers'];
        } else if (this.weapon.type === 'Explosive') {
          return ['Fuse', 'ExplosiveMaterial', 'Detonator', 'Casing', 'Payload'];
        }
      }
      return [];
    },
  },
  watch: {
    'currentCharacter.equipped': {
      handler(newVal) {
        const equippedWeapon = newVal.find(item => item.slot === 'weapon');
        if (equippedWeapon) {
          this.weapon = equippedWeapon.item;
        } else {
          this.weapon = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.loadEquippedWeapon();
  },
  methods: {
    loadEquippedWeapon() {
      if (this.currentCharacter && this.currentCharacter.equipped) {
        const equippedWeapon = this.currentCharacter.equipped.find(item => item.slot === 'weapon');
        if (equippedWeapon) {
          this.weapon = equippedWeapon.item;
        } else {
          console.warn('No equipped weapon found');
        }
      } else {
        console.warn('Character or equipped items not available');
      }
    },
    showAttachments(slot) {
      this.selectedSlot = slot;
      this.showingAttachments = true;
    },
    getAttachmentsForSlot(slot) {
      if (this.currentCharacter && this.currentCharacter.inventory) {
        const slotType = slot.toLowerCase().replace('-', '');
        return this.currentCharacter.inventory.filter(
          (item) => item.type && item.type.toLowerCase() === slotType
        );
      }
      return [];
    },
    async attachItem(attachment, slot) {
      console.log('ATTACHMENT', attachment);
      const weapon = this.weapon;
      const slotKey = slot.toLowerCase().replace('-', '');
      if (weapon.attachments && weapon.attachments[slotKey] && weapon.attachments[slotKey].name) {
        const oldAttachment = weapon.attachments[slotKey];
        await inventoryWorker.addToInventory(this.$store, this.user, this.currentCharacter, oldAttachment);
      }
      weapon.attachments[slotKey] = attachment;
      await inventoryWorker.removeFromInventory(this.$store, this.user, this.currentCharacter, attachment);

      // Ensure updateWeaponStats returns the updated weapon
      const updatedWeapon = await generationWorker.updateWeaponStats(this.user, weapon);

      console.log('UPDATED WEAPON IN TEST', updatedWeapon);

      if (updatedWeapon) {
        await inventoryWorker.equipItem(this.$store, this.user, this.currentCharacter, updatedWeapon);

        // Remove the duplicated weapon from the player's inventory
        const weaponInInventory = this.currentCharacter.inventory.find(item => item.id === weapon.id);
        if (weaponInInventory) {
          await inventoryWorker.removeFromInventory(this.$store, this.user, this.currentCharacter, weaponInInventory);
        }
      } else {
        console.error('Failed to update weapon stats');
      }

      this.showingAttachments = false;
    },
    async removeAttachment(slot) {
      let weapon = this.weapon;
      const slotKey = slot.toLowerCase().replace('-', '');
      const attachment = weapon.attachments[slotKey];
      if (attachment) {
        await inventoryWorker.addToInventory(this.$store, this.user, this.currentCharacter, attachment);
        weapon.attachments[slotKey] = {};
        const updatedWeapon = await generationWorker.updateWeaponStats(this.user, weapon);

        inventoryWorker.equipItem(this.$store, this.user, this.currentCharacter, updatedWeapon);

        // Remove the duplicated weapon from the player's inventory
        const weaponInInventory = this.currentCharacter.inventory.find(item => item.id === updatedWeapon.id);
        if (weaponInInventory) {
          inventoryWorker.removeFromInventory(this.$store, this.user, this.currentCharacter, weaponInInventory);
        }
      }
    }
  }
};
</script>

<style>
.weapon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.weapon-stats {
  margin-bottom: 20px;
}

.attachment {
  cursor: pointer;
}

.attachment-slots {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.attachment-slot {
  max-width: 100px;
  max-height: 100px;
  min-width: 100px;
  min-height: 100px;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
}

.attachment-list {
  margin-top: 20px;
}
</style>