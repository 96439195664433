<template>
  <div class="playing-field">
    <div class="container">
      <div class="combat-scene" @mousedown="startGauge" @mouseup="stopGauge" @mouseleave="stopGauge"
        @touchstart="startGauge" @touchend="stopGauge" :style="{ backgroundImage: `url(${enemyPortraitUrl})` }">
        <div class="circle" v-if="showGauge" :style="outerCircleStyle"></div>
        <div class="circle expanding-circle" v-if="showGauge" :style="innerCircleStyle"></div>
        <div class="health-stripe" :style="healthStripeStyle"></div>
        <div class="notification-area">
          <ion-text v-if="errorMessage" class="error-banner">{{ errorMessage }}</ion-text>
          <ion-text v-if="notificationMessage" class="notification-banner">
            {{ notificationMessage }}
          </ion-text>
          <div class="notification-banner" v-if="endCombatMessage">
            <p>
              You defeated the enemy and gained {{ this.generatedEnemy.qiValue }} Qi,
              <CurrencyDisplay :value="this.generatedEnemy.value" currency="mortal" />
              <span v-if="this.generatedEnemy.immortalValue">, and
                <CurrencyDisplay :value="this.generatedEnemy.immortalValue" currency="immortal" />
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="ticker" v-if="generatedEnemy">
        <div>Enemy: <span :style="{ color: generatedEnemy.dangerLevel.color }">{{ generatedEnemy.name }}</span></div>
        <div>Level: {{ generatedEnemy.level }}</div>
        <div>Health: {{ generatedEnemy.health }} / {{ generatedEnemy.maxHealth }}</div>
      </div>
    </div>
    <p class="selected-attack">Selected Attack: {{ selectedAttack }}</p>
    <CombatCards class="combat-buttons" @card-clicked="handleCardClick" />
    <ion-button expand="block" @click="healCharacter(100)">Heal Character</ion-button>
    <ion-button expand="block" @click="fillStamina">Fill Stamina</ion-button>
    <ion-button expand="block" @click="fillVitalEnergy">Fill Vital Energy</ion-button>
  </div>
</template>

<script>
import CombatCards from '../combat/CombatCards.vue';
import CurrencyDisplay from '../helpers/CurrencyDisplay.vue';

import generationWorker from '../../workers/generationWorker.js';
import characterWorker from '../../workers/characterWorker.js';
import combatWorker from '../../workers/combatWorker.js';

import helperFunctions from '../../../serverless-backend/server/helpers/helperFunctions.js';

export default {
  components: {
    CombatCards,
    CurrencyDisplay
  },
  props: {
    enemy: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedAttack: "Weapon Attack",
      generatedEnemy: undefined,
      isMeleeAttack: false,
      errorMessage: '',
      notificationMessage: '',
      endCombatMessage: false,
      errorTimeout: null,
      notificationTimeout: null,
      enemyDefeated: false,
      enemyHit: false,
      showGauge: false,
      gaugeCenter: { x: 0, y: 0 },
      gaugeInterval: null,
      expandingCircleRadius: 0,
      circleMaxRadius: 100,
    };
  },
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
    healthStripeStyle() {
      if (!this.generatedEnemy) return {};
      const healthPercent = (this.generatedEnemy.health / this.generatedEnemy.maxHealth) * 100;
      return {
        height: `${healthPercent}%`,
        backgroundColor: healthPercent > 0 ? 'red' : 'white',
        borderBottom: healthPercent > 0 ? 'none' : '1px solid black',
      };
    },
    enemyPortraitUrl() {
      if (!this.generatedEnemy) return '';
      const enemyName = helperFunctions.normalizeForS3(this.generatedEnemy.name);
      return `https://willoftheimmortals-images.s3.amazonaws.com/enemyportaits/${enemyName}.png`;
    },
    outerCircleStyle() {
      return {
        width: '200px',
        height: '200px',
        borderWidth: '4px',
        position: 'absolute',
        borderRadius: '50%',
        borderColor: 'rgba(255, 255, 255, 0.5)',
        borderStyle: 'solid',
        left: `${this.gaugeCenter.x - 100}px`,
        top: `${this.gaugeCenter.y - 100}px`,
      };
    },
    innerCircleStyle() {
      return {
        width: `${this.expandingCircleRadius * 2}px`,
        height: `${this.expandingCircleRadius * 2}px`,
        borderWidth: '4px',
        position: 'absolute',
        borderRadius: '50%',
        borderColor: 'rgba(255, 255, 255, 0.8)',
        borderStyle: 'solid',
        left: `${this.gaugeCenter.x - this.expandingCircleRadius}px`,
        top: `${this.gaugeCenter.y - this.expandingCircleRadius}px`,
      };
    }
  },
  methods: {
    handleCardClick(cardName) {
      this.selectedAttack = cardName;
      this.showNotification(`Selected attack: ${cardName}`);
    },
    async performCriticalHit() {
      await this.executeAttack(1.5, true);
    },
    async performGoodHit() {
      await this.executeAttack(1.0);
    },
    async performMiss() {
      this.showNotification('You missed the attack!');
    },
    async executeAttack(multiplier, isCritical = false) {
      console.log('Executing attack with multiplier', multiplier);
      const skill = this.currentCharacter.skills.find(skill => skill.name === this.selectedAttack);
      let damage = 0;

      if (skill) {
        const energyType = skill.type.includes('Martial') ? 'stamina' : 'vitalEnergy';
        const currentEnergy = this.currentCharacter[energyType];
        const energyCost = skill.effect.energyCost;

        if (currentEnergy < energyCost) {
          this.showError(`Not enough ${energyType}!`);
          return;
        }
        if (energyType === 'stamina') {
          await characterWorker.modifyCharacterStamina(this.$store, this.$store.getters.getCurrentUser, this.currentCharacter, -energyCost);
        } else if (energyType === 'vitalEnergy') {
          await characterWorker.modifyCharacterVitalEnergy(this.$store, this.$store.getters.getCurrentUser, this.currentCharacter, -energyCost);
        }
       
        console.log("skill.effect.damage", skill.effect.damage);
        damage = skill.effect.damage * multiplier;
      } else if (this.selectedAttack === 'Weapon Attack') {
        const weapon = this.currentCharacter.equipped.find(item => item.slot === 'weapon').item;
        damage = weapon.damage * multiplier;
      } else if (this.selectedAttack === 'Defend') {
        this.showNotification('You defended against the enemy attack!');
        return;
      } else if (this.selectedAttack === 'Retreat') {
        this.showNotification('You retreated from the battle!');
        return;
      }
      console.log('Damage:', damage);
      const attackDetails = combatWorker.attack(this.currentCharacter, this.generatedEnemy, damage);

      if (attackDetails.miss) {
        this.showNotification('Your attack missed!');
      } else {
        const message = `You dealt ${attackDetails.totalDamage} damage to the enemy!`;
        this.showNotification(isCritical ? message + ' It was a critical hit!' : message);
        await this.attackEnemy(attackDetails.totalDamage, attackDetails.kill);
      }
    },
    async attackEnemy(damage, kill = false) {
      if (!this.generatedEnemy) {
        console.error("No enemy to attack");
        return;
      }

      try {
        let updatedCharacter;
        if (kill) {
          updatedCharacter = await characterWorker.modifyCharacterQi(this.$store, this.$store.getters.getCurrentUser, this.currentCharacter, this.generatedEnemy.qiValue);
          updatedCharacter = await characterWorker.modifyCharacterMortalCurrency(this.$store, this.$store.getters.getCurrentUser, updatedCharacter, this.generatedEnemy.value);
          updatedCharacter = await characterWorker.modifyCharacterImmortalCurrency(this.$store, this.$store.getters.getCurrentUser, updatedCharacter, this.generatedEnemy.immortalValue);
          await characterWorker.updateCharacter(this.$store, this.$store.getters.getCurrentUser, updatedCharacter, { type: "killEnemy" });
          this.showEndCombatMessage();
          this.endCombat();
          this.enemyDefeated = true;
          return;
        } else {
          this.generatedEnemy.health -= damage;
          updatedCharacter = await characterWorker.updateCharacter(this.$store, this.$store.getters.getCurrentUser, this.currentCharacter);
          this.enemyHit = true;
          setTimeout(() => {
            this.enemyHit = false;
            this.enemyAttack();
          }, 1000);
        }
      } catch (error) {
        console.error("Failed to attack enemy", error);
      }
    },
    showError(message) {
      this.errorMessage = message;
      clearTimeout(this.errorTimeout);
      this.errorTimeout = setTimeout(() => {
        this.errorMessage = '';
      }, 2000);
    },
    showNotification(message) {
      this.notificationMessage = message;
      clearTimeout(this.notificationTimeout);
      this.notificationTimeout = setTimeout(() => {
        this.notificationMessage = '';
      }, 2000);
    },
    showEndCombatMessage() {
      clearTimeout(this.notificationTimeout);
      clearTimeout(this.errorTimeout);
      this.endCombatMessage = true;
    },
    async enemyAttack() {
      if (!this.generatedEnemy) {
        console.error("No enemy here");
        return;
      }
      try {
        const result = combatWorker.attack(this.generatedEnemy, this.currentCharacter);
        if (result.miss) {
          this.showNotification('The enemy attack missed!');
          return;
        }
        if (result.kill) {
          this.showNotification('You were defeated by the enemy!');
        } else {
          await characterWorker.modifyCharacterHealth(this.$store, this.$store.getters.getCurrentUser, this.currentCharacter, -result.totalDamage);
          await characterWorker.updateCharacter(this.$store, this.$store.getters.getCurrentUser, this.currentCharacter);
          this.showNotification(`The enemy dealt ${result.totalDamage} damage to you!`);
        }
      } catch (error) {
        console.error("Failed to attack enemy", error);
      }
    },
    async healCharacter(percentage) {
      let amount = Math.round(this.currentCharacter.maxHealth * (percentage / 100));
      let updatedCharacter = await characterWorker.modifyCharacterHealth(this.$store, this.$store.getters.getCurrentUser, this.currentCharacter, amount);
      await characterWorker.updateCharacter(this.$store, this.$store.getters.getCurrentUser, updatedCharacter);
      this.showNotification(`You healed for ${amount} health!`);
    },
    async fillStamina() {
      try {
        await characterWorker.fillCharacterStamina(this.$store, this.$store.getters.getCurrentUser, this.currentCharacter);
        this.showNotification('Stamina filled to full!');
      } catch (error) {
        console.error('Failed to fill stamina', error);
      }
    },
    async fillVitalEnergy() {
      try {
        await characterWorker.fillCharacterVitalEnergy(this.$store, this.$store.getters.getCurrentUser, this.currentCharacter);
        this.showNotification('Vital Energy filled to full!');
      } catch (error) {
        console.error('Failed to fill vital energy', error);
      }
    },
    endCombat() {
      setTimeout(() => {
        console.log('Closing combat scene');
        this.$emit('close');
      }, 2000);
    },
    async generateRandomEnemy() {
      this.generatedEnemy = undefined;
      let enemy = await generationWorker.generateRandomEnemy(this.$store.getters.getCurrentUser, this.currentCharacter);
      this.generatedEnemy = enemy;
    },
    startGauge(event) {
      const boundingRect = event.currentTarget.getBoundingClientRect();
      const clickX = (event.clientX || event.touches[0].clientX) - boundingRect.left;
      const clickY = (event.clientY || event.touches[0].clientY) - boundingRect.top;

      this.gaugeCenter = {
        x: clickX,
        y: clickY
      };
      this.showGauge = true;
      this.gaugeStartTime = Date.now();
      this.expandingCircleRadius = 0;
      this.gaugeInterval = setInterval(this.updateGauge, 16); // Roughly 60fps
    },
    stopGauge() {
      if (!this.showGauge) return;

      clearInterval(this.gaugeInterval);
      this.showGauge = false;

      const overlap = this.expandingCircleRadius >= 90 && this.expandingCircleRadius <= 110;

      if (overlap) {
        this.performCriticalHit();
      } else if (this.expandingCircleRadius < 90) {
        this.performGoodHit();
      } else {
        this.performMiss();
      }
    },
    updateGauge() {
      const elapsedTime = Date.now() - this.gaugeStartTime;
      const cycleDuration = 1000; // Time in milliseconds for a full cycle
      const cycleFraction = (elapsedTime % cycleDuration) / cycleDuration;

      // Adjust the radius to expand fully, snap back to zero, and expand again
      this.expandingCircleRadius = this.circleMaxRadius * Math.abs(Math.sin(cycleFraction * Math.PI));
    }
  },
  mounted() {
    if (this.enemy) {
      this.generatedEnemy = this.enemy;
    } else {
      this.generateRandomEnemy();
    }
    console.log('Enemy should be generated and ticker should show');
  },
  beforeDestroy() {
    clearInterval(this.gaugeInterval);
    clearTimeout(this.errorTimeout);
    clearTimeout(this.notificationTimeout);
  }
};
</script>

<style scoped>
.playing-field {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.combat-scene {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 500px;
  height: 500px;
  border: 1px solid black;
  margin-bottom: 20px;
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

@media (max-width: 500px) {
  .combat-scene {
    width: 100%;
    height: 100%;
    min-width: 350px;
    min-height: 350px;
  }
}

.health-stripe {
  width: 2%;
  height: 100%;
  background-color: red;
  border: 1px solid black;
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
}

.health-stripe>div {
  width: 100%;
}

.circle {
  pointer-events: none;
}

.expanding-circle {
  transition: width 0.1s ease, height 0.1s ease, left 0.1s ease, top 0.1s ease;
}

.error-banner,
.notification-banner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 10;
  font-size: 1.2em;
  text-align: center;
}

.notification-banner {
  background-color: rgba(0, 128, 0, 0.8);
}

.notification-area {
  position: absolute;
  width: 100%;
  top: 200px;
  z-index: 30;
}

.ticker {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 20px;
  font-size: 16px;
  background-color: darkgray;
  color: black;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  margin-top: 10px;
}

.combat-scene,
.gauge-container,
.gauge-bar {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
</style>