exports.calculateArmorDamageReduction = (defender) => {
    let armorValue = defender.armorValue;

    let levelReduction = Math.floor(defender.level / 3);    

    if (levelReduction < 1) {
        levelReduction = 1;
    }
    
    let damageReduction = armorValue / levelReduction;

    let totalReduction = damageReduction;

    return totalReduction;
}