import { createStore } from 'vuex';

export default createStore({
  state: {
    currentUser: null,
    currentCharacter: null,
  },
  getters: {
    getCurrentUser: state => state.currentUser,
    getCurrentCharacter: state => state.currentCharacter,
  },
  mutations: {
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
    setCurrentCharacter(state, character) {
      console.log("SETTING CHARACTER", character);
      state.currentCharacter = character;
    },
  },
  actions: {
    setCurrentUser({ commit }, user) {
      commit('setCurrentUser', user);
    },
  },
  modules: {
    
  }
});