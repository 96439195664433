<template>
    <ButtonStack :horizontal="true" class="top-nav ion-grid">
        <ion-row>
            <ion-col size="12">
                <QJButton @click="$router.push('/auth')">Log In</QJButton>
            </ion-col>
            <ion-col size="12">
                <QJButton @click="$router.push(`/characters/${user.id}`)">Character List</QJButton>
            </ion-col>
            <ion-col size="12">
                <QJButton @click="$router.push(`/character/${currentCharacter._id}`)">Character Info</QJButton>
            </ion-col>
            <ion-col size="12">
                <QJButton @click="$router.push('/character-inventory/')">Character Inventory</QJButton>
            </ion-col>
            <ion-col size="12">
                <QJButton @click="$router.push('/world-map-test/')">Enter World</QJButton>
            </ion-col>
            <ion-col size="12">
                <QJButton @click="$router.push('/test-bed/')">Tests (Here be Bug-dragons)</QJButton>
            </ion-col>
        </ion-row>
    </ButtonStack>
    <!-- <CharacterTicker class="ticker" /> -->
</template>


<script>
import ButtonStack from '../framework/ButtonStack.vue';
import QJButton from '../framework/QJButton.vue';

import CharacterTicker from '../character/CharacterTicker.vue';

export default {
    components: {
        ButtonStack,
        QJButton,
        CharacterTicker
    },
    name: 'TopNav',
    computed: {
        currentCharacter() {
            return this.$store.getters.getCurrentCharacter;
        },
        user() {
            return this.$store.getters.getCurrentUser;
        }
    }
}
</script>

<style scoped>
.top-nav {
    width: 100%;
    margin-bottom: 10px;
    padding: 16px;
}

.ticker {
    margin-bottom: 10px;
}
</style>