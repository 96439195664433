<template>
  <Teleport to="body">
    <div class="large-map-generator">
      <div class="large-map-config">
        <ion-item>
          <ion-label>Map Name: </ion-label>
          <ion-input v-model.text="largeMapData.name" placeholder="Enter map name"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>Map Type: </ion-label>
          <ion-input v-model.text="largeMapData.type" placeholder="Enter map type"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>Map Width: </ion-label>
          <ion-input v-model.number="largeMapData.width" type="number"
            placeholder="Enter map width in pixels"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>Map Height: </ion-label>
          <ion-input v-model.number="largeMapData.height" type="number"
            placeholder="Enter map height in pixels"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>Background Image Filename:</ion-label>
          <ion-input v-model="largeMapData.backgroundImageFilename" placeholder="Enter background image filename"></ion-input>
        </ion-item>
        <ion-button @click="generateMap">Generate Map</ion-button>
      </div>

      <div v-if="isMapGenerated" class="map-wrapper">
        <div class="large-map-container" ref="mapContainer" @click="handleMapClick"
          :style="{ width: largeMapData.width + 'px', height: largeMapData.height + 'px', backgroundImage: getBackgroundImage() }">
          <div v-for="poi in largeMapData.pointsOfInterest" :key="poi.name" :style="getPOIStyle(poi)">
            <div class="poi-label">{{ poi.name }}</div>
            <img :src="poi.image" :alt="poi.name" class="poi-image" />
          </div>
        </div>
      </div>

      <ion-modal :is-open="showPOIModal" @will-dismiss="resetPOIModal">
        <ion-header>
          <ion-toolbar>
            <ion-title>Set POI Details</ion-title>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-item>
            <ion-label>POI Name:</ion-label>
            <ion-input v-model="currentPOI.name" placeholder="Enter POI name"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>POI Type:</ion-label>
            <ion-select v-model="currentPOI.type" placeholder="Select POI type">
              <ion-select-option value="town">Town</ion-select-option>
              <ion-select-option value="forest">Forest</ion-select-option>
              <ion-select-option value="mountains">Mountains</ion-select-option>
              <ion-select-option value="poi">POI</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label>POI Image:</ion-label>
            <ion-select v-model="currentPOI.image" placeholder="Select POI image">
              <ion-select-option v-for="image in poiImages" :key="image" :value="image">
                {{ image.split('/').pop() }} <!-- Display only the image filename -->
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label>POI Action:</ion-label>
            <ion-input v-model="currentPOI.action" placeholder="Enter POI action"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>POI Connections:</ion-label>
            <ion-input v-model="currentPOI.connections" placeholder="Enter POI connections"></ion-input>
          </ion-item>
          <ion-button expand="block" @click="savePOI">Save POI</ion-button>
        </ion-content>
      </ion-modal>

      <ion-button v-if="isMapGenerated" @click="finishMap">Finish and Generate JSON</ion-button>

      <ion-footer>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="saveMap">Save Map</ion-button>
            <ion-button @click="loadAvailableWorldMaps">Load Available Maps</ion-button>
            <ion-button @click="deleteMap" color="danger" v-if="!isNewMap">Delete Map</ion-button>
          </ion-buttons>
          <ion-item slot="end">
            <ion-label>Available Maps</ion-label>
            <ion-select v-model="selectedMap" @ionChange="loadMap">
              <ion-select-option v-for="map in availableMaps" :key="map._id" :value="map">{{ map.name }}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-toolbar>
      </ion-footer>

      <!-- JSON Output for Testing -->
      <div class="json-output">
        <textarea v-model="jsonOutput" rows="10" cols="50" readonly></textarea>
      </div>
    </div>
  </Teleport>
</template>

<script>
import mapWorker from '../../workers/mapWorker';

export default {
  data() {
    return {
      isMapGenerated: false,
      largeMapData: {
        name: '',
        width: 3000,
        height: 3000,
        type: '',
        pointsOfInterest: [],
        backgroundImageFilename: 'default-background'
      },
      availableMaps: [],
      selectedMap: null,
      showPOIModal: false,
      currentPOI: {
        name: '',
        type: '',
        image: '',
        action: '',
        connections: '',
        x: 0,
        y: 0
      },
      poiImages: [],
      jsonOutput: '',
      isNewMap: true
    };
  },
  watch: {
    largeMapData: {
      handler() {
        this.jsonOutput = JSON.stringify(this.largeMapData, null, 2);
      },
      deep: true,
    },
  },
  methods: {
    async loadPOIImages() {
      try {
        this.poiImages = [
          '/assets/images/pois/alchemistpoi.png',
          '/assets/images/pois/armorshoppoi.png',
          '/assets/images/pois/blacksmithpoi.png',
          '/assets/images/pois/cavepoi.png',
          '/assets/images/pois/cavepoi2.png',
          '/assets/images/pois/city-poi.png',
          '/assets/images/pois/combatpoi.png',
          '/assets/images/pois/forestpoi2.png',
          '/assets/images/pois/forestpoi3.png',
          '/assets/images/pois/forestpoi4.png',
          '/assets/images/pois/forestpoiround.png',
          '/assets/images/pois/generalstorepoi.png',
          '/assets/images/pois/groceryshoppoi.png',
          '/assets/images/pois/housepoi.png',
          '/assets/images/pois/librarypoi.png',
          '/assets/images/pois/meadowpoi.png',
          '/assets/images/pois/mountainPOI.png',
          '/assets/images/pois/mountainpoi2.png',
          '/assets/images/pois/mountainpoiround.png',
          '/assets/images/pois/mountainpoiround2.png',
          '/assets/images/pois/restaurantpoi.png',
          '/assets/images/pois/sect-poi.png',
          '/assets/images/pois/shrinepoi.png',
          '/assets/images/pois/shrinepoi2.png',
          '/assets/images/pois/stonepoi.png',
          '/assets/images/pois/townhallpoi.png',
          '/assets/images/pois/townpoi.png',
          '/assets/images/pois/townpoi2.png',
          '/assets/images/pois/waterfallpoi.png',
          '/assets/images/pois/waterfallpoi2.png',
          '/assets/images/pois/weaponshoppoi.png'
        ];
      } catch (error) {
        console.error('Error loading POI images:', error);
      }
    },
    generateMap() {
      if (this.largeMapData.width > 0 && this.largeMapData.height > 0) {
        this.isMapGenerated = true;
      } else {
        alert('Please enter valid dimensions for the map.');
      }
    },
    handleMapClick(event) {
      const rect = this.$refs.mapContainer.getBoundingClientRect();
      const x = event.clientX - rect.left + this.$refs.mapContainer.scrollLeft;
      const y = event.clientY - rect.top + this.$refs.mapContainer.scrollTop;

      this.currentPOI.x = x;
      this.currentPOI.y = y;
      this.showPOIModal = true;
    },
    savePOI() {
      this.largeMapData.pointsOfInterest.push({ ...this.currentPOI });
      this.resetPOIModal();
    },
    resetPOIModal() {
      this.showPOIModal = false;
      this.currentPOI = {
        name: '',
        type: '',
        image: '',
        x: 0,
        y: 0
      };
    },
    getPOIStyle(poi) {
      return {
        position: 'absolute',
        left: `${poi.x}px`,
        top: `${poi.y}px`,
        width: '100px',
        height: '100px',
      };
    },
    getBackgroundImage() {
      const filename = this.largeMapData.backgroundImageFilename || 'default-map';
      return `url(https://willoftheimmortals-images.s3.amazonaws.com/mapimages/${filename}.png)`;
    },
    finishMap() {
      alert('Map generation complete! JSON output is ready.');
    },
    async saveMap() {
      try {
        const user = this.$store.getters.getCurrentUser;
        if (!user) throw new Error('User is not authenticated');

        if (this.isNewMap) {
          await mapWorker.createWorldMap(user, this.largeMapData);
        } else {
          await mapWorker.saveWorldMap(user, this.largeMapData);
        }

        alert('Map saved successfully!');
        this.isNewMap = false;
        this.loadAvailableWorldMaps();
      } catch (error) {
        console.error('Error saving map:', error);
      }
    },
    async loadAvailableWorldMaps() {
      try {
        const user = this.$store.getters.getCurrentUser;
        if (!user) throw new Error('User is not authenticated');

        this.availableMaps = await mapWorker.loadAvailableWorldMaps(user);
      } catch (error) {
        console.error('Error loading available maps:', error);
      }
    },
    async loadMap() {
      if (!this.selectedMap) return;

      try {
        const user = this.$store.getters.getCurrentUser;
        if (!user) throw new Error('User is not authenticated');

        this.largeMapData = await mapWorker.loadWorldMap(user, this.selectedMap.name);
        this.generateMap();
        this.isNewMap = false;
      } catch (error) {
        console.error(`Error loading map "${this.selectedMap.name}":`, error);
        this.isNewMap = true;
      }
    },
    async deleteMap() {
      if (!this.selectedMap) return;

      if (confirm(`Are you sure you want to delete the map "${this.selectedMap.name}"?`)) {
        try {
          const user = this.$store.getters.getCurrentUser;
          if (!user) throw new Error('User is not authenticated');

          await mapWorker.deleteWorldMap(user, this.selectedMap.name);

          this.largeMapData = {
            name: '',
            width: 3000,
            height: 3000,
            pointsOfInterest: [],
            backgroundImageFilename: 'default-map'
          };
          this.isNewMap = true;
          this.loadAvailableWorldMaps();
        } catch (error) {
          console.error(`Error deleting map "${this.selectedMap.name}":`, error);
        }
      }
    },
  },
  mounted() {
    this.loadAvailableWorldMaps();
    this.loadPOIImages(); // Load POI images on component mount
  }
};
</script>

<style scoped>
.large-map-generator {
  position: absolute;
  top: 0;
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.large-map-config {
  margin-bottom: 20px;
}

.poi-label {
  position: absolute;
  bottom: 80%; /* Position the label above the icon */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  white-space: nowrap;
  margin-bottom: 5px;
  pointer-events: none;
  text-align: center;
  z-index: 5;
}

.map-wrapper {
  width: 1400px;
  height: 800px;
  overflow: auto;
  border: 1px solid #ccc;
  position: relative;
  background-color: #f0f0f0;
}

.large-map-container {
  position: relative;
  background-size: cover;
  cursor: pointer;
}

.poi-image {
  position: absolute;
  width: 60px;
  height: 60px;
}

.json-output {
  margin-top: 20px;
}
</style>
