<template>
    <div>
      <div>
        <div v-if="!showJournal">
          <ion-grid>
            <ion-row>
              <ion-col size="12">
                <div class="quest-board">
                  <div v-for="(quest, index) in quests" :key="index" class="quest-tile" @click="selectQuest(quest)">
                    <ion-text>
                      <h3>{{ quest.name }}</h3>
                    </ion-text>
                  </div>
                </div>
              </ion-col>
            </ion-row>
            <ion-row v-if="selectedQuest">
              <ion-col size="12">
                <ion-card class="quest-details">
                  <ion-card-header>
                    <ion-card-title>{{ selectedQuest.name }}</ion-card-title>
                  </ion-card-header>
                  <ion-card-content>
                    <p>{{ selectedQuest.text }}</p>
                    <ion-list>
                      <ion-item v-for="(objective, index) in selectedQuest.objectives" :key="index">
                        <ion-label>{{ objective.description }}</ion-label>
                      </ion-item>
                    </ion-list>
                    <div class="rewards">
                      <p>Qi: {{ selectedQuest.rewards.qi }}</p>
                      <p>Mortal Currency:
                        <CurrencyDisplay currency="mortal" :value="selectedQuest.rewards.mortalCurrency" />
                      </p>
                    </div>
                    <QJButton @click="acceptQuest">Accept Quest</QJButton>
                  </ion-card-content>
                </ion-card>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <QJButton @click="toggleJournal">Open Journal</QJButton>
              </ion-col>
              <ion-col size="12">
                <QJButton @click="closeBoard">Close</QJButton>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <CharacterJournal v-else :character="currentCharacter" @closeJournal="toggleJournal"
          @updateCharacter="updateCharacter" />
      </div>
    </div>
  </template>
  
  <script>
  import CharacterJournal from '../character/CharacterJournal.vue';
  import characterWorker from '../../workers/characterWorker.js';
  import questWorker from '../../workers/questWorker.js';
  
  import CurrencyDisplay from '../helpers/CurrencyDisplay.vue';
  import QJButton from '../framework/QJButton.vue'
  
  export default {
    components: {
      CharacterJournal,
      CurrencyDisplay,
      QJButton
    },
    data() {
      return {
        quests: [],
        selectedQuest: null,
        showJournal: false
      };
    },
    computed: {
      currentCharacter() {
        return this.$store.getters.getCurrentCharacter;
      },
      user() {
        return this.$store.getters.getCurrentUser;
      }
    },
    created() {
      this.generateQuests();
    },
    methods: {
      async generateQuests() {
        const quest1 = await questWorker.generateRandomQuest(this.user, this.currentCharacter);
        const quest2 = await questWorker.generateRandomQuest(this.user, this.currentCharacter);
        const quest3 = await questWorker.generateRandomQuest(this.user, this.currentCharacter);
        const quest4 = await questWorker.generateRandomQuest(this.user, this.currentCharacter);
        const quest5 = await questWorker.generateRandomQuest(this.user, this.currentCharacter);
        const quest6 = await questWorker.generateRandomQuest(this.user, this.currentCharacter);
        if (quest1 && quest2) {
          this.quests = [quest1, quest2, quest3, quest4, quest5, quest6];
          console.log("Quests generated successfully.", this.quests);
        } else {
          console.error("Failed to generate one or both quests.");
        }
      },
      selectQuest(quest) {
        this.selectedQuest = quest;
      },
      async acceptQuest() {
        if (this.selectedQuest) {
          const updatedCharacter = {
            ...this.currentCharacter,
            quests: [...this.currentCharacter.quests, this.selectedQuest]
          };
          await characterWorker.updateCharacter(this.$store, this.user, updatedCharacter);
          this.$emit('updateCharacter', updatedCharacter);
          this.selectedQuest = null;
        }
      },
      closeBoard() {
        this.$emit('close');
      },
      toggleJournal() {
        this.showJournal = !this.showJournal;
      },
      updateCharacter(updatedCharacter) {
        this.$emit('updateCharacter', updatedCharacter);
      }
    }
  };
  </script>
  
  <style scoped>
  .quest-board {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
  }
  
  .quest-tile {
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
  }
  
  .quest-tile:hover {
    background-color: #f0f0f0;
  }
  
  .quest-details {
    border: 1px solid #ccc;
    padding: 20px;
    margin-top: 20px;
  }
  
  .rewards {
    margin-top: 10px;
  }
  
  .qj-button {
    margin-top: 20px;
  }
  </style>