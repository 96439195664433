<template>
  <ButtonStack :horizontal="false" :expandable="false">
    <QJButton @click="$router.push('/random-combat/')">Combat</QJButton>
    <QJButton @click="$router.push('/shop-test')">Shop</QJButton>
    <QJButton @click="$router.push('/profession-test')">Professions</QJButton>
    <QJButton @click="$router.push('/world-map-test')">World Map</QJButton>
    <QJButton @click="$router.push('/map-test')">Map</QJButton>
    <QJButton @click="$router.push('/mustard-seed-test')">Mustard Seed</QJButton>
    <QJButton @click="$router.push('/equipment-test')">Equipment</QJButton>
    <QJButton @click="$router.push('/quest-test/')">Quests</QJButton>
    <QJButton @click="$router.push('/story-test')">Story</QJButton>
    <QJButton @click="$router.push('/transmog-test')">Transmog</QJButton>
    <QJButton @click="$router.push('/map-generator/')" v-if="user.uid === 'Zh1ApdkrkmfXateT7n5Tu1Dgxhn1'">Map Generator</QJButton>
    <QJButton @click="$router.push('/large-map-generator')" v-if="user.uid === 'Zh1ApdkrkmfXateT7n5Tu1Dgxhn1'">Large Map Generator</QJButton>
  </ButtonStack>
</template>

<script>
import ButtonStack from '../framework/ButtonStack.vue';
import QJButton from '../framework/QJButton.vue';

export default {
  components: {
    ButtonStack,
    QJButton
  },
  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    }
  }
};
</script>