<template>
  <div>
    <ion-button @click="showStory('example.txt', 'cave')">Show Story</ion-button>

    <!-- Story Popup -->
    <StoryPopup v-if="showPopup" :fileName="currentFileName" :layout="currentLayout" :background="currentBackground"
      @close="showPopup = false" />

    <!-- Info Button and Popup -->
    <ion-button fill="clear" @click="showInfoPopup('This is the information about the item.')">
      <ion-icon name="help-circle-outline"></ion-icon>
    </ion-button>
    <InfoPopup v-if="showInfo" :text="infoText" @close="showInfo = false" />
  </div>
</template>


<script>
import StoryPopup from '../popups/StoryPopup.vue';
import InfoPopup from '../popups/InfoPopup.vue'

export default {
  components: {
    StoryPopup,
    InfoPopup
  },
  data() {
    return {
      showPopup: false,
      currentFileName: '',
      currentBackground: 'city',
      showInfo: false,
      infoText: ''
    };
  },
  methods: {
    showStory(fileName, background) {
      this.currentFileName = fileName;
      this.currentBackground = background;
      this.showPopup = false;
      this.$nextTick(() => {
        this.showPopup = true;
      });
    },
    showInfoPopup(text) {
      this.infoText = text;
      this.showInfo = false; // Reset the state to force re-render
      this.$nextTick(() => {
        this.showInfo = true; // Show the popup again
      });
    }
  }
};
</script>

<style>
.bamboo-button {
  background: linear-gradient(135deg, #7f8c42 25%, #a8c24b 25%, #a8c24b 50%, #7f8c42 50%, #7f8c42 75%, #a8c24b 75%, #a8c24b);
  background-size: 30px 30px;
  border: 2px solid #7f8c42;
  border-radius: 10px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.3), inset 0 -2px 5px rgba(255, 255, 255, 0.3);
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.bamboo-button::before {
  content: '';
  position: absolute;
  top: -50%;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  transform: rotate(-45deg);
  opacity: 0;
  transition: all 0.3s ease;
}

.bamboo-button:hover::before {
  top: 150%;
  opacity: 1;
}

.bamboo-button:active {
  transform: scale(0.95);
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.5), inset 0 -2px 5px rgba(255, 255, 255, 0.5);
}
</style>
