<template>
  <div class="ion-padding">
    <ion-grid>
      <ion-row>
        <ion-col size="12" class="ion-text-center">
          <h3 class="text-center">Inventory Management</h3>
          <ButtonStack :startOpen="true">
            <QJButton expand="block" @click="setActiveTab('inventory')">Inventory</QJButton>
            <QJButton expand="block" @click="setActiveTab('consumables')">Consumables</QJButton>
            <QJButton expand="block" @click="setActiveTab('resources')">Resources & Seeds</QJButton>
            <QJButton expand="block" @click="setActiveTab('equipment')">Equipment</QJButton>
            <QJButton expand="block" @click="setActiveTab('skillBooks')">Skill Books</QJButton>
          </ButtonStack>
        </ion-col>
      </ion-row>

      <!-- Inventory Tab -->
      <ion-row v-if="activeTab === 'inventory'" class="inventory-items">
        <ion-col size="12">
          <h4 class="text-center">Inventory Items</h4>
          <ion-row>
            <ion-col size="12" size-md="6" size-lg="4" v-for="item in inventory" :key="item.id">
              <ion-card class="ion-padding-bottom ion-margin-bottom display-card">
                <ion-card-header>
                  <img v-fallback-img :src="item.image" class="item-image-small">
                  <ion-card-title class="item-name"
                    :style="{ color: item.rarity ? item.rarity.color : defaultColor }">{{ item.name }}</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <p class="stat-text">{{ item.slot }}</p><br>
                  <p v-if="item.damage" class="subtext">{{ item.damage }} damage</p>
                  <p v-if="item.armorValue" class="subtext">{{ item.armorValue }} armor</p>
                  <p class="subtext wrap-text">Value:
                    <CurrencyDisplay currency="mortal" :value="item.value" />
                  </p>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>

      <!-- Consumables Tab -->
      <ion-row v-if="activeTab === 'consumables'" class="consumables-items">
        <ion-col size="12">
          <h4 class="text-center">Consumables</h4>
          <ion-row>
            <ion-col size="12" size-md="6" size-lg="4" v-for="item in consumables" :key="item.id">
              <ion-card class="ion-padding-bottom ion-margin-bottom display-card">
                <ion-card-header>
                  <ion-card-title class="item-name">{{ item.name }}</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <p class="subtext">Quantity: {{ item.quantity }}</p>
                  <p class="stat-text">{{ item.effect }}</p><br>
                  <p class="subtext wrap-text">Value:
                    <CurrencyDisplay currency="mortal" :value="item.value * item.quantity" />
                  </p>
                </ion-card-content>
                <ion-card-footer>
                  <QJButton expand="block" @click="consumeItem(item)">Consume</QJButton>
                </ion-card-footer>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>

      <!-- Resources & Seeds Tab -->
      <ion-row v-if="activeTab === 'resources'" class="resources-items">
        <ion-col size="12">
          <h4 class="text-center">Resources & Seeds</h4>
          <ion-row>
            <ion-col size="12" size-md="6" size-lg="4" v-for="item in resources" :key="item.material">
              <ion-card class="ion-padding-bottom ion-margin-bottom display-card">
                <ion-card-header>
                  <ion-card-title class="item-name">{{ capitalizeAllWords(item.material) }}</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <p class="subtext">Quantity: {{ item.quantity }}</p>
                  <p class="subtext wrap-text">Value:
                    <CurrencyDisplay currency="mortal" :value="item.value * item.quantity" />
                  </p>
                </ion-card-content>
              </ion-card>
            </ion-col>
            <ion-col size="12" size-md="6" size-lg="4" v-for="seed in seeds" :key="seed.name">
              <ion-card class="ion-padding-bottom ion-margin-bottom display-card">
                <ion-card-header>
                  <ion-card-title class="item-name">{{ capitalizeAllWords(seed.name) }}</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <p class="stat-text">{{ seed.quantity }}</p>
                  <p class="subtext">Harvest Time: {{ seed.harvestTime / 1000 }} seconds</p>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>

      <!-- Equipment Tab -->
      <ion-row v-if="activeTab === 'equipment'" class="equipped-items">
        <ion-col size="12">
          <h2 class="text-center">Equipment</h2>
          <ion-row>
            <ion-col size="12" v-for="slot in equipmentSlots.filter(slot => !slot.includes('ring'))" :key="slot"
              class="slot-column">
              <div @click="selectEquipmentSlot(slot)" class="slot-container">
                <label class="slot-label">{{ capitalizeAllWords(slot) }}</label>
                <span class="item-count">({{ countMatchingItems(slot) }})</span><br /><br />
                <img v-fallback-img :src="getEquippedItem(slot).image ? getEquippedItem(slot).image : getSlotBackgroundImage(slot)"
                  class="slot-image" />
              </div>
            </ion-col>
          </ion-row>
          <ion-row class="ring-slots">
            <ion-col size="3" v-for="slot in equipmentSlots.filter(slot => slot.includes('ring'))" :key="slot"
              class="slot-column ring-slot">
              <div @click="selectEquipmentSlot(slot)" class="slot-container">
                <label class="slot-label">{{ capitalizeAllWords(slot) }}</label>
                <span class="item-count">({{ countMatchingItems(slot) }})</span><br /><br />
                <img v-fallback-img :src="getEquippedItem(slot).image ? getEquippedItem(slot).image : getSlotBackgroundImage(slot)"
                  class="ring-slot-image" />
              </div>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>

      <!-- Skill Books Tab -->
      <ion-row v-if="activeTab === 'skillBooks'" class="skillbook-items">
        <ion-col size="12">
          <h4 class="text-center">Skill Books</h4>
          <ion-row>
            <ion-col size="12" size-md="6" size-lg="4" v-for="item in skillBooks" :key="item.id">
              <ion-card class="ion-padding-bottom ion-margin-bottom display-card">
                <ion-card-header>
                  <ion-card-title class="item-name">{{ item.name }}</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <p class="subtext">Level: {{ item.level }}</p>
                  <p v-if="item.type === 'Martial Offensive' || item.type === 'Qi Offensive'">Damage: {{
                    item.effect.damage }}</p>
                  <p class="subtext wrap-text">Value:
                    <CurrencyDisplay currency="immortal" :value="item.value" />
                  </p>
                </ion-card-content>
                <ion-card-footer>
                  <QJButton expand="block" @click="readSkillBook(item)">Learn</QJButton>
                </ion-card-footer>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>

      <!-- Fullscreen Popup for selected slot -->
      <FullScreenPopup v-if="selectedSlot" :isOpen="true" @close="closeModal">
        <QJButton @click="closeModal">Close</QJButton>
        <div v-if="equippedItem" class="selected-equipment-info">
          <h4>Currently Equipped</h4>
          <ion-card class="ion-padding-bottom ion-margin-bottom display-card">
            <ion-card-header>
              <ion-card-title class="item-name"
                :style="{ color: equippedItem.rarity ? equippedItem.rarity.color : defaultColor }">{{ equippedItem.name
                }}</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <img v-fallback-img
                :src="getEquippedItem(selectedSlot).image ? getEquippedItem(selectedSlot).image : getSlotBackgroundImage(selectedSlot)"
                class="equipped-item-image" />
              <p class="stat-text">{{ equippedItem.slot }}</p><br>
              <p v-if="equippedItem.damage" class="subtext">{{ equippedItem.damage }} damage</p>
              <p v-if="equippedItem.armorValue" class="subtext">{{ equippedItem.armorValue }} armor</p>
              <p class="subtext wrap-text" v-if="getEquippedItem(selectedSlot)">Value:
                <CurrencyDisplay currency="mortal" :value="equippedItem.value" />
              </p>
            </ion-card-content>
            <ion-card-footer v-if="getEquippedItem(equippedItem.slot)">
              <QJButton expand="block" @click="unequipItem(equippedItem.slot)">Unequip</QJButton>
            </ion-card-footer>
          </ion-card>
        </div>

        <div v-if="matchingItems.length > 0" class="matching-items">
          <h4>Matching Items</h4>
          <ion-row>
            <ion-col size="12" size-md="6" size-lg="4" v-for="item in matchingItems" :key="item.id">
              <ion-card class="ion-padding-bottom ion-margin-bottom display-card">
                <ion-card-header>
                  <ion-card-title class="item-name"
                    :style="{ color: item.rarity ? item.rarity.color : defaultColor }">{{ item.name }}</ion-card-title>
                </ion-card-header>
                <ion-card-content class="item-info-panel">
                  <img v-fallback-img :src="item.image" class="matching-item-image" />
                  <p class="stat-text">{{ item.slot }}</p><br>
                  <p v-if="item.damage" class="subtext info-text">{{ item.damage }} damage
                    <Indicator :first="equippedItem.damage" :second="item.damage" />
                  </p>
                  <p v-if="item.armorValue" class="subtext info-text">{{ item.armorValue }} armor
                    <Indicator :first="equippedItem.armorValue" :second="item.armorValue" />
                  </p>
                  <p class="subtext wrap-text">Value:
                    <CurrencyDisplay currency="mortal" :value="item.value" />
                  </p>
                </ion-card-content>
                <ion-card-footer>
                  <QJButton expand="block" @click="equipItem(item)">Equip</QJButton>
                </ion-card-footer>
              </ion-card>
            </ion-col>
          </ion-row>
        </div>
      </FullScreenPopup>
    </ion-grid>
  </div>
</template>

<script>
import inventoryWorker from '@/workers/inventoryWorker.js';
import characterWorker from '@/workers/characterWorker.js';
import helperFunctions from '../../../serverless-backend/server/helpers/helperFunctions.js';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import CurrencyDisplay from '../helpers/CurrencyDisplay.vue';
import ButtonStack from '../framework/ButtonStack.vue';
import QJButton from '../framework/QJButton.vue';
import FullScreenPopup from '../navigation/FullScreenPopup.vue';
import Indicator from '../framework/Indicator.vue';

export default {
  components: {
    CurrencyDisplay,
    ButtonStack,
    QJButton,
    FullScreenPopup,
    Indicator
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
      character: {},
      inventory: [],
      consumables: [],
      resources: [],
      seeds: [],
      skillBooks: [],
      equipped: {},
      defaultColor: "black",
      activeTab: 'inventory',
      equipmentSlots: ['head', 'neck', 'chest', 'arms', 'hands', 'waist', 'legs', 'feet', 'ring1', 'ring2', 'ring3', 'ring4', 'weapon'],
      selectedSlot: null,
      s3BaseUrl: 'https://willoftheimmortals-images.s3.amazonaws.com/siteimages/'
    };
  },
  mounted() {
    this.loadInventory();
  },
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
    matchingItems() {
      return this.inventory.filter(i => i.slot === this.selectedSlot);
    },
    equippedItem() {
      return this.getEquippedItem(this.selectedSlot);
    }
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
      this.selectedSlot = null; // Clear the selected slot when changing tabs
    },
    async loadInventory() {
      try {
        console.log("Loading Inventory");
        const response = await inventoryWorker.getCharacterInventory(this.$store.getters.getCurrentUser, this.currentCharacter._id);
        this.character = response;
        this.$store.commit('setCurrentCharacter', this.character);
        this.inventory = this.character.inventory || [];
        this.consumables = this.character.consumables || [];
        this.resources = this.character.resources || [];
        this.seeds = this.character.seeds || [];
        this.skillBooks = this.character.skillBooks || [];
        this.equipped = this.character.equipped || {};

        console.log('inventory', this.inventory);
        console.log('consumables', this.consumables);
        console.log('resources', this.resources);
        console.log('seeds', this.seeds);
        console.log('skillBooks', this.skillBooks);
        console.log('equipped', this.equipped);
      }
      catch (error) {
        console.error(error);
      }
    },
    getSlotBackgroundImage(slot) {
      const slotImages = {
        head: 'headslot.png',
        neck: 'neckslot.png',
        chest: 'chestslot.png',
        waist: 'waistslot.png',
        legs: 'legsslot.png',
        feet: 'feetslot.png',
        arms: 'armslot.png',
        hands: 'handslot.png',
        ring1: 'ringslot.png',
        ring2: 'ringslot.png',
        ring3: 'ringslot.png',
        ring4: 'ringslot.png',
        weapon: 'weaponslot.png'
      };
      return `${this.s3BaseUrl}${slotImages[slot]}`;
    },
    async equipItem(item) {
      try {
        const response = await inventoryWorker.equipItem(this.$store, this.$store.getters.getCurrentUser, this.character, item);
        console.log(response);
        this.updateInventory(response);
        this.selectedSlot = null; // Clear the selected slot after equipping an item
      }
      catch (error) {
        console.error(error);
      }
    },
    async unequipItem(slot) {
      try {
        const response = await inventoryWorker.unequipItem(this.$store, this.$store.getters.getCurrentUser, this.character, slot);
        console.log(response);
        this.updateInventory(response);
        this.selectedSlot = null; // Clear the selected slot after unequipping an item
      }
      catch (error) {
        console.error(error);
      }
    },
    async removeItem(item) {
      try {
        const response = await inventoryWorker.removeFromInventory(this.$store, this.$store.getters.getCurrentUser, this.character, item);
        console.log(response);
        this.loadInventory();
      }
      catch (error) {
        console.error(error);
      }
    },
    async removeFromConsumables(item) {
      try {
        const response = await inventoryWorker.removeFromConsumables(this.$store, this.user, this.currentCharacter, item);
        console.log(response);
        this.loadInventory();
      }
      catch (error) {
        console.error(error);
      }
    },
    selectEquipmentSlot(slot) {
      this.selectedSlot = slot;
    },
    closeModal() {
      this.selectedSlot = null;
    },
    getEquippedItem(slot) {
      return this.equipped.find(e => e.slot === slot)?.item || null;
    },
    countMatchingItems(slot) {
      return this.inventory.filter(i => i.slot === slot).length;
    },
    async updateInventory(character) {
      this.character = character;
      this.loadInventory();
    },
    async readSkillBook(book) {
      try {
        const response = await characterWorker.addSkillToCharacter(this.$store, this.$store.getters.getCurrentUser, this.character, book);
        await inventoryWorker.removeSkillBookFromInventory(this.$store, this.$store.getters.getCurrentUser, this.character, book);
        console.log(response);
        this.updateInventory(response);
      } catch (error) {
        console.error("Error reading skill book:", error);
      }
    },
    async consumeItem(item) {
      console.log("Consuming item", item);

      const healthGainPercent = item.effects.health;
      const staminaGainPercent = item.effects.stamina;
      const vitalEnergyGainPercent = item.effects.vitalEnergy;

      if (healthGainPercent) {
        console.log("Health Gain Percent:", healthGainPercent);
        const healthGain = Math.floor(this.currentCharacter.maxHealth * (healthGainPercent / 100));
        console.log("Health Gain:", healthGain);
        await characterWorker.modifyCharacterHealth(this.$store, this.user, this.currentCharacter, healthGain);
      }
      if (staminaGainPercent) {
        console.log("Stamina Gain Percent:", staminaGainPercent);
        const staminaGain = Math.floor(this.currentCharacter.maxStamina * (staminaGainPercent / 100));
        console.log("Stamina Gain:", staminaGain);
        await characterWorker.modifyCharacterStamina(this.$store, this.user, this.currentCharacter, staminaGain);
      }
      if (vitalEnergyGainPercent) {
        console.log("Vital Energy Gain Percent:", vitalEnergyGainPercent);
        const vitalEnergyGain = Math.floor(this.currentCharacter.maxVitalEnergy * vitalEnergyGainPercent / 100);
        console.log("Vital Energy Gain:", vitalEnergyGain);
        await characterWorker.modifyCharacterVitalEnergy(this.$store, this.user, this.currentCharacter, vitalEnergyGain);
      }
      await this.removeFromConsumables(item);
    },
    capitalizeAllWords(string) {
      return helperFunctions.capitalizeAllWords(string);
    }
  }
};
</script>

<style scoped>
.character-inventory-pane {
  text-align: center;
}

hr {
  border: 1px solid black;
  border-radius: 5px;
  width: 100%;
  padding-left: 30px;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.info-text {
  display: flex;
  align-items: center;
  margin-left: 35px
}

.info-text Indicator {
  margin-left: 10px;
}


button {
  padding: 10px;
  cursor: pointer;
  width: 125px;
  height: 50px;
}

.inventory-items,
.consumables-items,
.resources-items,
.equipped-items,
.skill-books-items {
  text-align: left;
}

.small-cards {
  list-style-type: none;
  padding: 0;
}

.display-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}

.empty-slot {
  background-color: #f0f0f0;
  cursor: pointer;
}

.select-equipment {
  margin-top: 20px;
}

.slot-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.slot-container {
  cursor: pointer;
}

.slot-label {
  margin-bottom: 10px;
  margin-right: 10px;
  font-weight: bold;
}

.item-count {
  margin-bottom: 10px;
  font-size: 12px;
  color: #666;
}

.slot-image,
.equipped-item-image,
.matching-item-image {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.ring-slot-image {
  width: 75px;
  height: 75px;
}

.ring-slots {
  margin-top: 20px;
  justify-content: center;
}

.selected-equipment-info {
  margin-bottom: 20px;
}

.slot-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slot-title {
  flex-grow: 1;
  text-align: center;
}

.checkmark {
  color: blue;
  font-size: 20px;
}

.equipped-item {
  margin-left: 10px;
  font-style: italic;
  font-size: 14px;
}

.ion-card-text p {
  margin-bottom: 8px;
  /* Add some gap between lines */
}
.item-image-small {
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 768px) {
  .info-text {
    margin-left: 115px;
  }
}
</style>
