<template>
    <div class="game-container">
        <QJButton @click="handleClose" class="close-button">Leave the Kitchen</QJButton>
        <Kitchen />
    </div>
</template>


<script>
import Kitchen from '../professions/Kitchen.vue';
import QJButton from '../framework/QJButton.vue';

export default {
    components: {
        Kitchen,
        QJButton
    },
    methods: {
        handleClose() {
            setTimeout(() => {
                console.log('Closing combat scene');
                this.$emit('close');
            }, 500); // Display result for 2 seconds before closing
        }
    }
};
</script>

<style scoped>
.game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
}
</style>