import axios from 'axios';
import _ from 'lodash';

import characterCalculations from '../../serverless-backend/server/utilities/characterCalculations.js';
import cultivationInformation from '../../serverless-backend/server/datasets/cultivationInformation.json';

import helperFunctions from '../../serverless-backend/server/helpers/helperFunctions.js'

const apiUrl = process.env.VUE_APP_API_URL;

const getAllCharacters = async (user) => {
  try {
    if (!user) {
      console.error("No user is currently signed in");
      return;
    }
    const token = await user.getIdToken(true);
    console.log("TOKEN", token);
    const response = await axios.get(`${apiUrl}/api/characters/all`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("GET ALL CHARACTERS", response);
    return response.data;
  } catch (error) {
    console.error("Failed to get all characters", error);
  }
};

const getCharacter = async (user, id) => {
  try {
    if (!user) {
      console.error("No user is currently signed in");
      return;
    }
    const token = await user.getIdToken(true);
    console.log("TOKEN", token);
    const response = await axios.get(`${apiUrl}/api/characters/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("GET CHARACTER", response);
    return response.data;
  } catch (error) {
    console.error("Failed to get character", error);
  }
}

const updateCharacter = async (store, user, character, event) => {
  try {
    if (!user) {
      console.error("No user is currently signed in");
      return;
    }
    if (!character) {
      console.error("No character data loaded");
      return;
    }

    if (event) {
      const updatedCharacter = handleEvent(_.cloneDeep(character), event);
      character = updatedCharacter;
    }

    const token = await user.getIdToken(true);

    const payload = {
      character: character,
    };
    console.log("PAYLOAD", payload);
    const response = await axios.put(`${apiUrl}/api/characters/update-character`, payload, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("RESPONSE", response);
    store.commit('setCurrentCharacter', character);
    console.log("CHARACTER", character);
    return character;
  } catch (error) {
    console.error("Failed to update character", error);
  }
};

export const handleEvent = (character, event) => {
  const updatedCharacter = _.cloneDeep(character);

  updatedCharacter.quests.forEach(quest => {
    quest.objectives.forEach(objective => {
      if (objective.type === event.type && !objective.completed) {
        objective.progress = (objective.progress || 0) + 1;
        if (objective.progress >= objective.required) {
          objective.completed = true;
        }
      }
    });
  });

  return updatedCharacter;
};

const modifyCharacterHealth = (store, user, character, amount) => {
  if (amount === 'full') {
    character.health = character.maxHealth;
    return updateCharacter(store, user, character);
  } else {
    character.health += amount;
    if (character.health <= 0) {
      character.health = 0;
    }
    if (character.health > character.maxHealth) {
      character.health = character.maxHealth;
    }
    return updateCharacter(store, user, character);
  }
};

const modifyCharacterQi = (store, user, character, amount) => {
  let updatedCharacter = character;
  updatedCharacter.qiTotal += amount;
  if (updatedCharacter.qiTotal >= updatedCharacter.qiToNextLevel) {
    return levelUpCharacter(store, user, updatedCharacter);
  } else {
    return updateCharacter(store, user, updatedCharacter);
  }
};

const modifyCharacterMortalCurrency = (store, user, character, amount) => {
  let updatedCharacter = character;
  updatedCharacter.mortalCurrency += amount;
  return updateCharacter(store, user, updatedCharacter);
};

const modifyCharacterImmortalCurrency = (store, user, character, amount) => {
  console.log("MODIFYING IMMORTAL CURRENCY");
  let updatedCharacter = character;
  updatedCharacter.immortalCurrency += amount;
  console.log("UPDATED CHARACTER", updatedCharacter);
  return updateCharacter(store, user, updatedCharacter);
};

const addSkillToCharacter = async (store, user, character, skill) => {
  try {
    let updatedCharacter = character;
    updatedCharacter.skills.push(skill);
    await updateCharacter(store, user, updatedCharacter);
  } catch (error) {
    console.error("Failed to add skill to character", error);
  }
}

const removeSkillFromCharacter = async (store, user, character, skill) => {
  try {
    let updatedCharacter = character;
    updatedCharacter.skills = updatedCharacter.skills.filter(s => s.name !== skill.name);
    await updateCharacter(store, user, updatedCharacter);
  } catch (error) {
    console.error("Failed to remove skill from character", error);
  }
}

const levelUpCharacterStats = (oldCharacter, character) => {
  const updatedCharacter = { ...character };
  const bonuses = updatedCharacter.bonuses;

  bonuses.enduranceBonus += character.endurance - oldCharacter.endurance;
  bonuses.staminaBonus += character.agility - oldCharacter.agility;
  bonuses.spiritBonus += character.spirit - oldCharacter.spirit;

  if (oldCharacter.bonuses.enduranceBonus !== bonuses.enduranceBonus) {
    updatedCharacter.maxHealth = Math.ceil(character.maxHealth + (helperFunctions.rollDice(4) * bonuses.enduranceBonus));
    updatedCharacter.health = updatedCharacter.maxHealth;
    console.log("UPDATED HEALTH", oldCharacter.maxHealth, updatedCharacter.maxHealth);
  }
  if (oldCharacter.bonuses.staminaBonus !== bonuses.staminaBonus) {
    updatedCharacter.maxStamina = Math.ceil(character.maxStamina + (helperFunctions.rollDice(4) * bonuses.staminaBonus));
    updatedCharacter.stamina = updatedCharacter.maxStamina;
    console.log("UPDATED STAMINA", oldCharacter.maxStamina, updatedCharacter.maxStamina);
  }
  if (oldCharacter.bonuses.spiritBonus !== bonuses.spiritBonus) {
    updatedCharacter.maxVitalEnergy = Math.ceil(character.maxVitalEnergy + (helperFunctions.rollDice(4) * bonuses.spiritBonus));
    updatedCharacter.vitalEnergy = updatedCharacter.maxVitalEnergy;
    console.log("UPDATED VITAL ENERGY", oldCharacter.maxVitalEnergy, updatedCharacter.maxVitalEnergy);
  }

  console.log("UPDATED CHARACTER", updatedCharacter);
  return updatedCharacter;
}

const levelUpCharacter = async (store, user, character, tribulationPassed = false) => {
  let updatedCharacter = { ...character };

  if (updatedCharacter.stage === 9 && !tribulationPassed) {
    return updatedCharacter;
  }

  updatedCharacter.level += 1;
  updatedCharacter = calculateRealmStageRebirth(updatedCharacter);

  updatedCharacter.qiToNextLevel = updatedCharacter.qiTotal + characterCalculations.calculateXpToLevel(updatedCharacter.level);
  updatedCharacter.maxHealth = characterCalculations.calculateNewHealth(updatedCharacter);
  updatedCharacter.health = updatedCharacter.maxHealth;
  updatedCharacter.maxStamina = characterCalculations.calculateNewStamina(updatedCharacter);
  updatedCharacter.stamina = updatedCharacter.maxStamina;
  updatedCharacter.maxVitalEnergy = characterCalculations.calculateNewVitalEnergy(updatedCharacter);
  updatedCharacter.vitalEnergy = updatedCharacter.maxVitalEnergy;
  updatedCharacter.attributePoints += 2;

  return updateCharacter(store, user, updatedCharacter);
};

const levelDownCharacter = async (store, user, character, levels) => {
  let updatedCharacter = { ...character };

  updatedCharacter.level -= levels;
  updatedCharacter = calculateRealmStageRebirth(updatedCharacter);

  updatedCharacter.qiToNextLevel = updatedCharacter.qiTotal + characterCalculations.calculateXpToLevel(updatedCharacter.level);
  updatedCharacter.maxHealth = characterCalculations.calculateNewHealth(updatedCharacter);
  updatedCharacter.health = updatedCharacter.maxHealth;

  return updateCharacter(store, user, updatedCharacter);
}

const calculateRealmStageRebirth = (character) => {
  const stagesPerRealm = 10;
  const realms = cultivationInformation.realms;
  const totalRealms = realms.length;
  const updatedCharacter = { ...character };

  const rebirth = Math.floor((character.level - 1) / (totalRealms * stagesPerRealm));
  const levelWithinRebirth = (character.level - 1) % (totalRealms * stagesPerRealm);
  const realmIndex = Math.floor(levelWithinRebirth / stagesPerRealm);
  const stage = levelWithinRebirth % stagesPerRealm;

  updatedCharacter.rebirth = rebirth;
  updatedCharacter.realm = realms[realmIndex].name;
  updatedCharacter.stage = stage;

  return updatedCharacter;
}

const modifyCharacterStamina = (store, user, character, amount) => {
  character.stamina += amount;
  if (character.stamina < 0) {
    character.stamina = 0;
  }
  if (character.stamina > character.maxStamina) {
    character.stamina = character.maxStamina;
  }
  return updateCharacter(store, user, character);
};

const modifyCharacterVitalEnergy = (store, user, character, amount) => {
  character.vitalEnergy += amount;
  if (character.vitalEnergy < 0) {
    character.vitalEnergy = 0;
  }
  if (character.vitalEnergy > character.maxVitalEnergy) {
    character.vitalEnergy = character.maxVitalEnergy;
  }
  return updateCharacter(store, user, character);
};

const fillCharacterStamina = async (store, user, character) => {
  character.stamina = character.maxStamina;
  return updateCharacter(store, user, character);
};

const fillCharacterVitalEnergy = async (store, user, character) => {
  character.vitalEnergy = character.maxVitalEnergy;
  return updateCharacter(store, user, character);
};

export default {
  getAllCharacters,
  getCharacter,
  updateCharacter,
  levelUpCharacter,
  levelUpCharacterStats,
  levelDownCharacter,
  modifyCharacterHealth,
  modifyCharacterQi,
  modifyCharacterMortalCurrency,
  modifyCharacterImmortalCurrency,
  modifyCharacterStamina,
  fillCharacterStamina,
  modifyCharacterVitalEnergy,
  fillCharacterVitalEnergy,
  addSkillToCharacter,
  removeSkillFromCharacter,
  calculateRealmStageRebirth
};
