<template>
    <div class="game-container">
        <QuestBoard @close="handleClose" />
    </div>
</template>


<script>
import QuestBoard from '../professions/QuestBoard.vue';

export default {
    components: {
        QuestBoard
    },
    methods: {
        handleClose() {
            this.$emit('close');
        }
    }
};
</script>
