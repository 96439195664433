<template>
  <div>
    <ion-card class="skill-buttons">
      <ion-card-content>
        <ion-button expand="full" @click="generateRandomSkill">Generate Random Skill</ion-button>
      </ion-card-content>
      <ion-card-content>
        <ion-select v-model="selectedRealm" placeholder="Select Realm">
          <ion-select-option v-for="realm in realms" :key="realm.id" :value="realm.name">
            {{ realm.name }}
          </ion-select-option>
        </ion-select>
        <ion-button expand="full" @click="generateSkillByRealm">Generate Skill by Realm</ion-button>
      </ion-card-content>
      <ion-card-content>
        <ion-select v-model="selectedRealmForType" placeholder="Select Realm">
          <ion-select-option v-for="realm in realms" :key="realm.id" :value="realm.name">
            {{ realm.name }}
          </ion-select-option>
        </ion-select>
        <ion-select v-model="selectedType" placeholder="Select Type">
          <ion-select-option v-for="type in types" :key="type" :value="type">
            {{ type }}
          </ion-select-option>
        </ion-select>
        <ion-button expand="full" @click="generateSkillByRealmAndType">Generate Skill by Realm and Type</ion-button>
      </ion-card-content>
    </ion-card>

    <ion-card v-if="skill">
      <ion-card-header>
        <ion-card-title>Generated Skill</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <p>Name: {{ skill.name }}</p>
        <p>Realm: {{ skill.realm }}</p>
        <p>Type: {{ skill.type }}</p>
        <p v-if="skill.evolves_from">Evolves From: {{ skill.evolves_from }}</p>
        <p v-for="(value, key) in skill.requirements" :key="key">Requirements: {{ capitalizeFirstCharacter(key) }}: {{
          value }}</p>
        <p v-for="(value, key) in skill.value" :key="key">Cost: {{ key }}: {{ value }}</p>
        <p>Effect</p>
        <p v-if="skill.effect.damage">Damage: {{ skill.effect.damage }}</p>
        <p v-if="skill.effect.damageType">Damage Type: {{ skill.effect.damageType }}</p>
        <p v-if="skill.effect.resistance">Resistance: {{ skill.effect.resistance }}</p>
        <p v-if="skill.effect.resistanceType">Resistance Type: {{ skill.effect.resistanceType }}</p>
        <p v-if="skill.effect.effect">Effect: {{ skill.effect.effect }}</p>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
import skillWorker from '../../workers/skillWorker.js';
import cultivationInformation from '../../../serverless-backend/server/datasets/cultivationInformation.json';
import helperFunctions from '../../../serverless-backend/server/helpers/helperFunctions.js';

export default {
  computed: {
    currentCharacter() {
      return this.$store.getters.getCurrentCharacter;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    }
  },
  data() {
    return {
      skill: null,
      selectedRealm: '',
      selectedRealmForType: '',
      selectedType: '',
      realms: cultivationInformation.realms,
      types: []
    };
  },
  created() {
    this.types = this.getAllTypes();
  },
  methods: {
    async generateRandomSkill() {
      try {
        this.skill = await skillWorker.generateRandomSkill(this.user, this.currentCharacter.level);
      } catch (error) {
        console.error('Error generating random skill:', error);
      }
    },
    async generateSkillByRealm() {
      try {
        this.skill = await skillWorker.generateSkillByRealm(this.user, this.selectedRealm, this.currentCharacter.level);
      } catch (error) {
        console.error(`Error generating skill for realm ${this.selectedRealm}:`, error);
      }
    },
    async generateSkillByRealmAndType() {
      try {
        console.log(`generating skill for realm ${this.selectedRealmForType} and type ${this.selectedType}`)
        this.skill = await skillWorker.generateSkillByRealmAndType(this.user, this.selectedRealmForType, this.selectedType, this.currentCharacter.level);
      } catch (error) {
        console.error(`Error generating skill for realm ${this.selectedRealmForType} and type ${this.selectedType}:`, error);
      }
    },
    getAllTypes() {
      const typesSet = new Set();
      cultivationInformation.realms.forEach(realm => {
        Object.keys(realm).forEach(key => {
          if (Array.isArray(realm[key])) {
            typesSet.add(key);
          }
        });
      });
      return Array.from(typesSet);
    },
    capitalizeFirstCharacter(string) {
      return helperFunctions.capitalizeFirstCharacter(string);
    }
  }
};
</script>

<style scoped>
.skill-buttons {
  padding: 20px;
}
</style>